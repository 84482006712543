import { AppShell, Modal, useMantineTheme } from '@mantine/core';
import classes from './UserProfile.module.scss';
import { Navbar, NavbarItem } from '../Navbar';
import { userProfileNavbarItems } from './NavbarItems';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '@assemblio/frontend/data-access';

interface UserProfileProps {
  opened: boolean;
}

export const UserProfile = ({ opened }: UserProfileProps) => {
  const theme = useMantineTheme();
  const appRole = useAuthStore((state) => state.appRole);

  const [currentViewIndex, setCurrentViewIndex] = useState<number>(0);

  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseUserProfile = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('profile');
    navigate({ search: `?${searchParams.toString()}` }, { replace: true });
  };
  const navbarItems = userProfileNavbarItems(appRole);

  return (
    <Modal
      overlayProps={{
        backgroundOpacity: 1,
        blur: 0,
        bg: 'transparent',
      }}
      p={0}
      classNames={{
        overlay: classes.profileModal__height,
        root: classes.profileModal__height,
        content: classes.profileModal__height,
        body: classes.profileModal__height,
        inner: classes.profileModal__height,
      }}
      size={'100%'}
      withCloseButton={false}
      opened={opened}
      onClose={handleCloseUserProfile}
      transitionProps={{ transition: 'fade', duration: 200 }}
    >
      <AppShell
        transitionDuration={2000}
        h={'100%'}
        footer={{ height: theme.other.navbarHeight }}
        navbar={{ width: 300, breakpoint: 'xs' }}
        classNames={{
          root: classes.appshell__root,
          main: classes.appshell_main,
        }}
      >
        <Navbar onClose={handleCloseUserProfile}>
          {navbarItems.map((item, index) => (
            <NavbarItem
              onClick={() => setCurrentViewIndex(index)}
              isSelected={currentViewIndex === index}
              item={item}
              key={`navbar-item-${item.slug}`}
            />
          ))}
        </Navbar>

        <AppShell.Main>{navbarItems[currentViewIndex].component}</AppShell.Main>
      </AppShell>
    </Modal>
  );
};
