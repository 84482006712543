import { useAuthStore, useNextProjectQuery } from '@assemblio/frontend/data-access';
import { useDocumentTitle } from '@assemblio/frontend/hooks';
import { NextInstruction, NextInstructionVariant, NextProject } from './types/project-structure.types';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { InstructionDetails } from './Details/InstructionDetails';
import { InstructionExplorerHeaderItems } from './InstructionExplorerHeaderItems';
import { NextProjectDto } from '@assemblio/shared/dtos';
import { useQueryClient } from '@tanstack/react-query';
import { InstructionChangeModal } from './Dialogs/InstructionChangeModal/InstructionChangeModal';
import { OwnershipErrorModal } from './Dialogs/OwnershipErrorModal/OwnershipErrorModal';
import { ProjectController, useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { ExplorerOutletContext } from './Explorer';
import { InstructionCreateVariantModal } from './Dialogs/InstructionCreateVariantModal/InstructionCreateVariantModal';
import {  SyncProfileState } from '@assemblio/shared/next-types';
import { notifications } from '@mantine/notifications';
import { ErrorNotification } from '@assemblio/frontend/types';
import useAsyncConfirm from '../Utilities/useAsyncConfirmation';
import { useAppTransition } from './AppTransition';
import { ExplorerItem, ProjectRoutingParams } from './types';
import { InstructionVariantSyncModal } from './Dialogs/InstructionVariantSyncModal/InstructionVariantSyncModal';
import { InstructionUnresolvedErrorModal } from './Dialogs/InstructionUnresolvedErrorModal/InstructionUnresolvedErrorModal';
import { SyncOwnershipErrorModal } from './Dialogs/OwnershipErrorModal/SyncOwnershipErrorModal';
import { InstructionDeleteSyncProfileModal } from './Dialogs/InstructionDeleteSyncProfileModal/InstructionDeleteSyncProfileModal';
import { useProjectWebsocketListeners } from './hooks/ProjectWebsocketListener';
import { InstructionState } from '@assemblio/type/instruction';

export const InstructionExplorer = () => {
  useDocumentTitle('selection');

  const queryClient = useQueryClient();
  const view = useUIStore((state) => state.view);
  const [getConfirmation, Confirmation] = useAsyncConfirm();
  const appTransition = useAppTransition();

  useProjectWebsocketListeners();

  const { projectId } = useParams<ProjectRoutingParams>() as ProjectRoutingParams;

  const { setLoading, setError, setDetailComponent, setItems, ownerOnly } = useOutletContext<ExplorerOutletContext>();

  const userId = useAuthStore((state) => state.userId);

  const [changeModalOpened, changeModalHandler] = useDisclosure(false);

  const [ownershipErrorModalOpened, ownershipErrorModalHandler] = useDisclosure(false);

  const [syncOwnershipErrorModalOpened, syncOwnershipErrorModalHandler] = useDisclosure(false);

  const [unresolvedErrorModalOpened, unresolvedErrorModalHandler] = useDisclosure(false);

  const [createVariantModalOpened, createVariantModalHandler] = useDisclosure(false);

  const [deleteSyncProfileModalOpened, deleteSyncProfileModalHandler] = useDisclosure(false);

  const [openedSyncResolveModal, syncResolveModalHandler] = useDisclosure(false);

  const setSelectedItemId = ProjectController.setSelectedExplorerItemId;
  const selectedItem = useProjectStore((state) => state.selectedExplorerItem);

  const [modalInstruction, setModalInstruction] = useState<NextInstruction | null>(null);

  const [sourceVariant, setSourceVariant] = useState<NextInstructionVariant | undefined>(undefined);

  const ownerFilter = useCallback(
    (project: NextProjectDto) => {
      if (!ownerOnly) return project;
      return {
        ...project,
        instructions: project.instructions?.filter((instruction) => instruction.owner.id === userId),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerOnly]
  );

  const { data: projectData, isLoading, isError } = useNextProjectQuery(projectId, ownerFilter);

  const handleInstructionChangeModalOpen = (instructionId: string) => {
    const instruction = projectData?.instructions.find((instruction) => instruction.id === instructionId);
    if (instruction) {
      setModalInstruction(instruction);
      changeModalHandler.open();
    }
  };
  const handleCreateVariantModalOpen = (instructionId: string) => {
    const instruction = projectData?.instructions.find((instruction) => instruction.id === instructionId);
    if (instruction) {
      setModalInstruction(instruction);
      createVariantModalHandler.open();
    }
  };

  const handleDeleteSyncProfileModalOpen = (instructionId: string) => {
    const instruction = projectData?.instructions.find((instruction) => instruction.id === instructionId);
    if (instruction) {
      setModalInstruction(instruction);
      deleteSyncProfileModalHandler.open();
    }
  };

  const handleOpenInstruction = async (instruction: NextInstruction) => {
    if (instruction.state === InstructionState.INITIALIZING) {
      notifications.show(ErrorNotification.instructionIsBeingInitialized);
      return;
    }

    if (instruction.state === InstructionState.PROCESSING) {
      notifications.show(ErrorNotification.instructionIsBeingProcessed);
      return;
    }

    const unresolvedSyncProfile = instruction.synced.from.find(
      (syncProfile) => syncProfile.status === SyncProfileState.UNRESOLVED
    );

    if (unresolvedSyncProfile) {
      handleOpenResolveModal(instruction, unresolvedSyncProfile);
      return;
    }

    if (view !== 'editor' && instruction.state !== InstructionState.APPROVED) {
      const confirmed = await getConfirmation(
        'Product not approved',
        `The instruction for this product is not complete yet. You can still view the product but it is still subject to change.`
      );

      if (!confirmed) return;
    }
    if (!checkOwnership(instruction.owner.id)) {
      handleOwnershipErrorModalOpen(instruction);
      return;
    }
    appTransition(projectId, instruction.id);
  };

  const handleOpenSyncOwnershipErrorModal = (instruction: NextInstruction) => {
    setModalInstruction(instruction);
    syncOwnershipErrorModalHandler.open();
  };

  const handleOpenResolveModal = (instruction: NextInstruction, sourceVariant: NextInstructionVariant) => {
    if (!checkOwnership(instruction.owner.id)) {
      handleOpenSyncOwnershipErrorModal(instruction);
      return;
    }
    if (view !== 'editor') {
      handleUnresolvedErrorModalOpen(instruction);
      return;
    }
    setModalInstruction(instruction);
    setSourceVariant(sourceVariant);
    syncResolveModalHandler.open();
  };

  const checkOwnership = (ownerId: string) => {
    //Bypass ownership check for KIM
    if (view !== 'editor') return true;
    return ownerId === userId;
  };

  const handleOwnershipErrorModalOpen = (instruction: NextInstruction) => {
    setModalInstruction(instruction);
    ownershipErrorModalHandler.open();
  };

  const handleUnresolvedErrorModalOpen = (instruction: NextInstruction) => {
    setModalInstruction(instruction);
    unresolvedErrorModalHandler.open();
  };

  const handleInstructionChangeModalClose = (dirty: boolean) => {
    if (dirty) queryClient.invalidateQueries(['project', projectId]);
    changeModalHandler.close();
  };

  const handleInstructionSelection = (instructionId: string) => {
    setSelectedItemId(instructionId);
  };

  const navigationTarget = view !== 'editor' ? `/assembler` : `/disassembler`;

  const instructionItems: ExplorerItem[] = useMemo(() => {
    if (!projectData?.instructions) return [];
    return (
      projectData.instructions.map((instruction) => {
        return {
          type: 'instruction',
          id: instruction.id,
          props: {
            project: projectData,
            instruction,
            navigationTarget,
          },
          handlers: {
            onInstructionSelection: handleInstructionSelection,
            openInstructionChangeModal: handleInstructionChangeModalOpen,
            openCreateVariantModalOpened: handleCreateVariantModalOpen,
            onOpenInstruction: handleOpenInstruction,
            openDeleteSyncProfileModal: handleDeleteSyncProfileModalOpen,
          },
        };
      }) ?? []
    );
  }, [projectData?.instructions]);

  const detailsComponent = getDetailsComponent(projectData, selectedItem, handleOpenResolveModal);

  useEffect(() => {
    if (projectData?.instructions && projectData.instructions.length > 0) {
      setSelectedItemId(projectData.instructions[0].id);
    }
  }, [projectData?.instructions]);

  useEffect(() => {
    setLoading(isLoading);
    setError(isError);
    setDetailComponent(detailsComponent);
  }, [isLoading, isError, selectedItem, projectData?.instructions]);

  useEffect(() => {
    if (!isLoading) setItems(instructionItems);
  }, [projectData?.instructions]);

  return (
    <>
      <InstructionExplorerHeaderItems projectId={projectId} disabled={isError} />
      <Confirmation />

      {modalInstruction && (
        <>
          <InstructionChangeModal
            instruction={modalInstruction}
            projectId={projectId}
            opened={changeModalOpened}
            close={handleInstructionChangeModalClose}
          />
          <OwnershipErrorModal
            open={ownershipErrorModalOpened}
            onClose={ownershipErrorModalHandler.close}
            resourceName={modalInstruction.name}
            resourceId={modalInstruction.id}
          />
          <SyncOwnershipErrorModal
            open={syncOwnershipErrorModalOpened}
            onClose={syncOwnershipErrorModalHandler.close}
            resourceName={modalInstruction.name}
            resourceId={modalInstruction.id}
          />
          <InstructionUnresolvedErrorModal
            opened={unresolvedErrorModalOpened}
            instructionName={modalInstruction.name}
            onClose={unresolvedErrorModalHandler.close}
          />
          <InstructionCreateVariantModal
            instruction={modalInstruction}
            opened={createVariantModalOpened}
            onClose={createVariantModalHandler.close}
          />
          <InstructionDeleteSyncProfileModal
            instruction={modalInstruction}
            opened={deleteSyncProfileModalOpened}
            onClose={deleteSyncProfileModalHandler.close}
          />
          {sourceVariant && (
            <InstructionVariantSyncModal
              targetInstruction={modalInstruction}
              sourceVariant={sourceVariant}
              opened={openedSyncResolveModal}
              onClose={syncResolveModalHandler.close}
            />
          )}
        </>
      )}
    </>
  );
};

const getDetailsComponent = (
  project: NextProject | undefined,
  selectedInstructionId: string,
  onOpenResolveModal: (instruction: NextInstruction, sourceVariant: NextInstructionVariant) => void
) => {
  if (!selectedInstructionId || !project) return null;

  const instruction = getInstruction(project, selectedInstructionId);
  return instruction ? <InstructionDetails onOpenResolveModal={onOpenResolveModal} instruction={instruction} /> : null;
};

const getInstruction = (project: NextProject | undefined, instructionId: string) => {
  return project
    ? (project.instructions?.find((instruction) => instruction.id === instructionId) as NextInstruction)
    : undefined;
};
