import {
  ComboboxItem,
  ComboboxItemGroup,
  Loader,
  Select,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useMemo, useRef, useState } from 'react';
import { useSearchQuery } from '@assemblio/frontend/data-access';
import { SelectedItem, SelectedItemType } from './types/project-explorer.types';
import classes from './ProjectExplorer.module.scss';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
interface ExplorerSearchBarProps {
  onItemSelection: (
    selectedItem: SelectedItem,
    projectFolderId?: string
  ) => void;
}

type SelectItem = ComboboxItem & {
  type: SelectedItemType;
  project_folder_id?: string;
};

type SelectData = {
  itemGroups: ComboboxItemGroup[];
  byValue: { [key: string]: SelectItem };
};

export const ExplorerSearchBar = ({
  onItemSelection,
}: ExplorerSearchBarProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);
  const [value, setValue] = useState<string | null>(null);

  const ref = useRef<HTMLInputElement>(null);

  const { data: searchResults, isFetching } =
    useSearchQuery(debouncedSearchTerm);

  const formattedData: SelectData = useMemo(() => {
    const itemGroups: ComboboxItemGroup[] = [
      {
        group: 'Folders',
        items: [],
      },
      { group: 'Projects', items: [] },
    ];
    const byValue: { [key: string]: SelectItem } = {};

    if (searchResults) {
      searchResults.folders?.forEach((folder) => {
        if (folder.id && folder.name) {
          const item: SelectItem = {
            value: folder.id,
            label: folder.name,
            type: SelectedItemType.Folder,
          };
          itemGroups[0].items.push(item);
          byValue[item.value] = item;
        }
      });
      searchResults.projects?.forEach((project) => {
        if (project.id && project.name) {
          const item: SelectItem = {
            value: project.id,
            label: project.name,
            type: SelectedItemType.Project,
            project_folder_id: project.folderId,
          };
          itemGroups[1].items.push(item);
          byValue[item.value] = item;
        }
      });
    }

    return { itemGroups, byValue };
  }, [searchResults]);

  const handleChange = (value: string | null) => {
    if (!value) return;
    const selectedItem = formattedData.byValue[value];

    setValue(null);
    setSearchTerm('');

    onItemSelection(
      {
        type: selectedItem.type,
        itemId: selectedItem.value,
        name: selectedItem.label,
      },
      selectedItem.project_folder_id
    );

    // Call after current stack is handled, same as _.defer
    setTimeout(() => {
      ref.current?.blur();
    }, 1);
  };

  return (
    <Select
      aria-label="project explorer searchBar"
      ref={ref}
      data={formattedData.itemGroups}
      classNames={{
        dropdown: debouncedSearchTerm ? undefined : classes.hideElement,
        section: classes.inputSection,
      }}
      placeholder="Search"
      value={value}
      onSearchChange={setSearchTerm}
      onOptionSubmit={handleChange}
      leftSection={<IconSearch size={18} />}
      onClick={(e) => e.stopPropagation()}
      size="sm"
      w={isMobile ? '150px' : 'auto'}
      searchable
      nothingFoundMessage={isFetching ? <Loader /> : 'No Result'}
      data-cy="search-by-name"
      spellCheck={false}
    />
  );
};
