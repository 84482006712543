import { useAuthStore, useFolderCreate } from '@assemblio/frontend/data-access';
import { ProjectFolder } from '../types/project-structure.types';
import { ActionIcon, Button, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconFolderPlus } from '@tabler/icons-react';
import { useState } from 'react';

export const AddFolderButton = ({ currentFolder }: { currentFolder: ProjectFolder }) => {
  const [loading, setLoading] = useState(false);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const createFolderMutation = useFolderCreate();

  const tenantId = useAuthStore((state) => state.tenant.id);

  const addNewFolder = async () => {
    if (!currentFolder) return;
    setLoading(true);
    try {
      await createFolderMutation.mutateAsync({
        folderData: {
          color: '#fff',
          name: 'New Folder',
          icon: undefined,
          tenantId: tenantId,
          parentFolderId: currentFolder.id,
        },
      });
      notifications.show({
        id: 'add-folder-success',
        message: 'Folder has been created',
        color: 'green',
      });
      setLoading(false);
    } catch (e) {
      notifications.show({
        id: 'add-folder-error',
        message: 'Folder could not be created',
        color: 'red',
      });
      setLoading(false);
    }
  };
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  if (!currentFolder) return null;
  return isMobile ? (
    <ActionIcon data-cy="add-folder-button" color={'gray'} size={'lg'} ml={20} loading={loading} onClick={addNewFolder}>
      <IconFolderPlus />
    </ActionIcon>
  ) : (
    <Button
      data-cy="add-folder-button"
      variant={colorScheme === 'dark' ? 'light' : 'filled'}
      color={'gray'}
      size={'sm'}
      loading={loading}
      leftSection={<IconFolderPlus size={22} />}
      onClick={addNewFolder}
    >
      Add Folder
    </Button>
  );
};
