import { Card, Flex, ScrollArea } from '@mantine/core';
import classes from '../UserProfile.module.scss';

interface ProfileCardProps {
  children?: React.ReactElement | React.ReactElement[];
}

export const ProfileCard = ({ children }: ProfileCardProps) => {
  return (
    <Flex p={'xl'} h={'100%'} direction={'column'} align={'left'}>
      <Card
        className={classes.profileCard}
        p={'xl'}
        mt={'lg'}
        radius={'lg'}
        w={'100%'}
        h={'100%'}
      >
        <ScrollArea w={'100%'} h={'100%'}>
          {children}
        </ScrollArea>
      </Card>
    </Flex>
  );
};
