import { SearchBar } from '@assemblio/frontend/components';
import { ScrollArea, Stack, useMantineTheme } from '@mantine/core';
import { useRef, useState } from 'react';
import classes from './Sequencer.module.scss';
import { StepBoard } from './StepBoard/StepBoard';

export const Sequencer = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const viewport = useRef<HTMLDivElement>(null);
  const theme = useMantineTheme();

  const scrollToBottom = () =>
    viewport?.current?.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: 'smooth',
    });

  return (
    <Stack
      justify={'stretch'}
      // Offset height by height of ChevronRight
      style={{ height: `calc(100% - ${theme.fontSizes.xs})` }}
      data-cy="sequencerTab"
      mt={10}
    >
      <div className={classes.stepList}>
        <SearchBar searchTerm={searchTerm} onSearchTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)} />
        <ScrollArea
          data-cy="sequencer-scroll-list"
          type="scroll"
          offsetScrollbars
          className={classes.scrollArea}
          classNames={{
            viewport: classes.viewport,
          }}
          viewportRef={viewport}
        >
          <StepBoard searchTerm={searchTerm} scrollToBottom={scrollToBottom} />
        </ScrollArea>
      </div>
    </Stack>
  );
};
