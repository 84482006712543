import { useAuthStore, useInstructionDelete } from '@assemblio/frontend/data-access';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { ActionIcon, Group, Menu, Text, Tooltip, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconArrowsDiff, IconDotsVertical, IconEdit, IconLinkOff, IconTrash } from '@tabler/icons-react';
import cx from 'clsx';
import { BetaBadge } from '../../../Common';
import useAsyncConfirm from '../../../Utilities/useAsyncConfirmation';
import { useHasFeatureFlag } from '../../../Utilities/useHasFeatureFlag.hook';
import { NextInstruction } from '../../types/project-structure.types';
import classes from '../Cards.module.scss';
import { CardMenuItem } from '../ExplorerCard/CardMenuItem';

type InstructionCardMenuProps = {
  projectId: string;
  instruction: NextInstruction;
  disabled?: boolean;
  onListItem?: boolean;
  openInstructionChangeModal: (instructionId: string) => void;
  openCreateVariantModalOpened: (instructionId: string) => void;
  openDeleteSyncProfileModal: (instructionId: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function InstructionCardMenu({
  instruction,
  projectId,
  disabled = false,
  onListItem = false,
  openInstructionChangeModal,
  openCreateVariantModalOpened,
  openDeleteSyncProfileModal,
  setLoading,
}: InstructionCardMenuProps) {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const deleteInstructionMutation = useInstructionDelete();
  const [getConfirmation, Confirmation] = useAsyncConfirm();
  const userId = useAuthStore((state) => state.userId);

  const isOwner = instruction.owner.id === userId;

  const isVariantFeatureEnabled = useHasFeatureFlag(FeatureFlag.Variants);

  const handleDelete = async () => {
    const confirmation = await getConfirmation(
      `Delete Product ${instruction.name}`,
      `Are you sure you want to delete ${instruction.name}? This will also delete all exported manuals and cannot be undone.`,
      'Delete'
    );
    if (!confirmation) return;
    deleteInstruction();
  };

  const deleteInstruction = () => {
    setLoading(true);
    deleteInstructionMutation.mutate(
      {
        projectId: projectId,
        instructionId: instruction.id,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'delete-instruction-success',
            message: 'Product has been deleted',
            color: 'green',
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  const enableLinking = isVariantFeatureEnabled;

  const hasSyncSource = instruction.synced.from.length > 0;

  return (
    <>
      {onListItem ? (
        <Group>
          <Tooltip openDelay={500} label={'Edit Product'}>
            <ActionIcon onClick={() => openInstructionChangeModal && openInstructionChangeModal(instruction.id)}>
              <IconEdit />
            </ActionIcon>
          </Tooltip>
          {enableLinking && (
            <>
              <Tooltip
                openDelay={500}
                label={
                  <Group>
                    <BetaBadge variant={colorScheme === 'dark' ? undefined : 'filled'} />
                    <Text>Link Product to...</Text>
                  </Group>
                }
              >
                <ActionIcon onClick={() => openCreateVariantModalOpened(instruction.id)}>
                  <IconArrowsDiff />
                </ActionIcon>
              </Tooltip>

              {hasSyncSource && (
                <Tooltip
                  openDelay={500}
                  label={
                    <Group>
                      <BetaBadge variant={colorScheme === 'dark' ? undefined : 'filled'} />
                      <Text>Unlink Product</Text>
                    </Group>
                  }
                >
                  <ActionIcon
                    color={theme.other.color.brand.pink}
                    onClick={() => openDeleteSyncProfileModal(instruction.id)}
                  >
                    <IconLinkOff />
                  </ActionIcon>
                </Tooltip>
              )}
            </>
          )}
          <Tooltip openDelay={500} label={isOwner ? 'Delete Product' : 'Only the owner can delete a product'}>
            <ActionIcon disabled={!isOwner} color={theme.other.color.brand.pink} onClick={handleDelete}>
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        </Group>
      ) : (
        <Menu
          disabled={disabled || deleteInstructionMutation.isLoading}
          position="bottom-start"
          withinPortal
          trapFocus={false}
          classNames={{
            itemLabel: classes.explorerCard__menuButton__itemLabel,
          }}
          trigger={'hover'}
        >
          <Menu.Target>
            <ActionIcon
              data-cy="icon-dots-vertical"
              color="gray"
              className={cx(classes.explorerCard__menuButton, {
                [classes['explorerCard__menuButton--list']]: onListItem,
              })}
              variant="filled"
            >
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown data-cy="menu-dropdown">
            <CardMenuItem
              data-cy="instruction-card-edit-icon"
              Icon={IconEdit}
              label={'Edit'}
              onClick={() => openInstructionChangeModal && openInstructionChangeModal(instruction.id)}
            />
            {enableLinking && (
              <>
                <CardMenuItem
                  data-cy="instruction-card-compare-icon"
                  Icon={IconArrowsDiff}
                  label={'Link Product to...'}
                  rightSection={<BetaBadge />}
                  onClick={() => openCreateVariantModalOpened(instruction.id)}
                />
                {hasSyncSource && (
                  <CardMenuItem
                    data-cy="instruction-card-compare-icon"
                    Icon={IconLinkOff}
                    label={'Unlink Product'}
                    rightSection={<BetaBadge />}
                    color={theme.other.color.brand.pink}
                    onClick={() => openDeleteSyncProfileModal(instruction.id)}
                  />
                )}
              </>
            )}
            <CardMenuItem
              tooltipLabel={isOwner ? undefined : 'Only the owner can delete a product'}
              Icon={IconTrash}
              disabled={!isOwner}
              label={'Delete'}
              color={theme.other.color.brand.pink}
              onClick={handleDelete}
            />
          </Menu.Dropdown>
        </Menu>
      )}
      <Confirmation />
    </>
  );
}
