import {
  BottomBar,
  ErrorScreen,
  Explorer,
  InstructionExplorer,
  Login,
  NextLoadingTransition,
  PersistLogin,
  ProjectExplorer,
  RequireAuth,
  Unauthorized,
  UserProfile,
} from '@assemblio/frontend/components';
import { usePreventZoom, useUserProfile } from '@assemblio/frontend/hooks';
import { UIController, useWebSocket } from '@assemblio/frontend/stores';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enableMapSet, enablePatches } from 'immer';
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import classes from './Editor.module.scss';
import { editorTheme } from '../theme/Editor.theme';
import { ThemeVariablesResolver } from '../theme/CSSVariables';
import { Disassembler } from './pages';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient();
enableMapSet();
enablePatches();

function EditorWithProvider() {
  usePreventZoom();
  useWebSocket();
  UIController.setQueryClient(queryClient);

  const location = useLocation();
  const isUsingNewExplorer = location.pathname.includes('/v2');

  const userProfileOpened = useUserProfile() && !isUsingNewExplorer;
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <SentryRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />

      {/* protected routes */}
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={['editor', 'manager']} />}>
          <Route
            element={
              <div id="container" className={classes.container}>
                <div id="content" className={classes.content}>
                  <Outlet />
                  <UserProfile opened={userProfileOpened} />
                </div>
                {!isUsingNewExplorer && (
                  <div id="bottom" className={classes.footer}>
                    <BottomBar showCenterinExplorer={false} />
                  </div>
                )}
              </div>
            }
          >
            <Route path="/explorer" element={<Explorer />}>
              <Route index element={<ProjectExplorer />} />
              <Route path=":folderId" element={<ProjectExplorer />} />
              <Route path="project/:projectId" element={<InstructionExplorer />} />
            </Route>
            <Route
              path="/disassembler/:instructionId"
              element={<NextLoadingTransition component={<Disassembler />} />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate replace to="/explorer" />} />
    </SentryRoutes>
  );
}

export function Editor() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider cssVariablesResolver={ThemeVariablesResolver} defaultColorScheme={'dark'} theme={editorTheme}>
        <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
          <Notifications zIndex={1000} />
          <Suspense fallback={<h2>Loading...</h2>}>
            <ReactQueryDevtools initialIsOpen={false} />
            <BrowserRouter>
              <EditorWithProvider />
            </BrowserRouter>
          </Suspense>
        </Sentry.ErrorBoundary>
      </MantineProvider>
    </QueryClientProvider>
  );
}
