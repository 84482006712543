import {
  axiosBaseRequest,
  useAuthStore,
  useCurrentUserQuery,
} from '@assemblio/frontend/data-access';
import {
  EventController,
  useProjectStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import {
  Avatar,
  Group,
  Menu,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IconArrowUpRight,
  IconLogout,
  IconSettings,
  IconUser,
} from '@tabler/icons-react';
import { SettingsModal } from '../SettingsModal';
import { ExportModal } from '../ExportModal/ExportModal';
import { useDisclosure } from '@mantine/hooks';

const UserButton = () => {
  const clearSession = useAuthStore((state) => state.clearSession);
  const email = useAuthStore((state) => state.email);
  const userQuery = useCurrentUserQuery();
  const instructionId = useProjectStore((state) => state.instructionId);
  const isAnimating = useUIStore((state) => state.isAnimating);
  const view = useUIStore((state) => state.view);
  const { colorScheme } = useMantineColorScheme();
  const [editorSettingsOpen, setEditorSettingsOpen] = useState(false);
  const [openedExporter, { toggle: toggleExportModal }] = useDisclosure(false);

  const getUserInitials = () => {
    if (!userQuery.data) return;
    const { firstName, lastName } = userQuery.data;
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const location = useLocation();
  const navigate = useNavigate();

  const openUserProfile = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('profile', 'open');
    navigate({ search: `?${searchParams.toString()}` }, { replace: false });
  };

  const inMainPage =
    location.pathname.startsWith('/disassembler') ||
    location.pathname.startsWith('/assembler');

  const logout = async () => {
    await axiosBaseRequest({
      url: '/auth/sign-out',
      method: 'get',
      withCredentials: true,
    });
    EventController.disconnectSocket();
    clearSession();
    navigate('/login');
  };

  return (
    <>
      <Group justify="center">
        <Menu
          withArrow
          width={300}
          trapFocus={false}
          position="bottom"
          transitionProps={{ transition: 'pop' }}
          data-cy="component-navbarbutton-user"
        >
          <Menu.Target>
            {userQuery.data ? (
              <Group gap={'xs'} style={{ cursor: 'pointer' }}>
                <Avatar
                  radius={'xl'}
                  variant={colorScheme === 'dark' ? 'light' : 'filled'}
                  size={'sm'}
                  color={'brandYellow'}
                >
                  {getUserInitials()}
                </Avatar>
                <Text
                  size={'xs'}
                  data-cy="username"
                >{`${userQuery.data.firstName} ${userQuery.data.lastName}`}</Text>
              </Group>
            ) : (
              <Avatar
                style={{ cursor: 'pointer' }}
                variant={colorScheme === 'dark' ? 'light' : 'filled'}
                color="blue"
                size={'sm'}
              >
                <IconUser size={'1.625rem'} />
              </Avatar>
            )}
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label data-cy="user-email">{email}</Menu.Label>

            <Menu.Divider />

            <Menu.Item
              data-cy="component-usermenu-user-settings"
              leftSection={<IconUser size={14} />}
              onClick={() => openUserProfile()}
            >
              User Settings
            </Menu.Item>

            {inMainPage && view !== 'viewer' && (
              <Menu.Item
                leftSection={<IconArrowUpRight size={14} />}
                onClick={() => toggleExportModal()}
              >
                Export
              </Menu.Item>
            )}

            {inMainPage && (
              <Menu.Item
                leftSection={<IconSettings size={14} />}
                onClick={() => setEditorSettingsOpen(true)}
                disabled={isAnimating}
              >
                Editor Settings
              </Menu.Item>
            )}
            {/* {appRole === 'admin' && (
              <Menu.Item
                data-cy="component-usermenu-adminpanel"
                leftSection={<IconUserExclamation size={14} />}
                onClick={() =>
                  navigate('/adminpanel', { state: { from: location } })
                }
                disabled={isAnimating}
              >
                Admin Panel
              </Menu.Item>
            )} */}
            <Menu.Item
              data-cy="component-usermenu-logout"
              leftSection={<IconLogout size={14} />}
              onClick={logout}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <SettingsModal
        opened={editorSettingsOpen}
        onSetModalOpened={setEditorSettingsOpen}
      />
      {instructionId && (
        <ExportModal
          instructionId={instructionId}
          opened={openedExporter}
          close={toggleExportModal}
        />
      )}
    </>
  );
};

export default UserButton;
