import { usePartGroupNameReset, usePartNameReset } from '@assemblio/frontend/data-access';
import { ModelController, useProjectStore } from '@assemblio/frontend/stores';
import { ActionIcon, Menu, Switch, Text } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { useState } from 'react';
import { CSVModal } from './CSVImport/CSVModal';
import { ConfirmationDialog } from './ConfirmationDialog';
import { HierarchyFilters } from '../types/hierarchy.types';

interface HierarchyMenuProps {
  onChange: (filters: HierarchyFilters) => void;
}

export const HierarchyMenu = ({ onChange, showExcluded, showDisassembled }: HierarchyMenuProps & HierarchyFilters) => {
  const [csvModalOpened, setCSVModalOpened] = useState(false);
  const [resetDialogOpened, setResetDialogOpened] = useState(false);

  const partGroupNameResetMutation = usePartGroupNameReset();
  const partNameResetMutation = usePartNameReset();

  const handleResetNameConfirm = () => {
    const resets = ModelController.resetNames();
    partNameResetMutation.mutate({ ids: resets.resetedParts });
    partGroupNameResetMutation.mutate({ ids: resets.resetedAssemblies });
    setResetDialogOpened(false);
  };

  return (
    <>
      <Menu shadow="md" width={220} data-cy="verticalThreeDotBtn">
        <Menu.Target>
          <ActionIcon color="gray" mt={4} variant="transparent">
            <IconDotsVertical size="16" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown data-cy="verticalThreeDotBtnDropdown">
          <Menu.Label>Filter</Menu.Label>
          <Menu.Item closeMenuOnClick={false}>
            <Switch
              labelPosition="left"
              label={'Show disassembled'}
              size="xs"
              checked={showDisassembled}
              onChange={(event) => {
                onChange({
                  showDisassembled: event.currentTarget.checked,
                  showExcluded: showExcluded,
                });
              }}
            />
          </Menu.Item>
          <Menu.Item closeMenuOnClick={false}>
            <Switch
              labelPosition="left"
              label={'Show excluded'}
              size="xs"
              checked={showExcluded}
              onChange={(event) => {
                onChange({
                  showDisassembled: showDisassembled,
                  showExcluded: event.currentTarget.checked,
                });
              }}
            />
          </Menu.Item>
          <Menu.Divider />
          <Menu.Label>Part Names</Menu.Label>
          <Menu.Item onClick={() => setCSVModalOpened(true)}>
            <Text size={'xs'} data-cy="rename-by-csv">
              Rename by CSV
            </Text>
          </Menu.Item>
          <Menu.Item onClick={() => handleCSVDownload()}>
            <Text size={'xs'} data-cy="dowdnload-csv">
              Download CSV
            </Text>
          </Menu.Item>
          <Menu.Item color="red.7" onClick={() => setResetDialogOpened(true)}>
            <Text size={'xs'} data-cy="reset-option">
              Reset Names
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <CSVModal opened={csvModalOpened} setOpened={setCSVModalOpened} />
      <ConfirmationDialog
        opened={resetDialogOpened}
        setOpened={setResetDialogOpened}
        handleConfirm={handleResetNameConfirm}
      />
    </>
  );
};

const handleCSVDownload = () => {
  const names = [...new Set(ModelController.getPartAndAssemblyNames())];
  const projectName = useProjectStore.getState().name;
  const csv = Papa.unparse({
    fields: ['Current name', 'New name'],
    data: names.map((currentName) => [currentName, currentName]),
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, `${projectName}_part_list`);
};
