import { Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

interface TitleWithInformationProps {
  title: string;
  information: string;
}
export const TitleWithInformation = ({
  title,
  information,
}: TitleWithInformationProps) => {
  return (
    <Group gap={'xs'} mb={'xs'}>
      <Text>{title}</Text>
      <Tooltip label={information}>
        <ThemeIcon
          c={'gray'}
          size={'xs'}
          style={{ border: 'none' }}
          variant="outline"
        >
          <IconInfoCircle />
        </ThemeIcon>
      </Tooltip>
    </Group>
  );
};
