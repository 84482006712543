import {
  AlignmentSwitch,
  HeadingSwitch,
  MarkButton,
  TextColorPicker,
  TextEditor,
} from '@assemblio/frontend/components';
import { useUpdateAnnotation } from '@assemblio/frontend/data-access';
import { AnnotationController } from '@assemblio/frontend/stores';
import { ShapeAnnotation } from '@assemblio/shared/next-types';
import { Group } from '@mantine/core';
import { IconBold, IconItalic, IconUnderline } from '@tabler/icons-react';
import { useRef } from 'react';
import 'react-resizable/css/styles.css';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import classes from './Annotations.module.scss';
import { ColorChooser } from './ColorChooser';
import { CommonTools } from './CommonTools';
import { ResizableAnnotation, ResizableInterface } from './ResizableAnnotation';
import { SizeSetter } from './SizeSetter';
import { ToolbarGroup } from './ToolbarGroup';

interface Props {
  annotationId: string;
  annotation: ShapeAnnotation;
  borderRadius?: string;
}

export const RectAnnotation = ({ annotationId, annotation, borderRadius }: Props) => {
  if (!borderRadius) {
    borderRadius = '0.5vmin';
  }
  const editorRef = useRef<BaseEditor & ReactEditor>(null);
  const resizableRef = useRef<ResizableInterface>(null);
  const updateAnnotationMutation = useUpdateAnnotation();

  const update = (partialUpdate: Partial<ShapeAnnotation>, persist = true) => {
    const meta = { ...partialUpdate };
    const wasUpdated = AnnotationController.updateAnnotation(annotationId, meta);

    if (wasUpdated && persist) {
      updateAnnotationMutation.mutate({
        id: annotationId,
        meta,
      });
    }
  };

  return (
    <ResizableAnnotation
      initialMode="display"
      annotationId={annotationId}
      annotation={annotation}
      ref={resizableRef}
      toolbar={
        <Group gap="sm">
          <ToolbarGroup label="Text Options">
            <MarkButton editor={editorRef.current} format="italic" icon={<IconItalic />} />
            <MarkButton editor={editorRef.current} format="underline" icon={<IconUnderline />} />
            <MarkButton editor={editorRef.current} format="bold" icon={<IconBold />} />
            <HeadingSwitch editor={editorRef.current} />
            <AlignmentSwitch
              editor={editorRef.current}
              verticalChange={(alignment) => {
                update({ verticalTextAlignment: alignment });
              }}
              verticalAlignment={annotation.verticalTextAlignment}
              horizontalAlignment="left"
            />
            <TextColorPicker editor={editorRef.current} color={annotation.color} />
          </ToolbarGroup>
          <ToolbarGroup label="Shape Options">
            <ColorChooser
              color={annotation.background}
              onChange={(color, persist) => update({ background: color }, persist)}
            />
          </ToolbarGroup>
          <ToolbarGroup label="Line Options">
            <ColorChooser
              onChange={(color, persist) => update({ lineColor: color }, persist)}
              color={annotation.lineColor}
            />
            <SizeSetter onChange={(size) => update({ lineWidth: size })} size={annotation.lineWidth} />
          </ToolbarGroup>
          <ToolbarGroup label="Common Options">
            <CommonTools annotationId={annotationId} />
          </ToolbarGroup>
        </Group>
      }
    >
      <div
        style={{
          width: `calc(100% - ${annotation.lineWidth * 2}px)`,
          height: `calc(100% - ${annotation.lineWidth * 2}px)`,
          position: 'absolute',
          background: annotation.background,
          borderWidth: `${annotation.lineWidth}px`,
          borderColor: `${annotation.lineColor}`,
          borderStyle: 'solid',
          borderRadius: borderRadius,
          boxSizing: 'content-box',
        }}
      >
        <TextEditor
          verticalAlignment={annotation.verticalTextAlignment}
          horizontalAlignment={'left'}
          ref={editorRef}
          text={annotation.text ? { elements: JSON.parse(annotation.text) } : null}
          onChange={(text) => {
            update({ text: JSON.stringify(text) });
          }}
          background="unset"
          color="white"
          textClassName={classes.textEditor}
        />
      </div>
    </ResizableAnnotation>
  );
};
