import {
  ActionIcon,
  Button,
  ButtonProps,
  useMantineTheme,
} from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import { useState } from 'react';
import { ProjectCreationModal } from './ProjectCreationModal';
import { useMediaQuery } from '@mantine/hooks';

type InstructionCreateButtonProps = {
  projectId: string;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  responsive?: boolean;
};

export const InstructionCreateButton = ({
  projectId,
  disabled = false,
  responsive = true,
  buttonProps,
}: InstructionCreateButtonProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [modalOpened, setModalOpened] = useState(false);

  const handleSetModalOpenend = (opened: boolean) => {
    setModalOpened(opened);
  };

  return (
    <>
      {isMobile && responsive ? (
        <ActionIcon
          data-cy="create-instruction-upload-button"
          aria-label="upload file"
          color={'instructionGreen'}
          size={'lg'}
          onClick={() => handleSetModalOpenend(true)}
        >
          <IconUpload />
        </ActionIcon>
      ) : (
        <Button
          data-cy="create-instruction-upload-button"
          aria-label="upload file"
          color={'instructionGreen'}
          variant="filled"
          size={'sm'}
          disabled={disabled}
          {...buttonProps}
          leftSection={<IconUpload size={22} />}
          onClick={() => handleSetModalOpenend(true)}
        >
          Add Product
        </Button>
      )}

      <ProjectCreationModal
        opened={modalOpened}
        projectId={projectId}
        onClose={() => handleSetModalOpenend(false)}
      />
    </>
  );
};
