import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import classes from './ProjectExplorer.module.scss';
import { ExplorerHeaderTitle } from './ExplorerHeaderTitle';
import { Box, Checkbox, Switch, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { IconGridDots, IconList } from '@tabler/icons-react';
import { useExplorerState } from './useExplorerState';

export interface ExplorerHeaderProps {
  loading: boolean;
  ownerOnly: boolean;
  setOwnerOnly: Dispatch<SetStateAction<boolean>>;
}

export const ExplorerHeader = ({
  loading,
  ownerOnly,
  setOwnerOnly,
  children,
}: PropsWithChildren<ExplorerHeaderProps>) => {
  const location = useLocation();

  const [inInstruction] = useDebouncedValue(
    location.pathname.includes('/project'),
    200
  );
  return (
    <div
      className={classes.explorerHeader}
      data-cy={
        inInstruction
          ? 'instruction-explorer-header'
          : 'project-explorer-header'
      }
    >
      <ExplorerHeaderTitle isLoading={loading} data-cy="project-title-header" />
      <Box component="div" className={classes.explorerHeader__buttonGroup}>
        {/* <ExplorerViewSwitch /> deactivated for current release*/}
        <Tooltip
          label="Only Show Projects Where You Are The Owner Of At Least One Product"
          refProp="rootRef"
          openDelay={500}
        >
          <Checkbox
            data-cy="owner-only-checkbox"
            label="Owner Only"
            checked={ownerOnly}
            onChange={(e) => setOwnerOnly(e.currentTarget.checked)}
          />
        </Tooltip>
        {children}
      </Box>
    </div>
  );
};

export const ExplorerViewSwitch = () => {
  const { isListView, toggleListView } = useExplorerState();
  return (
    <Tooltip
      label={`Toggle ${isListView ? 'Grid View' : 'List View'}`}
      refProp="rootRef"
      openDelay={500}
    >
      <Switch
        data-cy="list-grid-toggle-switch"
        classNames={{
          track: classes.viewSwitch__track,
          label: classes.viewSwitch__label,
          body: classes.viewSwitch__body,
        }}
        offLabel={<IconList color={'white'} size={12} />}
        onLabel={<IconGridDots color={'white'} size={12} />}
        checked={isListView}
        size={'md'}
        onChange={(e) => toggleListView()}
      />
    </Tooltip>
  );
};
