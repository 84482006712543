import {
  ActionIcon,
  NumberInput,
  Popover,
  useMantineTheme,
} from '@mantine/core';
import { IconResize } from '@tabler/icons-react';

export interface Props {
  onChange?: (size: number) => void;
  size?: number;
}

export const SizeSetter = ({ onChange, size }: Props) => {
  const theme = useMantineTheme();
  return (
    <Popover>
      <Popover.Target>
        <ActionIcon
          size="lg"
          variant="filled"
          color={theme.other.color.brand.yellow}
        >
          <IconResize />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <NumberInput
          style={{ width: '80px' }}
          value={size}
          onChange={(value) => {
            if (typeof value === 'number' && onChange) onChange(value);
          }}
          min={0}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
