import { Button } from '@mantine/core';
import { useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';

interface ConfirmationButtonProps {
  prompt: string;
  onClose: (result: boolean) => void;
  children: string;
}

export const ConfirmationButton = ({
  prompt,
  onClose,
  children,
}: ConfirmationButtonProps) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <ConfirmationDialog
        opened={opened}
        onClose={(ok) => {
          setOpened(false);
          onClose(ok);
        }}
        prompt={prompt}
      />
      <Button
        onClick={() => {
          setOpened(true);
        }}
      >
        {children}
      </Button>
    </>
  );
};
