import { ProjectController, useUIStore } from '@assemblio/frontend/stores';
import { NextInstructionVariant } from '../../types/project-structure.types';
import { ActionIcon, Card, Group, Text, Tooltip, rem } from '@mantine/core';
import classes from './Variants.module.scss';
import { IconRefresh, IconRefreshAlert } from '@tabler/icons-react';
import { useEffect } from 'react';
import { SyncProfileState } from '@assemblio/shared/next-types';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectRoutingParams } from '../../types';
import { useParams } from 'react-router-dom';

interface VariantCardProps {
  variant: NextInstructionVariant;
  type: 'from' | 'to';
  //TODO: refactor to avoid prop drilling
  onResolve: (sourceVariant: NextInstructionVariant) => void;
  disableSync?: boolean;
  syncMutationLoading?: boolean;
}

export const VariantCard = ({
  variant,
  type,
  onResolve,
  disableSync = false,
  syncMutationLoading = false,
}: VariantCardProps) => {
  const { projectId } =
    useParams<ProjectRoutingParams>() as ProjectRoutingParams;

  const queryClient = useQueryClient();

  const view = useUIStore((state) => state.view);
  const isUnresolved = variant.status === SyncProfileState.UNRESOLVED;

  const variantReady =
    variant.status &&
    [SyncProfileState.DONE, SyncProfileState.UNRESOLVED].includes(
      variant.status
    );

  const syncLoading = syncMutationLoading || !variantReady;

  const showActions = view === 'editor' && type === 'from';

  /* refetch project every 2 seconds query until SyncProfileState is either
    Unresolved or Done or maxRetryCount has been reached
    TODO: refactor once websocket module has been reworked **/
  useEffect(() => {
    let refetchInterval: number;
    if (type === 'from') {
      const maxRetryCount = 3;
      let retryCount = 0;
      refetchInterval = window.setInterval(() => {
        if (retryCount >= maxRetryCount) {
          clearInterval(refetchInterval);
          return;
        }
        if (!variantReady) {
          queryClient.invalidateQueries(['project', projectId]);
        }
        retryCount++;
      }, 2000);
    }

    return () => {
      clearInterval(refetchInterval);
    };
  }, [variant.status]);

  return (
    <Card
      p={'xs'}
      radius={'md'}
      w={'90%'}
      h={rem(48)}
      mb={'xs'}
      className={classes.variantCard}
      key={`variant-${variant.id}`}
      onMouseEnter={() => {
        ProjectController.setHighlightedInstructionId(variant.id);
      }}
      onMouseLeave={() =>
        ProjectController.setHighlightedInstructionId(undefined)
      }
      ml={'xs'}
    >
      <Group
        wrap="nowrap"
        gap={'xs'}
        justify={'start'}
        h={'100%'}
        align="center"
      >
        {showActions &&
          (isUnresolved ? (
            <Tooltip openDelay={250} label={`Resolve Sync issues`}>
              <ActionIcon
                disabled={disableSync}
                loading={syncLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  onResolve(variant);
                }}
              >
                <IconRefreshAlert />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip
              openDelay={250}
              label={`Sync changes from ${variant.name}`}
            >
              <ActionIcon
                disabled={disableSync}
                loading={syncLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  onResolve(variant);
                }}
              >
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
          ))}
        <Tooltip label={variant.name} openDelay={500}>
          <Text
            w={type === 'from' ? '75%' : '100%'}
            lineClamp={1}
            style={{ display: 'inline-block' }}
            truncate
            size={'sm'}
          >
            {variant.name}
          </Text>
        </Tooltip>
      </Group>
    </Card>
  );
};
