import { Grid, Skeleton, StyleProp } from '@mantine/core';
import { ColSpan } from '@mantine/core/lib/components/Grid/GridCol/GridCol';

interface ExplorerGridSkeletonProps {
  colSpans: StyleProp<ColSpan>;
}

export const ExplorerGridSkeleton = ({
  colSpans,
}: ExplorerGridSkeletonProps) => {
  return Array.from({ length: 3 }).map((_, index) => (
    <Grid.Col
      key={`skeleton-${index}`}
      h={'23vh'}
      mih={'250px'}
      span={colSpans}
    >
      <Skeleton h={'100%'} w={'100%'} radius={'md'} />
    </Grid.Col>
  ));
};
