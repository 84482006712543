import { SyncProfileConfig } from '@assemblio/shared/next-types';
import { Button, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import { useInstructionSync } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';

import { Box, Divider } from '@mantine/core';
import produce from 'immer';
import {
  NextInstruction,
  NextInstructionVariant,
} from '../../../types/project-structure.types';
import { ProjectRoutingParams } from '../../../types';
import { InstructionSyncWarning } from '../components/InstructionSyncWarning';
import { CheckboxWithHint } from '../components/CheckboxWithHint';

interface SyncVariantsContentProps {
  active: boolean;
  sourceVariant: NextInstructionVariant;
  targetInstruction: NextInstruction;
  onClose: () => void;
}

export const SyncVariantContent = ({
  active,
  sourceVariant,
  targetInstruction,
  onClose,
}: SyncVariantsContentProps) => {
  const syncMutation = useInstructionSync();
  const { projectId } =
    useParams<ProjectRoutingParams>() as ProjectRoutingParams;
  const [syncConfig, setSyncConfig] = useState<SyncProfileConfig>(
    sourceVariant.config ?? {
      removeEmptySteps: false,
      removeEmptyGroups: false,
      duplicatePartColors: false,
      colorizeNewParts: false,
    }
  );

  const commitSync = () => {
    //TODO: check ownership
    syncMutation.mutate(
      {
        sourceId: sourceVariant.id,
        targetId: targetInstruction.id,
        config: syncConfig,
        projectId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'sync-instruction-with-source-success',
            message: 'Initialized synchronization',
            color: 'green',
          });
        },
      }
    );
    onClose();
  };

  return (
    <Stack>
      <Text size={'lg'}>
        Adapt the assembly plan of&nbsp;
        <Text span fw={700}>
          {sourceVariant.name}
        </Text>
        &nbsp;to&nbsp;
        <Text span fw={700}>
          {targetInstruction.name}
        </Text>
      </Text>

      <InstructionSyncWarning
        sourceName={sourceVariant.name}
        targetName={targetInstruction.name}
      />

      <Divider />

      <Box>
        <Text mb={'xs'}>Options:</Text>

        <CheckboxWithHint
          checked={syncConfig.duplicatePartColors}
          onChange={() => {
            setSyncConfig(
              produce<SyncProfileConfig>((state) => {
                state.duplicatePartColors = !state.duplicatePartColors;
              })
            );
          }}
          label="Duplicate Part Colors"
        />

        <CheckboxWithHint
          checked={syncConfig.colorizeNewParts}
          onChange={() => {
            setSyncConfig(
              produce<SyncProfileConfig>((state) => {
                state.colorizeNewParts = !state.colorizeNewParts;
              })
            );
          }}
          label="Colorize New Parts"
        />

        <CheckboxWithHint
          checked={syncConfig.removeEmptyGroups}
          onChange={() => {
            setSyncConfig(
              produce<SyncProfileConfig>((state) => {
                state.removeEmptyGroups = !state.removeEmptyGroups;
              })
            );
          }}
          label="Remove Empty Groups"
        />

        <CheckboxWithHint
          checked={syncConfig.removeEmptySteps}
          onChange={() =>
            setSyncConfig(
              produce<SyncProfileConfig>((state) => {
                state.removeEmptySteps = !state.removeEmptySteps;
              })
            )
          }
          label="Remove Empty Steps"
        />
      </Box>
      <Button.Group
        style={{
          justifyContent: 'end',
        }}
      >
        <Button onClick={() => commitSync()}>Synchronize</Button>
        <Button variant={'outline'} onClick={onClose}>
          Cancel
        </Button>
      </Button.Group>
    </Stack>
  );
};
