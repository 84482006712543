import { ProjectFolder } from '../../../types/project-structure.types';
import { Text, Box, ActionIcon, useMantineTheme, UnstyledButton } from '@mantine/core';
import { IconChevronRight, IconFolder } from '@tabler/icons-react';
import classes from '../ItemMoveModal.module.scss';

interface FolderListItemProps {
  folder: ProjectFolder;
  onFolderNavigation: (targetId?: string) => unknown;
}

export const FolderListItem = ({ folder, onFolderNavigation }: FolderListItemProps) => {
  const theme = useMantineTheme();
  return (
    <UnstyledButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onFolderNavigation(folder.id);
      }}
      className={classes.listItem}
      data-cy="individual-folder-list"
      style={{ cursor: 'pointer' }}
    >
      <Box className={classes.listItem__name}>
        <IconFolder />
        <Text ml={5} data-cy="individual-folder-name">
          {folder.name}
        </Text>
      </Box>

      <ActionIcon data-cy="folder-list-item" variant={'transparent'} color={theme.other.color.brand.yellow}>
        <IconChevronRight />
      </ActionIcon>
    </UnstyledButton>
  );
};
