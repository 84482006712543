import { useSetInstructionEditorSettings } from '@assemblio/frontend/data-access';
import { useIsOwner } from '@assemblio/frontend/hooks';
import {
  SettingsController,
  useProjectStore,
} from '@assemblio/frontend/stores';
import { Box, Group, Modal, ScrollArea, Text } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import React, { Suspense, useState } from 'react';
import classes from './SettingsModal.module.scss';
import cx from 'clsx';

const componentMap = {
  VIEWPORT: React.lazy(() => import('./settings/ViewportSettings')),
  SHORTCUTS: React.lazy(() => import('./settings/ShortCutSettings')),
  ANIMATION: React.lazy(() => import('./settings/AnimationSettings')),
};

interface linkInfo {
  label: string;
  component: keyof typeof componentMap;
  order: number;
}

const links: linkInfo[] = [
  { label: 'Viewport', component: 'VIEWPORT', order: 1 },
  { label: 'Shortcuts', component: 'SHORTCUTS', order: 1 },
  //{ label: 'Animation', component: 'ANIMATION', order: 1 },
];

interface SettingsModalProps {
  opened: boolean;
  onSetModalOpened: (opened: boolean) => void;
}

export interface SettingsProps {
  setSettingsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SettingsModal = ({
  opened,
  onSetModalOpened,
}: SettingsModalProps) => {
  const [active, setActive] = useState<keyof typeof componentMap>('VIEWPORT');
  const [settingsDirty, setSettingsDirty] = useState<boolean>(false);
  const setEditorSettingsMutation = useSetInstructionEditorSettings();

  const isOwner = useIsOwner();

  const ActiveComponent = componentMap[active];

  const items = links.map((item, index) => (
    <Box<'a'>
      component="a"
      onClick={(event) => {
        event.preventDefault();
        setActive(item.component);
      }}
      key={`settings-link-${index}`}
      className={cx(classes.tableOfContents__link, {
        [classes['tableOfContents__link--active']]:
          active.toString() === item.component.toString(),
      })}
    >
      <Text size="xs">{item.label}</Text>
    </Box>
  ));

  const onClose = () => {
    if (isOwner && settingsDirty) {
      const editorSettings = SettingsController.getSettingsForUpdate();
      const id = useProjectStore.getState().instructionId;
      setEditorSettingsMutation.mutate({ id, data: editorSettings });
    }
    setSettingsDirty(false);
    onSetModalOpened(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Group mb="md">
          <IconSettings size={18} />
          <Text>Settings</Text>
        </Group>
      }
      overlayProps={{
        opacity: 0.7,
      }}
      size={'60%'}
      radius={0}
      centered
    >
      <Group className={classes.modal}>
        <Box className={classes.tableOfContents}>{items}</Box>
        <Box
          className={classes.settings}
          component={ScrollArea}
          type="auto"
          offsetScrollbars
        >
          <Suspense fallback={<div>Loading...</div>}>
            <ActiveComponent setSettingsDirty={setSettingsDirty} />
          </Suspense>
        </Box>
      </Group>
    </Modal>
  );
};
