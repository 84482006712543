import {
  useAuthStore,
  useFolderQuery,
  useRootFolderQuery,
} from '@assemblio/frontend/data-access';
import { useDocumentTitle } from '@assemblio/frontend/hooks';
import { ProjectController } from '@assemblio/frontend/stores';
import { ProjectFoldersDto } from '@assemblio/shared/dtos';
import { NextProject } from './types/project-structure.types';
import { Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { ItemMoveModal } from './Dialogs/ItemMoveModal/ItemMoveModal';
import { ProjectChangeModal } from './Dialogs/ProjectChangeModal/ProjectChangeModal';
import { ProjectCreationModal } from './Dialogs/ProjectCreationModal';
import { ExplorerOutletContext } from './Explorer';
import {
  ExplorerRoutingParams,
  SelectedItemType,
} from './types/project-explorer.types';
import { ExplorerItem } from './types';
import { ProjectExplorerHeaderItems } from './ProjectExplorerHeaderItems';

export const ProjectExplorer = () => {
  useDocumentTitle('selection');

  const { folderId } =
    useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;
  const setSelectedItemId = ProjectController.setSelectedExplorerItemId;

  const { data: root } = useRootFolderQuery();
  const userId = useAuthStore((state) => state.userId);
  const navigate = useNavigate();
  const location = useLocation();

  const { setLoading, setError, setItems, ownerOnly } =
    useOutletContext<ExplorerOutletContext>();
  const [createModalOpened, createModalHandler] = useDisclosure(false);
  const [changeModalOpened, changeModalHandler] = useDisclosure(false);
  const [moveModalOpened, moveModalHandler] = useDisclosure(false);
  const [projectIdModal, setProjectIdModal] = useState<string | null>(null);

  const [modalProject, setModalProject] = useState<NextProject | null>(null);

  const ownerFilter = useCallback(
    (folder: ProjectFoldersDto) => {
      if (!ownerOnly) return folder;
      return {
        ...folder,
        projects: folder.projects?.filter((project) => {
          return project.instructions.some(
            (instruction) => instruction.owner.id === userId
          );
        }),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerOnly]
  );

  const {
    data: currentFolderData,
    isLoading,
    isError,
  } = useFolderQuery(
    folderId && folderId !== '' ? folderId : root?.id,
    ownerFilter
  );

  const handleItemSelection = (itemId: string) => {
    setSelectedItemId(itemId);
  };

  const handleCreateInstructionModalOpen = (projectId: string) => {
    setProjectIdModal(projectId);
    createModalHandler.open();
  };

  const handleProjectChangeModalOpen = (project: NextProject) => {
    setModalProject(project);
    changeModalHandler.open();
  };

  const handleProjectMoveModalOpen = (project: NextProject) => {
    setModalProject(project);
    moveModalHandler.open();
  };

  /**
   * Pass targetFolder to navigate inside the Folder in the explorer. Omit the argument to navigate to the parent Folder
   * @param targetFolderId - Folder id to be navigated to
   */
  const handleFolderNavigation = async (targetFolderId?: string) => {
    setSelectedItemId('');

    if (targetFolderId && targetFolderId !== root?.id) {
      navigate(`/explorer/${targetFolderId}`);
    } else {
      navigate(`/explorer`);
    }
  };

  const handleProjectNavigation = async (projectId: string) => {
    navigate(`/explorer/project/${projectId}`);
  };

  const projectItems: ExplorerItem[] = useMemo(() => {
    if (!currentFolderData?.projects || !root) return [];
    return currentFolderData.projects?.map((project) => {
      return {
        type: 'project',
        id: project.id,
        props: {
          folderId: folderId ?? root.id,
          project: project,
        },
        handlers: {
          onItemSelection: handleItemSelection,
          openInstructionCreateModal: handleCreateInstructionModalOpen,
          openProjectChangeModal: handleProjectChangeModalOpen,
          openProjectMoveModal: handleProjectMoveModalOpen,
          onItemNavigation: handleProjectNavigation,
        },
      };
    });
  }, [currentFolderData?.projects]);

  const folderItems: ExplorerItem[] = useMemo(() => {
    if (!currentFolderData?.subFolders || !root) return [];
    return (
      currentFolderData?.subFolders?.map((folder) => {
        return {
          type: 'folder',
          id: folder.id,
          props: {
            folder: folder,
            rootFolderId: root?.id,
          },
          handlers: {
            onFolderNavigation: handleFolderNavigation,
          },
        };
      }) ?? []
    );
  }, [currentFolderData?.subFolders]);

  const items = useMemo(() => {
    const allItems = [];
    allItems.push(...(folderItems ?? []));
    allItems.push(...(projectItems ?? []));

    return allItems;
  }, [currentFolderData]);

  useEffect(() => {
    setLoading(isLoading);
    setError(isError);
  }, [isLoading, isError]);

  useEffect(() => {
    if (!isLoading) {
      setItems(items);
    }
  }, [currentFolderData?.projects, currentFolderData?.subFolders]);

  useEffect(() => {
    if (root && location.pathname.includes(root.id)) {
      navigate('/explorer', { replace: true });
    }
  }, [location, root]);

  if (isError) {
    navigate(`/explorer`);
  }

  if (!root) {
    // TODO Return some fallback UI or null
    return <HeaderSkeleton />;
  }

  return (
    <>
      <ProjectExplorerHeaderItems
        currentFolder={currentFolderData ?? root}
        handleFolderNavigation={handleFolderNavigation}
        onItemSelection={handleItemSelection}
      />

      <ProjectCreationModal
        opened={createModalOpened}
        projectId={projectIdModal}
        folderId={currentFolderData?.id}
        onClose={createModalHandler.close}
      />

      {modalProject && currentFolderData && (
        <>
          <ProjectChangeModal
            project={modalProject}
            currendFolderId={currentFolderData?.id}
            opened={changeModalOpened}
            close={changeModalHandler.close}
          />
          <ItemMoveModal
            rootFolderId={root.id}
            currentFolderId={currentFolderData?.id}
            itemType={SelectedItemType.Project}
            close={moveModalHandler.close}
            opened={moveModalOpened}
            item={modalProject}
          />
        </>
      )}
    </>
  );
};

const HeaderSkeleton = () => {
  return <Skeleton w={'200px'} h={'50px'} ml={'xs'} my={'10px'} />;
};
