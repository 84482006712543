import { Button, ButtonProps } from '@mantine/core';
import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import { useState } from 'react';
import { ProjectCreationModal } from './ProjectCreationModal';
import { useMediaQuery } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { ExplorerRoutingParams } from '../types/project-explorer.types';
import { ProjectFoldersDto } from '@assemblio/shared/dtos';
import { useQueryClient } from '@tanstack/react-query';

type ProjectCreateButtonProps = {
  responsive?: boolean;
  buttonProps?: ButtonProps;
};

export const ProjectCreateButton = ({
  responsive = true,
  buttonProps,
}: ProjectCreateButtonProps) => {
  const [modalOpened, setModalOpened] = useState(false);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const queryClient = useQueryClient();
  const { folderId } =
    useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;
  const rootFolderId = queryClient.getQueryData<ProjectFoldersDto>([
    'rootFolder',
  ])?.id;

  const handleSetModalOpenend = (opened: boolean) => {
    setModalOpened(opened);
  };

  return (
    <>
      {isMobile && responsive ? (
        <ActionIcon
          data-cy="create-project-upload-button"
          aria-label="upload file"
          color={'brandPurple'}
          variant="filled"
          size={'lg'}
          onClick={() => handleSetModalOpenend(true)}
        >
          <IconUpload />
        </ActionIcon>
      ) : (
        <Button
          data-cy="create-project-upload-button"
          aria-label="upload file"
          color={'brandPurple'}
          variant="filled"
          size={'sm'}
          {...buttonProps}
          leftSection={<IconUpload size={22} />}
          onClick={() => handleSetModalOpenend(true)}
        >
          Add Project
        </Button>
      )}

      <ProjectCreationModal
        opened={modalOpened}
        folderId={folderId ?? rootFolderId}
        onClose={() => handleSetModalOpenend(false)}
      />
    </>
  );
};
