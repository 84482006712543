import { SyncProfileState } from '@assemblio/shared/next-types';
import { Modal, Tabs } from '@mantine/core';
import { BetaBadge } from '../../../Common';
import {
  NextInstruction,
  NextInstructionVariant,
} from '../../types/project-structure.types';
import { SyncVariantContent } from './SyncVariantContent/SyncVariantContent';
import { ResolveSyncIssuesContent } from './ResolveSyncIssuesContent/ResolveSyncIssuesContent';
import { EditVariantSyncProfileContent } from './EditVariantSyncProfileContent/EditVariantSyncProfileContent';
interface InstructionVariantSyncModalProps {
  targetInstruction: NextInstruction;
  sourceVariant: NextInstructionVariant;
  opened: boolean;
  onClose: () => void;
}

export const InstructionVariantSyncModal = ({
  opened,
  sourceVariant,
  targetInstruction,
  onClose,
}: InstructionVariantSyncModalProps) => {
  const handleCloseModal = () => {
    onClose();
  };

  const isUnresolved = sourceVariant.status === SyncProfileState.UNRESOLVED;

  return (
    <Modal
      title={<BetaBadge />}
      size={'xl'}
      opened={opened}
      onClose={handleCloseModal}
      px={'xl'}
      styles={{
        content: {
          maxHeight: '85vh',
        },
      }}
    >
      <Tabs defaultValue={isUnresolved ? 'resolve' : 'sync'}>
        {!isUnresolved && (
          <Tabs.List mb={'xs'}>
            <Tabs.Tab disabled={isUnresolved} value="sync">
              Link
            </Tabs.Tab>
            <Tabs.Tab disabled={isUnresolved} value="edit">
              Edit Profile
            </Tabs.Tab>
          </Tabs.List>
        )}

        <Tabs.Panel value="resolve">
          <ResolveSyncIssuesContent
            active={opened}
            target={targetInstruction}
            source={sourceVariant}
            onClose={handleCloseModal}
          />
        </Tabs.Panel>
        <Tabs.Panel value="sync">
          <SyncVariantContent
            active={opened}
            targetInstruction={targetInstruction}
            sourceVariant={sourceVariant}
            onClose={handleCloseModal}
          />
        </Tabs.Panel>
        <Tabs.Panel value="edit">
          <EditVariantSyncProfileContent
            targetInstruction={targetInstruction}
            sourceVariant={sourceVariant}
            onClose={handleCloseModal}
          />
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};
