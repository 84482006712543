import { Badge, BadgeProps } from '@mantine/core';
import classes from './Tag.module.scss';
import { PropsWithChildren, ReactNode } from 'react';
import { InstructionState } from '@assemblio/type/instruction';
import { IconCircleCheckFilled, IconRefresh } from '@tabler/icons-react';
import { IconDraft } from '../Icons';

type ApplicableStates = Extract<
  InstructionState,
  InstructionState.DRAFT | InstructionState.APPROVED | InstructionState.IN_REVIEW
>;

type StateIconMapType = {
  [key in ApplicableStates]: ReactNode;
};

const StateIconMap: StateIconMapType = {
  [InstructionState.APPROVED]: <IconCircleCheckFilled />,
  [InstructionState.IN_REVIEW]: <IconRefresh />,
  [InstructionState.DRAFT]: <IconDraft />,
};

interface TagProps extends BadgeProps {
  state?: InstructionState;
}

export const Tag = ({ children, state, ...props }: PropsWithChildren<TagProps>) => {
  const Icon = StateIconMap[state as ApplicableStates];

  return (
    <Badge leftSection={Icon} py={'xs'} px={'md'} className={classes.tag} {...props}>
      {state ? state : children}
    </Badge>
  );
};
