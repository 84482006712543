import { MachineController, SequenceController, useSequenceStore, useUIStore } from '@assemblio/frontend/stores';
import { ActionIcon, Group, TextInput } from '@mantine/core';
import { IconRefresh, IconSearch, IconX } from '@tabler/icons-react';
import classes from './SearchBar.module.scss';
import { fetchStepGroups } from '@assemblio/frontend/data-access';
import _ from 'lodash';

export const SearchBar = ({
  searchTerm,
  onSearchTermChange,
}: {
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  className?: string;
}): JSX.Element => {
  const isAnimating = useUIStore((state) => state.isAnimating);
  const nodeEnv = process.env['NODE_ENV'];

  const handleStepRefresh = async () => {
    if (nodeEnv === 'development') {
      const sequenceId = useSequenceStore.getState().selectedSequenceId;
      const stepGroupsDto = await fetchStepGroups({
        sequenceId,
        reverseOrder: false,
      });
      SequenceController.setStepGroupsFromDto(stepGroupsDto);
      _.defer(() => MachineController.deselect());
    }
  };

  return (
    <Group justify={'start'} gap={0}>
      <TextInput
        mx={'10px'}
        placeholder="Search"
        data-cy="sequencer-search-input"
        radius="sm"
        size="xs"
        type="search"
        value={searchTerm}
        onChange={(e) => onSearchTermChange(e.target.value)}
        disabled={isAnimating}
        classNames={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        rightSection={
          searchTerm === '' ? (
            <ActionIcon variant="transparent" color="gray">
              <IconSearch strokeWidth={3} size="18" dat-cy="sequencer-search-icon" />
            </ActionIcon>
          ) : (
            <ActionIcon variant="transparent" color="gray" onClick={() => onSearchTermChange('')}>
              <IconX strokeWidth={3} size="18" />
            </ActionIcon>
          )
        }
        leftSection={
          nodeEnv === 'development' && (
            <ActionIcon size="xs" variant="light" onClick={() => handleStepRefresh()}>
              <IconRefresh strokeWidth={2} size="18" />
            </ActionIcon>
          )
        }
      />{' '}
    </Group>
  );
};
