import { Box, Flex, Select, Stack, Text, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { SortingValue } from './ArtefactList';
import { ListFilterOptions, ListTypeFilter } from './ListTypeFilter';
import classes from './InstructionDetails.module.scss';
interface ArtefactListHeaderProps {
  sortingValue: SortingValue;
  setSortingValue: React.Dispatch<React.SetStateAction<SortingValue>>;
  filterEnum: ListFilterOptions;
  selectedFilter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}
export const ArtefactListHeader = ({
  setFilter,
  selectedFilter,
  filterEnum,
  sortingValue,
  setSortingValue,
}: ArtefactListHeaderProps) => {
  return (
    <Stack gap={0} h={'15%'} mih={rem(70)}>
      <Text fw={700}>Artefacts: </Text>
      <Flex mih={'25px'} my={10} align={'center'} justify={'space-between'}>
        <Box w={'40%'}>
          <SortingSelect
            sortingValue={sortingValue}
            setSortingValue={setSortingValue}
          />
        </Box>

        <ListTypeFilter
          setFilter={setFilter}
          selectedFilter={selectedFilter}
          filterEnum={filterEnum}
        />
      </Flex>
    </Stack>
  );
};

type SortingSelectProps = {
  sortingValue: SortingValue;
  setSortingValue: React.Dispatch<React.SetStateAction<SortingValue>>;
};

export const SortingSelect = ({
  sortingValue,
  setSortingValue,
}: SortingSelectProps) => {
  return (
    <>
      <Text c={'dimmed'} size={'xs'}>
        Sort by: &nbsp;
      </Text>
      <Select
        classNames={{
          input: classes.selectInput,
          wrapper: classes.selectWrapper,
          root: classes.root,
        }}
        size={'xs'}
        withCheckIcon={false}
        variant={'unstyled'}
        value={sortingValue}
        onClick={(e) => e.stopPropagation()}
        onOptionSubmit={(event) => setSortingValue(event as SortingValue)}
        allowDeselect={false}
        data={Object.values(SortingValue)}
        rightSection={<IconChevronDown color={'gray'} />}
        rightSectionWidth={30}
      />
    </>
  );
};
