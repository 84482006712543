import { useStepUpdate } from '@assemblio/frontend/data-access';
import {
  ModelController,
  StepController,
  UIController,
  useUIStore,
} from '@assemblio/frontend/stores';
import { StepData } from '@assemblio/shared/next-types';
import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import cx from 'clsx';
import _ from 'lodash';
import { memo, useCallback } from 'react';
import classes from '../Styles/Segment.module.scss';
import VectorDisplay from './VectorDisplay';

interface PathProps {
  stepData: StepData;
  stepId: string;
  stepSelected: boolean;
  disabled: boolean;
}

const Path = ({ stepData, stepId, stepSelected, disabled }: PathProps) => {
  const updateStep = useStepUpdate();

  const selectedSegment = useUIStore(
    useCallback((state) => state.selectedPathSegmentMap.get(stepId), [stepId])
  );

  const theme = useMantineTheme();

  const handleSegmentClick = useCallback(
    (index: number) => {
      const isSelected = UIController.setPathSegment(stepId, index);
      if (isSelected) {
        ModelController.movePartsToStepSegment(index, stepData);
      } else {
        ModelController.movePartsToStepSegment(-1, stepData);
      }
    },
    [stepId, stepData]
  );

  const deletePathElement = useCallback(
    (index: number) => {
      if (stepData) {
        const restore = _.cloneDeep(stepData);
        const data = _.cloneDeep(stepData);
        data.path.splice(index, 1);

        if (index === data.path.length || selectedSegment === index) {
          const newParts = ModelController.movePartsToStepSegment(-1, data);
          if (newParts) {
            data.parts = newParts;
          } else {
            showSegmentDeletionError();
            return;
          }
        }

        StepController.setStepData(stepId, data);
        // Trigger recalculation of transform gizmo
        UIController.refreshTransformGizmo();

        updateStep.mutate(
          {
            id: stepId,
            data: StepController.sanitizeStepData(data),
          },
          {
            onError: () => {
              StepController.setStepData(stepId, restore);
              showSegmentDeletionError();
            },
          }
        );

        // Set the selected Path Segment to the new start position
        UIController.setPathSegment(stepId);
      }
    },
    [stepId, stepData, selectedSegment]
  );

  return (
    <Box
      p={5}
      style={{
        backgroundColor: theme.other.color.editor.black_20,
        borderRadius: theme.radius.sm,
      }}
      mb={5}
    >
      {stepData &&
        stepData.path &&
        stepData.path.map((element, index) => {
          return (
            <Flex
              h={'2rem'}
              key={`step-path-segment-${index}`}
              className={cx(classes.segment, {
                [classes.active]: selectedSegment === index && stepSelected,
              })}
              onClick={() => handleSegmentClick(index)}
            >
              <Text
                ta="center"
                pt={'.25rem'}
                w={'15px'}
                pr={5}
                c={stepSelected ? 'white' : 'dimmed'}
                size={'xs'}
              >
                {stepData.path.length - index}
              </Text>
              <VectorDisplay
                onDelete={() => deletePathElement(index)}
                deletable={index !== 0}
                disabled={disabled}
                vector={element.position}
              />
            </Flex>
          );
        })}
    </Box>
  );
};

const showSegmentDeletionError = () => {
  notifications.show({
    id: `remove-step-segment`,
    message: 'Segment could not be deleted',
    color: 'red',
  });
};

export default memo(Path);
