import React from 'react';
import { useForm } from '@mantine/form';
import { Button, Group, Text, TextInput } from '@mantine/core';
import { ProjectFolder } from '../../types/project-structure.types';

interface FolderNameSelectionProps {
  onClose: () => void;
  folder: ProjectFolder;
  onUpdate: (name: string) => void;
}

export const FolderNameSelection: React.FC<FolderNameSelectionProps> = ({
  onClose,
  folder,
  onUpdate,
}) => {
  const form = useForm({
    initialValues: { folderName: '' },
    validate: {
      folderName: (value) => {
        if (value.trim().length < 3 || value.trim().length > 55) {
          return 'Field must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.validate().hasErrors) {
      return;
    }
    onUpdate(form.values.folderName);
    onClose();
  };

  return (
    <>
      <Text size={'sm'}>Rename Folder</Text>
      <form onSubmit={handleSubmit} noValidate>
        <Group
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
          }}
        >
          <TextInput
            style={{ width: '320px' }}
            placeholder={folder.name}
            {...form.getInputProps('folderName')}
            required
          />
          <Button
            style={{ height: '36px' }}
            size="xs"
            type="submit"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            Apply
          </Button>
        </Group>
      </form>
    </>
  );
};
