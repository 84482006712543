import { Button, Group, Modal, Text } from '@mantine/core';

interface SyncOwnershipErrorModalProps {
  resourceName: string;
  resourceId: string;
  open: boolean;
  onClose: () => unknown;
}

export const SyncOwnershipErrorModal = ({
  resourceName,
  resourceId,
  open,
  onClose,
}: SyncOwnershipErrorModalProps) => {
  return (
    <Modal
      opened={open}
      withCloseButton
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      centered
      withinPortal
    >
      <Text fw={700} mb={'lg'}>
        Not allowed
      </Text>
      <Text size="sm" mb={'xl'}>
        Only the owner can synchronize a product and you are currently not the
        owner of <b>{resourceName}</b>. {<br />}
      </Text>

      <Group align="flex-start" justify="left">
        <Button variant={'outline'} onClick={() => onClose()}>
          Close
        </Button>
      </Group>
    </Modal>
  );
};
