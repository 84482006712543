import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './main.scss';
import './fonts.css';
import 'react-image-crop/dist/ReactCrop.css';
import { Editor } from './lib';
import { initSentry } from './sentry-composer.helper';

initSentry();
const element = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(element!);
root.render(
  <StrictMode>
    <Editor />
  </StrictMode>
);
