import {
  ActionIcon,
  Collapse,
  Group,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
interface CollapsibleSectionProps {
  title: string;
  titleColor?: string;
  collapsed: boolean;
  selected: boolean;
  setCollapsed: () => void;
  children?: React.ReactNode;
}

export const CollapsibleSection = ({
  title,
  titleColor,
  collapsed,
  selected,
  setCollapsed,
  children,
}: CollapsibleSectionProps) => {
  const theme = useMantineTheme();
  return (
    <>
      <Group gap={5}>
        <ActionIcon
          data-cy="collapsible-section"
          size="sm"
          color="dimmed"
          variant="transparent"
          onClick={() => setCollapsed()}
          style={{ paddingBottom: 6 }}
        >
          {collapsed ? (
            <IconChevronDown
              size={16}
              color={titleColor ?? theme.colors.gray[6]}
            />
          ) : (
            <IconChevronRight
              size={16}
              color={titleColor ?? theme.colors.gray[6]}
            />
          )}
        </ActionIcon>
        <Text
          size={'10px'}
          fw={500}
          data-selected={selected}
          className={'global__label'}
          style={{ marginBottom: '4px' }}
        >
          {title}
        </Text>
      </Group>
      <Collapse in={collapsed}>{children}</Collapse>
    </>
  );
};
