import { ActionIcon, ColorPicker, Popover, useMantineTheme } from '@mantine/core';
import { IconPaint } from '@tabler/icons-react';
import { ASSEMBLIO_PART_COLORS } from '@assemblio/frontend/types';

export interface Props {
  onChange: (color: string, persist: boolean) => void;
  color?: string;
}

export const ColorChooser = ({ onChange, color }: Props) => {
  const theme = useMantineTheme();
  return (
    <Popover>
      <Popover.Target>
        <ActionIcon size="lg" variant="filled" color={theme.other.color.brand.yellow}>
          <IconPaint />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <ColorPicker
          format={'rgba'}
          onChange={(value) => onChange(value, false)}
          onChangeEnd={(value) => onChange(value, true)}
          value={color}
          defaultValue="rgba(253, 126, 20, 1)"
          swatchesPerRow={6}
          swatches={ASSEMBLIO_PART_COLORS.map((color) => color.code.hex)}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
