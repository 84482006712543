import {
  Box,
  Checkbox,
  Collapse,
  Group,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { ExportSequenceSelection } from './types/export-modal.types';
import classes from './ExportModal.module.scss';
interface SelectableSequenceProps {
  sequenceId: string;
  stepGroups: {
    id: string;
    name: string;
  }[];
  name: string | undefined;
  selection: ExportSequenceSelection;
  onSequenceSelection: (sequenceId: string) => unknown;
  onStepGroupSelection: (sequenceId: string, stepGroupId: string) => unknown;
}

export const SelectableSequence = ({
  sequenceId,
  stepGroups,
  onSequenceSelection,
  onStepGroupSelection,
  name,
  selection,
}: SelectableSequenceProps) => {
  const theme = useMantineTheme();
  const selectedSequence = selection.find(
    (seq) => seq.sequenceId === sequenceId
  );

  if (!selectedSequence) return null;
  return (
    <Box mb={5} p={5} className={classes.sequence}>
      <Group>
        <Checkbox
          size={'sm'}
          checked={Boolean(selectedSequence.selected)}
          onChange={() => {
            onSequenceSelection(sequenceId);
          }}
        />
        <Text>{name}</Text>
      </Group>
      <Collapse in={Boolean(selectedSequence.selected)}>
        {stepGroups.map((sg: { name: string; id: string }, index) => {
          return (
            <Group
              key={`sequence-${index}`}
              p={`calc(${theme.spacing.xs} / 2 )`}
              my={'2px'}
              className={classes.sequence__stepGroup}
            >
              <Checkbox
                size={'xs'}
                checked={Boolean(
                  selectedSequence.stepGroups.find(
                    (group) => group.stepGroupId === sg.id
                  )?.selected
                )}
                onChange={() => {
                  onStepGroupSelection(selectedSequence.sequenceId, sg.id);
                }}
              />
              <Text size={'sm'}>{sg.name}</Text>
            </Group>
          );
        })}
      </Collapse>
    </Box>
  );
};
