import { SyncProfilePairs } from '@assemblio/shared/next-types';
import { Button, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import {
  useInstructionSyncProfileQuery,
  useInstructionSyncResolve,
} from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
import { ProjectRoutingParams } from '../../../types';
import {
  SyncProfilePairEditTable,
  SyncProfilePairEditTableSkeleton,
} from '../components/SyncProfileEditTable';
import {
  NextInstruction,
  NextInstructionVariant,
} from '../../../types/project-structure.types';
import { InstructionSyncProfileVisualization } from '../components/InstructionSyncProfileVisualization';

interface ResolveSyncIssuesContentProps {
  source: NextInstructionVariant;
  target: NextInstruction;
  active: boolean;
  onClose: () => void;
}

export const ResolveSyncIssuesContent = ({
  source,
  target,
  active,
  onClose,
}: ResolveSyncIssuesContentProps) => {
  const { projectId } =
    useParams<ProjectRoutingParams>() as ProjectRoutingParams;

  const [updatedPairs, setUpdatedPairs] = useState<
    SyncProfilePairs['resolved'] | undefined
  >(undefined);

  const resolveMutation = useInstructionSyncResolve();

  const { data, isLoading, isError } = useInstructionSyncProfileQuery({
    sourceId: source.id,
    targetId: target.id,
  });

  const handleSync = () => {
    if (updatedPairs) {
      resolveMutation.mutate(
        {
          sourceId: source.id,
          targetId: target.id,
          projectId,
          resolve: updatedPairs,
        },
        {
          onSuccess: () => {
            notifications.show({
              id: 'sync-resolve-success',
              message: 'Synchronization Issues resolved',
              color: 'green',
            });
            handleClose();
          },
        }
      );
    }
  };

  const getSimilarPartsNotice = () => {
    if (!data || isLoading) return <Skeleton w={'5rem'} h={'1rem'} />;
    const partCount = data.pairs.unresolved.length;
    let partPhrase: string;

    switch (partCount) {
      case 0:
        partPhrase = 'are no parts yet that are';
        break;
      case 1:
        partPhrase = 'is one part that is';
        break;
      default:
        partPhrase = `are ${partCount} parts that are`;
        break;
    }
    return (
      <Text>
        There {partPhrase} similar to a part in the base product and the step
        information cannot be synchronized yet.
      </Text>
    );
  };

  const handleClose = () => {
    setUpdatedPairs(undefined);
    onClose();
  };

  return (
    <Stack>
      <Text size="xl">Resolve Synchronization Issues</Text>
      {isError ? (
        <Text>
          There was a problem loading the part data. Please try again.
        </Text>
      ) : (
        <>
          <Stack>
            {/* TODO: improve wording */}
            <Group justify="center" my={'xs'}>
              <InstructionSyncProfileVisualization
                sourceName={source.name}
                targetName={target.name}
                state={source.status}
              />
            </Group>
            <Text size="xs" c={'dimmed'}>
              During the synchronization&nbsp;
              <Text span c={'green'}>
                {data?.pairs?.replace?.length ?? 0} identical parts
              </Text>
              &nbsp;were found and the corresponding step information can be
              applied.
            </Text>

            <Text size="sm">{getSimilarPartsNotice()}</Text>
            <Divider />
          </Stack>
          {!data || isLoading ? (
            <SyncProfilePairEditTableSkeleton />
          ) : (
            <SyncProfilePairEditTable
              onChange={(updatedPairs) => setUpdatedPairs(updatedPairs)}
              pairs={data.pairs}
              label="Please choose how these parts should be handeled in the future:"
            />
          )}
        </>
      )}

      <Button.Group
        style={{
          justifyContent: 'end',
        }}
      >
        <Button
          disabled={isLoading || isError}
          loading={resolveMutation.isLoading}
          onClick={handleSync}
        >
          Synchronize
        </Button>
        <Button variant={'outline'} onClick={handleClose}>
          Cancel
        </Button>
      </Button.Group>
    </Stack>
  );
};
