import { ProjectFolder } from '../../../types/project-structure.types';
import { ScrollArea } from '@mantine/core';
import { FolderListItem } from './FolderListItem';
import { ProjectListItem } from './ProjectListItem';
import classes from '../ItemMoveModal.module.scss';

interface PopulatedFolderListProps {
  currentFolder: ProjectFolder;
  onFolderNavigation: (targetId?: string) => unknown;
}

export const PopulatedFolderList = ({
  currentFolder,
  onFolderNavigation,
}: PopulatedFolderListProps) => {
  return (
    <ScrollArea className={classes.listWrapper}>
      {currentFolder.subFolders?.map((sF, index) => (
        <FolderListItem
          key={`folder-list-item-${index}`}
          folder={sF}
          onFolderNavigation={onFolderNavigation}
        />
      ))}
      {currentFolder.projects?.map((project, index) => (
        <ProjectListItem key={`project-list-item-${index}`} project={project} />
      ))}
    </ScrollArea>
  );
};
