import { ActionIcon, Divider, Menu, useMantineTheme } from '@mantine/core';
import {
  IconAdjustmentsHorizontal,
  IconCopy,
  IconCopyOff,
  IconEye,
  IconTrash,
  IconVideo,
  IconVideoOff,
} from '@tabler/icons-react';
import classes from '../../Sequencer.module.scss';
import { useUIStore } from '@assemblio/frontend/stores';

interface StepMenuProps {
  onRemoveStep: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onResetCamera: () => void;
  onSetCamera: () => void;
  onSetCameraPositionToStep: () => void;
  onCopyCameraSettings: () => void;
  onPasteCameraSettings: () => void;
  isStepSelected: boolean;
  disabled: boolean;
}

export const StepMenu = ({
  onRemoveStep,
  onResetCamera,
  onSetCamera,
  onSetCameraPositionToStep,
  onCopyCameraSettings,
  onPasteCameraSettings,
  isStepSelected,
  disabled,
}: StepMenuProps) => {
  const theme = useMantineTheme();
  const disablePasteItem = useUIStore((state) => state.cameraClipboard)
    ? disabled
    : true;

  return (
    <Menu
      classNames={{
        item: classes.stepMenu__item,
      }}
      withinPortal
    >
      <Menu.Target>
        <ActionIcon
          disabled={disabled}
          variant="transparent"
          style={{
            background: 'transparent',
          }}
          data-cy="Icon-adjustments-horizontal"
        >
          <IconAdjustmentsHorizontal
            size={18}
            color={isStepSelected ? 'white' : theme.colors.gray[5]}
          />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          data-cy="reset-camera"
          leftSection={<IconVideoOff size={14} />}
          onClick={onResetCamera}
          disabled={disabled}
        >
          Reset Camera
        </Menu.Item>
        <Menu.Item
          data-cy="set-camera"
          leftSection={<IconVideo size={14} />}
          onClick={onSetCamera}
          disabled={disabled}
        >
          Set Camera
        </Menu.Item>
        <Menu.Item
          data-cy="view-camera"
          leftSection={<IconEye size={14} />}
          onClick={onSetCameraPositionToStep}
          disabled={disabled}
        >
          View Camera
        </Menu.Item>
        <Menu.Item
          data-cy="copy-camera"
          leftSection={<IconCopy size={14} />}
          onClick={onCopyCameraSettings}
          disabled={disabled}
        >
          Copy Camera
        </Menu.Item>
        <Menu.Item
          data-cy="paste-camera"
          leftSection={<IconCopyOff size={14} />}
          onClick={onPasteCameraSettings}
          disabled={disablePasteItem}
        >
          Paste Camera
        </Menu.Item>
        <Divider />
        <Menu.Item
          data-cy="delete-step"
          color="red"
          leftSection={<IconTrash size={14} />}
          onClick={onRemoveStep}
          disabled={disabled}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
