import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconBubbleText, IconBubble } from '@tabler/icons-react';

export const SidebarTextRendererButton = () => {
  const theme = useMantineTheme();
  const showSidebarTextRenderer = useUIStore((state) => state.showSidebarTextRenderer);
  const tooltipLabel = showSidebarTextRenderer ? 'Hide Step Text in Viewport' : 'Show Step Text in Viewport';
  return (
    <Tooltip position="right" label={tooltipLabel}>
      <ActionIcon
        style={{
          pointerEvents: 'auto',
        }}
        color={theme.other.color.brand.yellow}
        size="lg"
        variant="filled"
        onClick={() => UIController.setShowSidebarTextRenderer(!showSidebarTextRenderer)}
      >
        {showSidebarTextRenderer ? <IconBubble /> : <IconBubbleText />}
      </ActionIcon>
    </Tooltip>
  );
};
