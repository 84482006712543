import {
  useFolderQuery,
  useNextProjectMove,
} from '@assemblio/frontend/data-access';
import {
  NextProject,
  ProjectFolder,
} from '../../types/project-structure.types';
import { Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useState } from 'react';
import { SelectedItemType } from '../../types/project-explorer.types';
import { FolderListSkeleton } from './FolderListView/FolderListSkeleton';
import { FolderListView } from './FolderListView/FolderListView';
import { ItemMoveModalButtons } from './ItemMoveModalButtons';

interface MoveFolder {
  itemType: SelectedItemType.Folder;
  item: ProjectFolder;
}

interface MoveProject {
  itemType: SelectedItemType.Project;
  item: NextProject;
}
type ItemMoveModalProps = (MoveFolder | MoveProject) & {
  rootFolderId: string;
  currentFolderId: string;
  opened: boolean;
  close: () => void;
};

export const ItemMoveModal = ({
  itemType,
  item,
  rootFolderId,
  currentFolderId,
  close,
  opened,
}: ItemMoveModalProps) => {
  const [selectedFolderId, setSelectedFolderId] = useState(rootFolderId);

  const moveProject = useNextProjectMove();

  const { data: fetchedFolder } = useFolderQuery(selectedFolderId);

  const handleFolderNavigation = (targetId?: string) => {
    // move deeper in Folder Structure
    if (targetId) {
      setSelectedFolderId(targetId);
    } else {
      // move to parent
      if (fetchedFolder) setSelectedFolderId(fetchedFolder?.parentFolderId);
    }
  };

  const handleProjectMove = (targetId: string) => {
    if (itemType === SelectedItemType.Project && selectedFolderId) {
      moveProject.mutate(
        {
          projectId: item.id,
          targetFolderId: targetId,
          oldParentFolderId: currentFolderId,
        },
        {
          onSuccess: () => {
            notifications.show({
              id: 'move-project-success',
              message: 'Project has been moved',
              color: 'green',
            });
          },
          onError: () => {
            notifications.show({
              id: 'move-projec-error',
              message: 'Project could not be moved',
              color: 'red',
            });
          },
        }
      );
      handleClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      title={`Move${' '}${item.name}`}
      trapFocus
      onClick={handleModalClick}
      onDoubleClick={handleModalClick}
      withinPortal
      opened={opened}
      centered
      onClose={() => handleClose()}
      radius={0}
      data-cy="item-move-modal"
    >
      {fetchedFolder ? (
        <FolderListView
          currentFolder={fetchedFolder}
          onFolderNavigation={handleFolderNavigation}
        />
      ) : (
        <FolderListSkeleton />
      )}
      {selectedFolderId && (
        <ItemMoveModalButtons
          currentFolderId={selectedFolderId}
          parentFolderId={currentFolderId}
          onClose={handleClose}
          onProjectMove={handleProjectMove}
        />
      )}
    </Modal>
  );
};
