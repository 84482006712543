import { AxiosProgressEvent } from 'axios';

export interface ProgressCallback {
  onProgress?: (percentage: number) => void;
  onComplete?: () => void;
}

export const handleProgressCallback = (
  progressEvent: AxiosProgressEvent,
  events?: ProgressCallback
) => {
  if (progressEvent.progress) {
    if (progressEvent.progress === 1) {
      events?.onComplete && events.onComplete();
    } else {
      events?.onProgress && events.onProgress(progressEvent.progress);
    }
  }
};
