import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createInstructionSyncProfile,
  deleteInstructionSyncProfile,
  resolveInstructionSync,
  syncInstructionWithSource,
} from './instruction.sync.requests';
import {
  CreateSyncProfile,
  InstructionSyncProfileDelete,
  InstructionSyncResolve,
  SyncInstruction,
} from '../types/instruction.request.types';
import { notifications } from '@mantine/notifications';

export const useInstructionCreateSyncProfile = () => {
  const queryClient = useQueryClient();
  return useMutation((profile: CreateSyncProfile) => createInstructionSyncProfile(profile), {
    onError: (_error) => {
      notifications.show({
        id: 'create-sync-profile',
        message: 'Sync Profile could not be created',
        color: 'red',
      });
    },
    onSuccess: (_, values) => {
      typeof values.projectId === 'undefined'
        ? queryClient.invalidateQueries(['product', values.to])
        : queryClient.invalidateQueries(['project', values.projectId]);

      notifications.show({
        id: 'create-sync-profile-success',
        message: 'Linked Product',
        color: 'green',
      });
    },
  });
};

export const useInstructionSync = () => {
  const queryClient = useQueryClient();

  return useMutation((data: SyncInstruction) => syncInstructionWithSource(data), {
    onError: (_error) => {
      notifications.show({
        id: 'sync-instruction-with-source',
        message: 'Could not initialize synchronization',
        color: 'red',
      });
    },
    onSuccess: (_, values) => {
      typeof values.projectId === 'undefined'
        ? queryClient.invalidateQueries(['product', values.targetId])
        : queryClient.invalidateQueries(['project', values.projectId]);
    },
  });
};

export const useInstructionSyncResolve = () => {
  const queryClient = useQueryClient();
  return useMutation((data: InstructionSyncResolve) => resolveInstructionSync(data), {
    onError: (_error) => {
      notifications.show({
        id: 'sync-resolve-error',
        message: 'Could not resolve synchronization issues',
        color: 'red',
      });
    },
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['project', values.projectId]);
      queryClient.invalidateQueries(['instruction-sync-profile', values.targetId, values.sourceId]);
    },
  });
};

export const useInstructionSyncProfileDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((data: InstructionSyncProfileDelete) => deleteInstructionSyncProfile(data), {
    onError: (_error) => {
      notifications.show({
        id: 'sync-profile-delete-error',
        message: 'Could not delete Sync Profile',
        color: 'red',
      });
    },
    onSuccess: (_, values) => {
      typeof values.projectId === 'undefined'
        ? queryClient.invalidateQueries(['product', values.targetId])
        : queryClient.invalidateQueries(['project', values.projectId]);
      queryClient.invalidateQueries(['instruction-sync-profile', values.targetId, values.sourceId]);

      notifications.show({
        id: 'delete-sync-profile-success',
        message: 'Unlinked Product',
        color: 'green',
      });
    },
  });
};
