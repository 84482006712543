import { useInstructionArtefactsQuery, useInstructionJobQuery } from '@assemblio/frontend/data-access';
import { useUIStore } from '@assemblio/frontend/stores';
import { Box, Divider, ScrollArea, Skeleton } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { ArtefactCard } from '../Cards/ArtefactCard/ArtefactCard';
import { JobCard } from '../Cards/JobCard';
import { ArtefactListHeader } from './ArtefactListHeader';
import { ListFilterOptions } from './ListTypeFilter';

interface ArtefactListProps {
  selectedInstructionId: string;
}

const FilterOptions: ListFilterOptions = {
  ALL: 'all',
  DOCUMENT: 'document',
  VIDEO: 'video',
};

export enum SortingValue {
  createdAt = 'Created At',
  name = 'Name',
}

export const ArtefactList = ({ selectedInstructionId }: ArtefactListProps) => {
  const [selectedFilter, setFilter] = useState<string>(Object.values(FilterOptions)[0]);
  const [sortingValue, setSortingValue] = useState<SortingValue>(SortingValue.createdAt);

  //TODO dont fetch jobs when not in editor
  const view = useUIStore((state) => state.view);

  const jobsQuery = useInstructionJobQuery(selectedInstructionId);
  const artefactsQuery = useInstructionArtefactsQuery(selectedInstructionId);
  const queryClient = useQueryClient();

  //TODO Should probably be done on the server
  const filteredArtefacts = useMemo(() => {
    let artefacts = artefactsQuery.data || [];

    if (selectedFilter !== FilterOptions['ALL']) {
      artefacts = artefacts.filter((artefact) => {
        if (selectedFilter === FilterOptions.DOCUMENT) {
          // Include artefacts with type 'docx' or 'pdf' for 'document' filter
          return artefact.type === 'docx' || artefact.type === 'pdf';
        }
        return artefact.type === selectedFilter;
      });
    }

    if (sortingValue === SortingValue.createdAt) {
      artefacts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } else if (sortingValue === SortingValue.name) {
      artefacts.sort((a, b) => a.originalName.localeCompare(b.originalName));
    }

    return artefacts;
  }, [selectedFilter, sortingValue, artefactsQuery.data]);

  return (
    <Box h={'55%'}>
      <ArtefactListHeader
        setFilter={setFilter}
        selectedFilter={selectedFilter}
        filterEnum={FilterOptions}
        sortingValue={sortingValue}
        setSortingValue={setSortingValue}
      />
      <ScrollArea h={'85%'} mah={'85%'} w={'100%'}>
        {filteredArtefacts &&
          filteredArtefacts.length > 0 &&
          filteredArtefacts.map((artefact, index) => (
            <ArtefactCard instructionId={selectedInstructionId} artefact={artefact} key={`artefact-card-${index}`} />
          ))}
        {view === 'editor' && (
          <>
            <Divider mb={10} />
            {jobsQuery.data &&
              jobsQuery.data.length > 0 &&
              jobsQuery.data.map((job, index) => (
                <JobCard
                  instructionId={selectedInstructionId}
                  job={job}
                  key={`job-card-${index}`}
                  onComplete={() => {
                    setTimeout(() => {
                      jobsQuery.refetch();
                      artefactsQuery.refetch();
                      // Only works when Artefact List is mounted.
                      // TODO: Rework architecture to be more reactive to job events.
                      queryClient.invalidateQueries({ queryKey: ['folder'] });
                    }, 100);
                  }}
                />
              ))}
          </>
        )}
      </ScrollArea>
    </Box>
  );
};

const ArtefactCardSkeleton = () => {
  return <Skeleton h={'70px'} radius={'md'} w={'100%'} mb={10} />;
};
