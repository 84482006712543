import { useNextProjectUpdate } from '@assemblio/frontend/data-access';
import { Button, Group, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { NextProject } from '../../types/project-structure.types';

interface NameSelectionProps {
  project: NextProject;
  projectName: string;
  form: UseFormReturnType<{
    projectName: string;
  }>;
  parentFolderId: string;
  handleClose: () => void;
}

export const NameSelection = ({
  project,
  projectName,
  form,
  parentFolderId,
  handleClose,
}: NameSelectionProps) => {
  const projectPatch = useNextProjectUpdate();

  const updateName = (projectName: string) => {
    const newName = projectName.trim();

    projectPatch.mutate(
      {
        projectId: project.id,
        projectData: {
          name: newName,
          projectId: project.id,
          folderId: parentFolderId,
        },
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'update-projectName-success',
            message: 'Project has been renamed',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'update-projectName-error',
            message: 'Project could not be renamed',
            color: 'red',
          });
        },
      }
    );
    handleClose();
  };
  return (
    <>
      <Text size={'sm'}>Rename Project</Text>

      <form
        onSubmit={form.onSubmit((values) => {
          updateName(values.projectName);
        })}
      >
        <Group
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
          }}
        >
          <TextInput
            style={{ width: '320px' }}
            placeholder={projectName}
            {...form.getInputProps('projectName')}
            data-cy="edit-project-name"
          />
          <Button
            disabled={projectPatch.isLoading}
            style={{ height: '36px' }}
            size="xs"
            type="submit"
            onClick={(event) => {
              event.stopPropagation();
            }}
            data-cy="apply-button"
          >
            Apply
          </Button>
        </Group>
      </form>
    </>
  );
};
