import { useNextProjectDelete } from '@assemblio/frontend/data-access';
import {
  ActionIcon,
  Group,
  Menu,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  IconDotsVertical,
  IconEdit,
  IconFolderSymlink,
  IconTrash,
} from '@tabler/icons-react';
import { NextProject } from '../../types/project-structure.types';
import classes from '../Cards.module.scss';
import { CardMenuItem } from '../ExplorerCard/CardMenuItem';
import cx from 'clsx';
import useAsyncConfirm from '../../../Utilities/useAsyncConfirmation';
type ProjectCardMenuProps = {
  item: NextProject;
  disabled?: boolean;
  folderId: string;
  openInstructionCreateModal?: (projectId: string) => void;
  openProjectChangeModal: (project: NextProject) => void;
  openProjectMoveModal: (project: NextProject) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onListItem?: boolean;
};

export function ProjectCardMenu({
  item,
  onListItem = false,
  disabled = false,
  folderId,
  openProjectChangeModal,
  openProjectMoveModal,
  setLoading,
}: ProjectCardMenuProps) {
  const theme = useMantineTheme();

  const deleteProjectMutation = useNextProjectDelete();
  const [getConfirmation, Confirmation] = useAsyncConfirm();
  const hasInstructions = (item.instructions?.length ?? 0) > 0;

  const deleteItem = () => {
    setLoading(true);
    deleteProjectMutation.mutate(
      {
        projectId: item.id,
        parentFolderId: folderId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'delete-project-success',
            message: 'Project has been deleted',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'delete-project-error',
            message: 'Project could not be deleted',
            color: 'red',
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  const handleDeleteProject = async () => {
    if (hasInstructions) {
      notifications.show({
        id: 'delete-project-error-has-instructions',
        title: `Cannot delete ${item.name}`,
        message:
          'Projects can only be deleted if they do not contain any products',
        color: 'red',
      });
      return;
    }
    const confirmation = await getConfirmation(
      `Delete ${item.name}`,
      `Are you sure you want to delete ${item.name}?`,
      'Delete'
    );
    if (!confirmation) return;
    deleteItem();
  };

  return (
    <>
      {onListItem ? (
        <Group>
          <Tooltip openDelay={500} label={'Edit Project'}>
            <ActionIcon
              variant={'filled'}
              onClick={() =>
                openProjectChangeModal && openProjectChangeModal(item)
              }
            >
              <IconEdit />
            </ActionIcon>
          </Tooltip>

          <Tooltip openDelay={500} label={'Move Project'}>
            <ActionIcon
              variant={'filled'}
              onClick={() => openProjectMoveModal && openProjectMoveModal(item)}
            >
              <IconFolderSymlink />
            </ActionIcon>
          </Tooltip>

          <Tooltip
            openDelay={500}
            label={
              hasInstructions
                ? 'Only empty projects can be deleted'
                : 'Delete Project'
            }
          >
            <ActionIcon
              variant={'filled'}
              disabled={hasInstructions}
              color={theme.other.color.brand.pink}
              onClick={handleDeleteProject}
            >
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        </Group>
      ) : (
        <Menu
          disabled={disabled || deleteProjectMutation.isLoading}
          position="bottom-start"
          withinPortal
          trapFocus={false}
          trigger={'hover'}
        >
          <Menu.Target>
            <ActionIcon
              data-cy="icon-dots-vertical"
              color="gray"
              className={cx(classes.explorerCard__menuButton, {
                [classes['explorerCard__menuButton--list']]: onListItem,
              })}
              variant="filled"
            >
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown data-cy="menu-dropdown">
            <CardMenuItem
              data-cy="edit-icon"
              Icon={IconEdit}
              label={'Rename'}
              onClick={() =>
                openProjectChangeModal && openProjectChangeModal(item)
              }
            />
            <CardMenuItem
              Icon={IconFolderSymlink}
              onClick={() => openProjectMoveModal && openProjectMoveModal(item)}
              label={'Move'}
            />
            <CardMenuItem
              disabled={hasInstructions}
              tooltipLabel={
                hasInstructions
                  ? 'Only empty projects can be deleted'
                  : undefined
              }
              Icon={IconTrash}
              onClick={handleDeleteProject}
              color={theme.other.color.brand.pink}
              label={'Delete'}
            />
          </Menu.Dropdown>
        </Menu>
      )}
      <Confirmation />
    </>
  );
}
