import { Badge, Flex } from '@mantine/core';

export interface ListFilterOptions {
  [key: string]: string;
}

export interface ListTypeFilterProps {
  filterEnum: ListFilterOptions;
  selectedFilter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}
export const ListTypeFilter = ({
  filterEnum,
  selectedFilter,
  setFilter,
}: ListTypeFilterProps) => {
  return (
    <Flex gap={'xs'}>
      {Object.keys(filterEnum).map((key) => (
        <Badge
          key={`badge-${key}`}
          style={{ cursor: 'pointer' }}
          onClick={() => setFilter(filterEnum[key])}
          variant={selectedFilter === filterEnum[key] ? 'filled' : 'light'}
        >
          {key}
        </Badge>
      ))}
    </Flex>
  );
};
