import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { ProjectType } from '@assemblio/shared/next-types';
import { BackgroundImage, Flex, Group, Text } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { getFormattedDateDifference } from '../../DateFormatter';
import classes from './ProjectCard.module.scss';
import { ProjectCardBadge } from './ProjectCardBadge';
import { ProjectCardContent } from './ProjectCardContent';
import { ProjectCardMenu } from './ProjectCardMenu';
import ExplorerCard from '../ExplorerCard/ExplorerCard';
import { CombinedProjectItemProps } from '../../types';
import { useState } from 'react';

const PLACEHOLDER_PATH = 'assets/editor/thumbnail-placeholder.png';

export const ProjectCard = ({
  project,
  folderId,
  onItemSelection,
  openInstructionCreateModal,
  openProjectChangeModal,
  openProjectMoveModal,
  onItemNavigation,
}: CombinedProjectItemProps) => {
  const view = useUIStore((state) => state.view);

  const [isLoading, setLoading] = useState(false);

  const isSelected =
    useProjectStore((state) => state.selectedExplorerItem) === project.id;

  const onProjectSelection = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onItemSelection(project.id);
  };

  const handleDoubleClick = () => {
    onItemNavigation(project.id);
  };

  return (
    <ExplorerCard
      loading={isLoading}
      data-cy="project-card"
      onDoubleClick={handleDoubleClick}
      onClick={onProjectSelection}
      selected={isSelected}
      href={`/explorer/project/${project.id}`}
    >
      <ExplorerCard.Header>
        <BackgroundImage
          className={classes.explorerCard__header__image}
          src={project.thumbnailPath ?? PLACEHOLDER_PATH}
          radius="sm"
        >
          {view === 'editor' && (
            <ProjectCardMenu
              setLoading={setLoading}
              folderId={folderId}
              item={project}
              openInstructionCreateModal={openInstructionCreateModal}
              openProjectChangeModal={openProjectChangeModal}
              openProjectMoveModal={openProjectMoveModal}
            />
          )}
          <ProjectCardBadge type={ProjectType.INSTRUCTION} />
        </BackgroundImage>
      </ExplorerCard.Header>

      <ExplorerCard.Body>
        <ProjectCardContent project={project} />
      </ExplorerCard.Body>

      <ExplorerCard.Footer data-cy="project-card-footer">
        <Flex
          direction={'row'}
          justify={'space-between'}
          style={{ width: '100%' }}
        >
          <Text ml={5} size={'10'} c="dimmed" data-cy="product-count">
            {project.instructions?.length === 0
              ? 'No Products'
              : project.instructions?.length === 1
              ? '1 Product'
              : `${project.instructions?.length} Products`}
          </Text>
          <Group gap={0}>
            <IconEdit size={14} color="gray" />
            <Text ml={5} size={'10'} c="dimmed">
              {getFormattedDateDifference(project.updatedAt)}
            </Text>
          </Group>
        </Flex>
      </ExplorerCard.Footer>
    </ExplorerCard>
  );
};
