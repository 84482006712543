import React from 'react';
import { Modal, Stack } from '@mantine/core';
import { FolderNameSelection } from './FolderNameSelection';
import { ProjectFolder } from '../../types/project-structure.types'; // Update the import path as necessary

interface FolderChangeModalProps {
  folder: ProjectFolder;
  isOpen: boolean;
  onRequestClose: () => void;
  onUpdate: (name: string) => void;
}

export const FolderChangeModal: React.FC<FolderChangeModalProps> = ({
  folder,
  isOpen,
  onRequestClose,
  onUpdate,
}: FolderChangeModalProps) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onRequestClose}
      title={`Edit Folder: ${folder.name}`}
      centered
      radius="md"
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <Stack>
        <FolderNameSelection
          folder={folder}
          onClose={onRequestClose}
          onUpdate={onUpdate}
        />
      </Stack>
    </Modal>
  );
};
