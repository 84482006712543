import { notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFolderUpdate } from '@assemblio/frontend/data-access';
import { useUIStore } from '@assemblio/frontend/stores';
import { FolderCardIcon } from './FolderCardIcon';
import { FolderCardMenu } from './FolderCardMenu';
import ExplorerCard from '../ExplorerCard/ExplorerCard';
import { CardFooterButton } from '../ExplorerCard/CardFooterButton';
import { CombinedFolderItemProps, ExplorerRoutingParams } from '../../types';
import { ProjectCount } from '../../../Utilities';
import { FolderChangeModal } from '../../Dialogs/FolderChangeModal/FolderChangeModal';
import { useExplorerState } from '../../useExplorerState';
import classes from '../Cards.module.scss';
import cx from 'clsx';

export const FolderCard = ({
  folder,
  rootFolderId,
  onFolderNavigation,
}: CombinedFolderItemProps) => {
  const view = useUIStore((state) => state.view);

  const { isListView } = useExplorerState();

  const { folderId } =
    useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;

  const updateFolder = useFolderUpdate();

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenRenameModal = () => setIsRenameModalOpen(true);

  const handleNameChange = (newName: string) => {
    setLoading(true);
    updateFolder.mutate(
      {
        parentFolderId: folderId ?? rootFolderId,
        folderId: folder.id,
        folderData: { ...folder, name: newName },
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'update-folderName-success',
            message: 'Folder has been renamed',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'update-folderName-error',
            message: 'Folder could not be renamed',
            color: 'red',
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  return (
    <ExplorerCard
      data-cy="folder-card"
      loading={loading}
      onClick={(e) => e.preventDefault()}
      onDoubleClick={(e) => {
        e.stopPropagation();
        onFolderNavigation(folder.id);
      }}
      href={`/explorer/${folder.id}`}
    >
      <ExplorerCard.Header>
        <FolderCardIcon color={folder.color} iconName={folder.icon} />

        {view === 'editor' && (
          <FolderCardMenu
            disabled={loading}
            parentFolderId={folderId ?? rootFolderId}
            setLoading={setLoading}
            onRename={handleOpenRenameModal}
            item={folder}
          />
        )}
        {isRenameModalOpen && (
          <FolderChangeModal
            folder={folder}
            isOpen={isRenameModalOpen}
            onRequestClose={() => setIsRenameModalOpen(false)}
            onUpdate={(newName) => handleNameChange(newName)}
          />
        )}
      </ExplorerCard.Header>
      <ExplorerCard.Body>
        <Text
          data-cy="folder-title-text"
          className={cx(classes.explorerCard__title, {
            [classes['explorerCard__title--list']]: isListView,
          })}
          lineClamp={3}
        >
          {folder.name}
        </Text>
      </ExplorerCard.Body>

      <ExplorerCard.Footer data-cy="card-footer">
        <ProjectCount projectCount={folder.projectCount} />
        <CardFooterButton
          label={'OPEN'}
          onClick={() => onFolderNavigation(folder.id)}
        />
      </ExplorerCard.Footer>
    </ExplorerCard>
  );
};
