import { useProjectDelete } from '@assemblio/frontend/data-access';
import { useIsOwner } from '@assemblio/frontend/hooks';
import { NextInstruction } from '../../types/project-structure.types';
import { Divider, Modal, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { ConfirmationDialog } from '@assemblio/frontend/components';
import { NameSelection } from './NameSelection';
import { OwnerSelection } from './OwnerSelection';
import { StateSelection } from './StateSelection';
import classes from '../Dialogs.module.scss';
interface InstructionChangeModalProps {
  instruction: NextInstruction;
  projectId: string;
  opened: boolean;
  close: (dirty: boolean) => void;
}

export const InstructionChangeModal = ({
  instruction,
  projectId,
  opened,
  close,
}: InstructionChangeModalProps) => {
  const isOwner = useIsOwner(instruction.owner.id);
  const menuRef = useClickOutside(() => handleClose());
  const deleteProject = useProjectDelete();

  const [deletionDialogOpened, setDeletionDialogOpened] = useState(false);
  const [newOwnerId, setNewOwnerId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [dirty, setDirty] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      instructionName: '',
    },
    validate: {
      instructionName: (value) => {
        const trimmedName = value.trim();
        form.setFieldValue('instructionName', trimmedName);
        if (trimmedName.length < 3 || trimmedName.length > 55) {
          return 'Field must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleClose = () => {
    form.reset();
    setNewOwnerId(null);
    setSearchQuery('');
    close(dirty);
    setDirty(false);
  };

  return (
    <>
      <Modal
        title={`Edit${'  '}${instruction.name}`}
        trapFocus
        onClick={(e) => e.stopPropagation()}
        withinPortal
        opened={opened}
        centered
        onClose={() => handleClose()}
        radius={0}
        classNames={{ header: classes.modalHeader, close: classes.modalClose }}
        data-cy="instruction-change-modal"
      >
        {isOwner ? (
          <Stack ref={menuRef}>
            <NameSelection
              instruction={instruction}
              instructionName={instruction.name}
              form={form}
              setDirty={setDirty}
            />

            <Divider />

            <StateSelection instruction={instruction} setDirty={setDirty} />

            <Divider />

            <OwnerSelection
              instructionId={instruction.id}
              instructionOwnerId={instruction.owner.id}
              newOwnerId={newOwnerId}
              setNewOwnerId={setNewOwnerId}
              projectId={projectId}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleClose={handleClose}
              setDirty={setDirty}
            />
          </Stack>
        ) : (
          <Stack ref={menuRef} data-cy="alert-modal">
            <Text size={'sm'} data-cy="alert-message">
              Only the owner {instruction.owner.fullName} can make changes to
              the product.
            </Text>
          </Stack>
        )}
      </Modal>
      <ConfirmationDialog
        onClose={(result) => {
          if (result) {
            deleteProject.mutate(instruction.id);
          }
          setDeletionDialogOpened(false);
          handleClose();
        }}
        opened={deletionDialogOpened}
        prompt={'Are you sure you want to delete this instruction?'}
      />
    </>
  );
};
