import { Collapse, Group, Select, Text } from '@mantine/core';

import { Resolution, resolutions } from '@assemblio/shared/next-types';
import { useState } from 'react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { VideoFormatPreset } from './types/export-modal.types';

type VideoExportSettingsProps = {
  presets: VideoFormatPreset[];
  resolution: Resolution;
  format: VideoFormatPreset;
  setResolution: React.Dispatch<React.SetStateAction<Resolution>>;
  onFormatSelection: (value: string) => unknown;
};

export const VideoExporterSettings = ({
  presets,
  resolution,
  format,
  setResolution,
  onFormatSelection,
}: VideoExportSettingsProps) => {
  const [openedAdvanced, setOpenedAdvanced] = useState(false);
  return (
    <Group mb={'xs'} gap={'xs'} w={'100%'}>
      <Group
        style={{ cursor: 'pointer' }}
        onClick={() => setOpenedAdvanced(!openedAdvanced)}
      >
        <Text size={'sm'}>Advanced Settings</Text>
        {openedAdvanced ? <IconChevronUp /> : <IconChevronDown />}
      </Group>

      <Collapse in={openedAdvanced}>
        <Select
          w={'100%'}
          label="Resolution"
          mb={'xs'}
          value={resolution}
          data={resolutions.map((res) => {
            return { value: res, label: res };
          })}
          allowDeselect={false}
          onOptionSubmit={(value) => setResolution(value as Resolution)}
        />

        <Select
          label="Format"
          value={format.label}
          onOptionSubmit={onFormatSelection}
          data={presets.map((preset) => preset.label)}
          allowDeselect={false}
        />
      </Collapse>
    </Group>
  );
};
