import {
  AppShell,
  Button,
  Divider,
  Group,
  ScrollArea,
  useMantineColorScheme,
} from '@mantine/core';
import classes from './Navbar.module.scss';
import { Logo } from '../ImageMark';
import { IconArrowLeft } from '@tabler/icons-react';

interface NavbarProps {
  onLogoClick?: () => unknown;
  onClose: () => void;
  children?: JSX.Element | JSX.Element[];
}

export const Navbar = ({ onLogoClick, children, onClose }: NavbarProps) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <AppShell.Navbar p="xs" className={classes.navbar}>
      <AppShell.Section grow component={ScrollArea} mt="md">
        <Group
          style={{
            cursor: 'pointer',
          }}
          justify="center"
          onClick={onLogoClick}
        >
          <Logo
            width={200}
            variant={colorScheme === 'dark' ? 'white' : 'dark'}
          />
        </Group>
        <Divider my={'sm'} />
        <Button
          justify="start"
          aria-label="back to explorer button"
          color="gray"
          variant="subtle"
          size="sm"
          mb={'sm'}
          leftSection={<IconArrowLeft />}
          onClick={onClose}
        >
          Go Back
        </Button>
        {children}
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
