import {
  Box,
  Text,
  Flex,
  Tooltip,
  BackgroundImage,
  Group,
  ThemeIcon,
} from '@mantine/core';
import { NextInstruction } from '../types/project-structure.types';
import { getFormattedDateDifference } from '../DateFormatter';
import { IconFile3d } from '@tabler/icons-react';
import { InstructionFileType } from '@assemblio/type/file';
import { InstructionFileMetadata } from '@assemblio/shared/next-types';

interface InstructionDetailsHeaderProps {
  instruction: NextInstruction;
}

export const InstructionDetailsHeader = ({
  instruction,
}: InstructionDetailsHeaderProps) => {
  const { name, thumbnailPath } = instruction;
  
  const getInstructionFileName = (instruction: NextInstruction) : string => {
    const stepFile = instruction.files.find((file: InstructionFileMetadata) => file.type === InstructionFileType.STEP);
    const gltfFile = instruction.files.find((file: InstructionFileMetadata) => file.type === InstructionFileType.GLTF);
    return (stepFile || gltfFile)?.name || '';
  };

  return (
    <BackgroundImage
      style={{
        height: '100%',
        backgroundPosition: '95%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
      h={'20%'}
      mih={'100px'}
      w={'100%'}
      src={thumbnailPath ?? '/assets/editor/thumbnail-placeholder.png'}
    >
      <Flex
        data-cy="instruction-details-header"
        w={'100%'}
        h={'100%'}
        direction={'column'}
        justify={'space-between'}
      >
        <Tooltip openDelay={500} label={name}>
          <Text
            fw={800}
            size={'xl'}
            maw={'calc(100% - 50px)'}
            lineClamp={1}
            truncate
            py={10}
            mih={'2.5rem'}
            data-cy="individual-instruction-name"
          >
            {name}
          </Text>
        </Tooltip>
        <Box w={'50%'} data-cy="individual-instruction-details">
          {/* TODO: maybe add Tooltip with exact date string to compare times  */}

          <Flex align={'center'} gap={'0.1rem'}>
            <Text size={'xs'} c={'dimmed'}>
              Last edited:{' '}
              {getFormattedDateDifference(instruction.updatedAt, false)}
            </Text>
          </Flex>
          <Text size={'xs'} c={'dimmed'}>
            Created {getFormattedDateDifference(instruction.createdAt, false)}{' '}
            by {instruction.owner.fullName}
          </Text>
          <Text size={'xs'} c={'dimmed'}>
            Owned by {instruction.owner.fullName}
          </Text>
          <Group wrap="nowrap" gap={10} mt={3}>
            <ThemeIcon color="brandPurple" variant="light">
              <IconFile3d
                stroke={0.9}
                style={{ width: '70%', height: '70%' }}
              />
            </ThemeIcon>
            <Text fz="xs" c="dimmed">
              {getInstructionFileName(instruction)}
            </Text>
          </Group>
        </Box>
      </Flex>
    </BackgroundImage>
  );
};
