import { NextProjectDto } from '@assemblio/shared/dtos';
import { useQuery } from '@tanstack/react-query';
import { fetchNextProject, fetchProjectContent } from './project.requests';
import { FetchProject } from './types/project.queries.types';

export const useNextProjectQuery = (
  projectId: string | undefined,
  ownerFilter: (project: NextProjectDto) => NextProjectDto
) => {
  return useQuery(
    ['project', projectId],
    () => {
      return fetchNextProject(projectId);
    },
    {
      select: ownerFilter,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useProjectContentQuery = (data: FetchProject) => {
  return useQuery(
    ['project', data.projectId],
    () => {
      return fetchProjectContent(data);
    },
    {
      select: data.ownerFilter,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};
