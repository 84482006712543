import { useAuthStore } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect } from 'react';
import { EventController } from '../controller';
import { useEventStore } from '../stores';
import { JobMessage } from '@assemblio/type/job';

type StringMap = {
  [key: string]: string;
};
// Todo: Use the JobQueue type from @assemblio/type
const jobNames: StringMap = {
  initialization: 'input parsing job',
  'pdf-renderer': 'document export job',
  'video-renderer': 'video export job',
  'step-to-gltf': 'project initialization job',
};

/**
 * Hook that subscribes to all globally relevant Websocket events
 */
export const useWebSocket = () => {
  const socket = useEventStore((state) => state.socket);
  const { accessToken, setSessionActive } = useAuthStore((state) => state);
  const queryClient = useQueryClient();
  useEffect(() => {
    // Internal socket.io connection established
    socket.on('connect', () => {
      EventController.setConnected(true);
    });

    socket.on('reconnect', () => {
      EventController.setConnected(true);
    });

    socket.on('disconnect', () => {
      EventController.setConnected(false);
    });

    socket.on('connect_error', (err) => {
      console.error(err.message);
    });

    socket.on('job-progress-event', (message) => {
      handleJobMessageEvent(message, queryClient);
    });

    socket.on('updateClientState', (newState) => {
      setSessionActive(newState === 'active');
    });

    return () => {
      socket.off('connect');
      socket.off('reconnect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off('job-progress-event');
      socket.off('updateClientState');
    };
  }, [socket, queryClient]);

  useEffect(() => {
    if (socket.disconnected && accessToken !== '') {
      EventController.connectSocket();
    }
  }, [socket, accessToken]);
};

const handleJobMessageEvent = (message: JobMessage, queryClient: QueryClient) => {
  switch (message.type) {
    case 'status':
      switch (message.payload.type) {
        case 'progress':
          EventController.setJobProgress(message);
          break;
        case 'state':
          EventController.setJobState(message);
          switch (message.payload.state) {
            case 'initialized':
              notifications.show({
                title: 'Job Initialized',
                message: `Your ${jobNames[message.jobType]} for project ${
                  message.instructionName
                } has been initialized.`,
                color: 'blue',
                autoClose: 5000,
              });
              break;
            case 'completed':
              notifications.show({
                title: 'Job Completed',
                message: `Your ${jobNames[message.jobType]} for project ${
                  message.instructionName
                } has completed successfully.`,
                color: 'green',
                autoClose: 5000,
              });
              _.defer(() => {
                EventController.removeJob(message.jobId);
                queryClient.invalidateQueries(['jobs', message.jobId]);
                // if (message.projectId && message.jobType === 'step-to-gltf') {
                //   queryClient.invalidateQueries(['project', message.projectId]);
                // }
              });

              break;
            case 'running':
              notifications.show({
                title: 'Job Running',
                message: `Your ${jobNames[message.jobType]} for project ${message.instructionName} is being processed.`,
                color: 'blue',
                autoClose: 5000,
              });
              break;
            case 'failed':
              notifications.show({
                title: 'Job Failed',
                message: `Your ${jobNames[message.jobType]} for project ${message.instructionName} has failed.`,
                color: 'red',
                autoClose: 5000,
              });
              _.defer(() => {
                queryClient.invalidateQueries(['jobs']);
              });
          }
          break;
        case 'prose':
          break;
      }
      break;
    case 'error':
      notifications.show({
        title: 'Error',
        message: `Your ${jobNames[message.jobType]} for project ${
          message.instructionName
        } didn't complete successfully. ${
          message.payload
        } Please try again. If the problem persists, please notify your administrator.`,
        color: 'red',
        autoClose: 5000,
      });
      EventController.deleteJobProgress(message.jobId);
      EventController.deleteJobState(message.jobId);
      break;

    case 'result':
      notifications.show({
        title: 'Job Completed',
        message: `Your ${jobNames[message.jobType]} for project ${message.instructionName} completed successfully. ${
          message.payload
        }`,
        color: 'green',
        autoClose: 5000,
      });
      _.defer(() => {
        EventController.removeJob(message.jobId);
        queryClient.invalidateQueries({
          queryKey: ['jobs'],
          refetchType: 'all',
        });
      });
      if (message.jobType === 'step-to-gltf') {
        queryClient.invalidateQueries({
          queryKey: ['projects'],
          refetchType: 'all',
        });
      }
      break;

    default:
      break;
  }
};
