import {
  Badge,
  DefaultMantineColor,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { ProjectType } from '@assemblio/shared/next-types';
import classes from '../Cards.module.scss';
interface ProjectCardBadgeProps {
  type: ProjectType;
}

export const ProjectCardBadge = ({ type }: ProjectCardBadgeProps) => {
  const theme = useMantineTheme();
  const badgeColors: { [key in ProjectType]: [DefaultMantineColor, string] } = {
    /**
     * Mapping of type to Badge TextColor and Badge BackgroundColor
     * Needed to override transparent background of light-BadgeVariant
     */
    [ProjectType.EMPTY]: ['violet', '#362765'],
    [ProjectType.INSTRUCTION]: ['violet', '#362765'],
    [ProjectType.INSTRUCTION_GROUP]: ['teal', '#203538'],
    [ProjectType.SEQUENCED_INSTRUCTION]: ['yellow', '#4C381D'],
    [ProjectType.PROJECT]: ['violet', '#362765'],
  };

  return (
    <Tooltip
      label={type.toUpperCase()}
      openDelay={500}
      color={badgeColors[type][0]}
    >
      <Badge
        radius={'sm'}
        color="brandPurple"
        size={'xs'}
        styles={{
          root: {
            color: theme.colors.brandPurple[9],
          },
          label: {
            color: theme.colors.brandPurple[1],
          },
        }}
        className={classes.explorerCard__badge}
        classNames={{
          root: classes.explorerCard__badge__label,
        }}
      >
        PROJECT
      </Badge>
    </Tooltip>
  );
};

//TODO: fix color values - also use in InstructionCreateButton
export const InstructionCardBadge = () => {
  const theme = useMantineTheme();
  return (
    <Tooltip label="PRODUCT" openDelay={500}>
      <Badge
        radius={'sm'}
        color={'instructionGreen'}
        size={'xs'}
        className={classes.explorerCard__badge}
        styles={{
          root: {},
          label: {
            color: theme.colors.instructionGreen[1],
          },
        }}
        classNames={{
          root: classes.explorerCard__badge__label,
        }}
      >
        PRODUCT
      </Badge>
    </Tooltip>
  );
};
