import { MetaDto, UserDto } from '@assemblio/shared/dtos';
import { InstructionDetailDto } from '../instruction';

export enum ProjectType {
  INSTRUCTION = 'instruction',
  SEQUENCED_INSTRUCTION = 'sequenced-instruction',
  INSTRUCTION_GROUP = 'instruction-group',
  PROJECT = 'project',
  EMPTY = 'empty',
}

export class CreateProjectDto {
  name!: string;
  ownerId!: string;
  folderId!: string;
}

export class ProjectDto {
  id!: string;
  name!: string;
  thumbnailPath?: string;
  ownerId!: string;
}

export class ProjectDetailDto {
  id!: string;
  folderId!: string;
  name!: string;
  thumbnailPath?: string;
  projectType!: ProjectType;
  creator?: UserDto;
  owner!: UserDto;
  instructions!: InstructionDetailDto[]; // check if this still works
  updatedAt!: Date;
  createdAt!: Date;
}

export class UpdateProjectDto {
  name?: string;
  // Todo: The creator most likely should not be updated. Remove?
  creatorId?: string;
  folderId?: string;
  thumbnailPath?: string;
}

export class PaginatedProjectDto {
  items!: Partial<ProjectDetailDto>[];
  meta!: MetaDto;
}

export class PaginatedProjectQuery {
  curPage?: number;
  perPage?: number;
  sort?: 'name' | 'created_at' | 'updated_at';
  order?: 'ASC' | 'DESC';
}
