import { UIController, useUIStore } from '@assemblio/frontend/stores';
import {
  ActionIcon,
  Popover,
  Slider,
  Space,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconRuler } from '@tabler/icons-react';

export const RotationSnapSettings = () => {
  const theme = useMantineTheme();
  const action = useUIStore((state) => state.transformGizmo.action);
  const snappingAngle = useUIStore(
    (state) => state.transformGizmo.snappingAngle
  );
  if (action === 'rotate') {
    return (
      <Popover position="right" width={200}>
        <Popover.Target>
          <Tooltip position="right" label="Set rotation snapping value">
            <ActionIcon
              style={{
                pointerEvents: 'auto',
              }}
              color={theme.other.color.brand.yellow}
              size="lg"
              variant="filled"
            >
              <IconRuler />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown>
          <Slider
            step={5}
            min={0}
            max={15}
            value={snappingAngle}
            marks={[
              { value: 0, label: 'Free' },
              { value: 5, label: '5°' },
              { value: 10, label: '10°' },
              { value: 15, label: '15°' },
            ]}
            onChange={(value) => {
              UIController.setSnappingAngle(value);
            }}
          />
          <Space h="md" />
        </Popover.Dropdown>
      </Popover>
    );
  }
  return undefined;
};
