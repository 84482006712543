import { useProjectStore } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip, useMantineColorScheme } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

export const ProjectButton = () => {
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();
  const projectId = useProjectStore((state) => state.projectId);
  const navigationTarget =
    projectId.length > 0 ? `/explorer/project/${projectId}` : '/explorer';
  return (
    <Tooltip openDelay={500} label="Return to Project">
      <ActionIcon
        variant="transparent"
        onClick={() => navigate(navigationTarget, { replace: true })}
      >
        <IconArrowLeft
          color={colorScheme === 'dark' ? 'white' : 'black'}
          style={{ width: '100%', height: '100%' }}
        />
      </ActionIcon>
    </Tooltip>
  );
};
