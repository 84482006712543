import { useDocumentTitle } from '@assemblio/frontend/hooks';
import { Box, Flex, Grid, Paper, Text } from '@mantine/core';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import classes from './ProjectExplorer.module.scss';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useDebouncedState, useDebouncedValue } from '@mantine/hooks';
import { wait } from '../Utilities';
import { ProjectController } from '@assemblio/frontend/stores';
import { ListView } from './ListView';
import { GridView } from './GridView';
import { ExplorerItem } from './types';
import { ExplorerHeader } from './ExplorerHeader';
import { EmptyViewCard } from './Cards/EmptyViewCard';
import { useExplorerState } from './useExplorerState';
import { useFeatureFlagsQuery } from '@assemblio/frontend/data-access';

export type ExplorerOutletContext = {
  setDetailComponent: Dispatch<SetStateAction<ReactNode>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<boolean>>;
  setItems: Dispatch<SetStateAction<ExplorerItem[]>>;
  ownerOnly: boolean;
};
/**
 * Wrapper for our Explorer Grids.
 * Exposes a context containing methods to set state and content for the grid.
 *
 *  can be accessed by using:
 * @example
 *  const { setItems, setLoading, setError, ... } = useOutletContext<ExplorerOutletContext>();
 *
 */

export const Explorer = () => {
  useDocumentTitle('selection');
  const ANIMATION_DURATION = Number(classes.animation_duration);
  const location = useLocation();
  const setSelectedItemId = ProjectController.setSelectedExplorerItemId;
  const [items, setItems] = useState<ExplorerItem[] | null>(null);
  const [detailComponent, setDetailComponent] = useState<ReactNode>();
  const [renderItems, setRender] = useState<ExplorerItem[]>([]);
  const [isEmpty, setEmpty] = useDebouncedState(false, 0);
  const [ownerOnly, setOwnerOnly] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useDebouncedState(false, ANIMATION_DURATION);
  const { isListView } = useExplorerState();

  const [prevLocation, setPrev] = useState(location.pathname);
  const [inInstruction] = useDebouncedValue(prevLocation.includes('/project'), 200);

  const afterTransition = () => {
    if (items) {
      setEmpty(items.length === 0);
    }
  };

  /**
   * delaying the render of new items to give animations time to run
   */
  useEffect(() => {
    async function delayedSet(items: ExplorerItem[]) {
      //only wait for animation when items are being replaced
      const waitFor = renderItems.length === 0 ? ANIMATION_DURATION : 0;
      await wait(waitFor).then(() => {
        setRender(items);
      });
    }

    if (items) {
      delayedSet(items).then(() => {
        afterTransition();
      });
    }
  }, [items]);

  /**
   * If pathName changes we can assume that there will be a new set of items to be rendered
   * We remove all items and reset the itemSelection
   */
  useEffect(() => {
    setPrev(location.pathname);
    return () => {
      if (prevLocation !== location.pathname) {
        setRender([]);
        setSelectedItemId('');
        setEmpty(false);
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    if (detailComponent === null) {
      setDetailComponent(<EmptyDetails />);
    }
  }, [detailComponent]);

  return (
    <Box component="div" className={classes.gridWrapper}>
      <Grid
        className={classes.mainGrid}
        gutter={'md'}
        classNames={{
          inner: classes.mainGrid__inner,
        }}
      >
        <Grid.Col
          className={classes.mainGrid__column}
          span={
            inInstruction
              ? {
                  xl: 7,
                  lg: 6,
                  md: 6,
                  xs: 6,
                }
              : 12
          }
        >
          <ExplorerHeader loading={loading} ownerOnly={ownerOnly} setOwnerOnly={setOwnerOnly}>
            <Outlet
              context={{
                setDetailComponent,
                setLoading,
                setError,
                ownerOnly,
                setItems,
              }}
            />
          </ExplorerHeader>

          {isEmpty ? (
            <EmptyViewCard />
          ) : isListView ? (
            <ListView items={renderItems} error={error} loading={loading} />
          ) : (
            <GridView items={renderItems} error={error} loading={loading} inInstruction={inInstruction} />
          )}
        </Grid.Col>
        <Grid.Col
          className={classes.mainGrid__column}
          span={inInstruction ? { xl: 5, lg: 6, md: 6, xs: 6 } : 0}
          mt={'70px'}
        >
          <Paper py="xs" radius={'lg'} className={classes.mainGrid__detailsPanel}>
            {inInstruction ? detailComponent : null}
          </Paper>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

const EmptyDetails = () => {
  return (
    <Flex align={'center'} justify={'center'} w={'100%'}>
      <Text c={'dimmed'}>Please select an instruction</Text>
    </Flex>
  );
};
