import {
  ActionIcon,
  Popover,
  SegmentedControl,
  useMantineTheme,
} from '@mantine/core';
import {
  IconArrowBigLeft,
  IconArrowBigRight,
  IconArrowLeft,
  IconCircleOff,
} from '@tabler/icons-react';

const Marker_Options = ['arrow', 'none'] as const;
type MarkerTuple = typeof Marker_Options;
type Marker = MarkerTuple[number];

export interface Props {
  onChange?: (marker: Marker) => void;
  value: Marker;
  start: boolean;
}

export const MarkerChooser = ({ onChange, start, value }: Props) => {
  const theme = useMantineTheme();

  return (
    <Popover>
      <Popover.Target>
        <ActionIcon
          style={{ cursor: 'pointer' }}
          size="lg"
          variant="filled"
          color={theme.other.color.brand.yellow}
        >
          {start ? <IconArrowBigLeft /> : <IconArrowBigRight />}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <SegmentedControl
          onChange={(val) => {
            if (Marker_Options.includes(val as Marker) && onChange) {
              onChange(val as Marker);
            }
          }}
          value={value}
          data={[
            {
              value: 'arrow',
              label: <IconArrowLeft />,
            },
            {
              value: 'none',
              label: <IconCircleOff />,
            },
          ]}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
