import { useInstructionSync } from '@assemblio/frontend/data-access';
import { useUIStore } from '@assemblio/frontend/stores';
 import { FeatureFlag } from '@assemblio/type/feature-flag';
import { Box, Button, Divider } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ExportModal } from '../../ExportModal/ExportModal';
import { useHasFeatureFlag } from '../../Utilities/useHasFeatureFlag.hook';
import { NextInstruction, NextInstructionVariant } from '../types/project-structure.types';
import { ArtefactList } from './ArtefactList';
import classes from './InstructionDetails.module.scss';
import { InstructionDetailsHeader } from './InstructionDetailsHeader';
import { VariantInformation } from './VariantDisplay/VariantInformation';
import { useProductWebsocketListeners } from '../hooks/ProductWebsocketListener';
import { InstructionState } from '@assemblio/type/instruction';

interface InstructionDetailsProps {
  instruction: NextInstruction;
  onOpenResolveModal: (instruction: NextInstruction, sourceVariant: NextInstructionVariant) => void;
}

export const InstructionDetails = ({ instruction, onOpenResolveModal }: InstructionDetailsProps) => {
  useProductWebsocketListeners(instruction.id);
  const syncMutation = useInstructionSync();
  const isEditor = useUIStore.getState().view === 'editor';
  const [openedExporter, { toggle }] = useDisclosure(false);

  const isVariantFeatureEnabled = useHasFeatureFlag(FeatureFlag.Variants);

  const displayVariantInformation =
    (instruction.synced.from.length > 0 || instruction.synced.to.length > 0) && isVariantFeatureEnabled;

  return (
    <Box className={classes.detailsWrapper}>
      <InstructionDetailsHeader instruction={instruction} />
      {displayVariantInformation && (
        <VariantInformation
          onOpenResolveModal={onOpenResolveModal}
          syncMutationLoading={syncMutation.isLoading}
          instruction={instruction}
        />
      )}
      <Divider my={10} />
      <ArtefactList selectedInstructionId={instruction.id} />
      {isEditor && (
        <>
          <Button
            className={classes.detailsWrapper__exportButton}
            onClick={toggle}
            disabled={instruction.state === InstructionState.INITIALIZING}
          >
            Export
          </Button>
          <ExportModal opened={openedExporter} close={toggle} instructionId={instruction.id} name={instruction.name} />
        </>
      )}
    </Box>
  );
};
