import { PropsWithChildren, forwardRef } from 'react';
import classes from './TreeItem.module.scss';

export interface Props {
  onClick?: () => void;
  className?: string;
  color: string;
  size: string;
  disabled: boolean;
}

export const LightWeightIcon = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Props>
>(({ onClick, color, size, disabled, children }, ref) => (
  <div
    className={classes['lw-icon']}
    style={{
      background: color,
      width: size,
      height: size,
    }}
    data-disabled={disabled}
    onClick={disabled ? undefined : onClick ?? undefined}
  >
    {children}
  </div>
));
