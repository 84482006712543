import { UIController } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconFocusCentered } from '@tabler/icons-react';

export const CameraCenterButton = () => {
  const theme = useMantineTheme();
  return (
    <Tooltip position="right" label="Center Camera on Selection">
      <ActionIcon
        style={{
          pointerEvents: 'auto',
        }}
        color={theme.other.color.brand.yellow}
        size="lg"
        variant="filled"
        onClick={() => UIController.centerCameraOnSelection()}
      >
        <IconFocusCentered />
      </ActionIcon>
    </Tooltip>
  );
};
