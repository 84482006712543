import { NextProject } from '../../types/project-structure.types';
import { Flex, Text } from '@mantine/core';
import classes from '../Cards.module.scss';
interface ProjectCardContentProps {
  withTitle?: boolean;
  project: NextProject;
}
export const ProjectCardContent = ({
  project,
  withTitle = true,
}: ProjectCardContentProps) => {
  return (
    <Flex h={'100%'} direction={'column'} style={{ flexGrow: 1 }}>
      {withTitle && (
        <Text
          className={classes.explorerCard__title}
          data-cy="project-card-title"
        >
          {project.name}
        </Text>
      )}

      <SimpleContent />
    </Flex>
  );
};

const SimpleContent = () => {
  return null;
};
