import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { Box, Button, Stack, Text } from '@mantine/core';
import { useRef } from 'react';
import classes from './FileDropzone.module.scss';
import { IconCloudUpload } from '@tabler/icons-react';

interface FileDropzoneProps {
  acceptedFileTypes: string[];
  handleFileDrop: (files: FileWithPath[]) => void;
}

// 5 MB
const MAX_FILE_SIZE = 5 * 1024 * 1024;

//TODO: check why Dropzone.Accept and Dropzone.Reject are not working correctly
export const FileDropzone = ({ handleFileDrop, acceptedFileTypes }: FileDropzoneProps) => {
  const openRef = useRef<() => void>(null);

  return (
    <Stack mih={209} justify="center" align="center" gap={'lg'} className={classes.container}>
      <IconCloudUpload size={40} color={'var(--border-quaternary-color)'} />
      <Box>
        <Text variant={'medium'} size={'lg'} c={'text-secondary'} inline>
          Drag and drop here
        </Text>
        <Box mt={'sm'} className={classes.dragDrop}>
          <Dropzone
            maxFiles={1}
            maxSize={MAX_FILE_SIZE}
            openRef={openRef}
            accept={{
              'application/STEP': acceptedFileTypes,
            }}
            onDrop={handleFileDrop}
          >
            <Button px={'md'} py={'sm'}>
              Choose a file
            </Button>
          </Dropzone>
        </Box>
      </Box>
      <Text size={'sm'} c={'var(--text-primary)'}>
        Compatible Formats: {acceptedFileTypes.join(', ')}
      </Text>
    </Stack>
  );
};
