import { useBulkSetPartColor, useResetPartColor, useSetPartColor } from '@assemblio/frontend/data-access';
import { ModelController } from '@assemblio/frontend/stores';
import { ASSEMBLIO_PART_COLORS, COLOR_REEL, ColorCode } from '@assemblio/frontend/types';
import { ActionIcon, ColorSwatch, Divider, Group, Menu, Stack, Tooltip, useMantineTheme } from '@mantine/core';
import { IconDice6, IconPalette, IconRotate } from '@tabler/icons-react';
import classes from '../Viewport.module.scss';

export const ColorPicker = () => {
  const theme = useMantineTheme();

  const setPartColorMutation = useSetPartColor();
  const resetPartColorMutation = useResetPartColor();
  const bulkSetParColorMutation = useBulkSetPartColor();

  const handleColorChangeOnSelectedPart = (colorCode: ColorCode) => {
    const partIdsWithPrevColor = ModelController.setColorToSelectedParts(colorCode.dec);
    if (partIdsWithPrevColor.length > 0) {
      setPartColorMutation.mutate(
        {
          partIds: partIdsWithPrevColor.map((partAndColor) => partAndColor.partId),
          color: colorCode.dec.toString(),
        },
        {
          onError: () => {
            ModelController.setColorToPartsById(partIdsWithPrevColor);
          },
        }
      );
    }
  };

  const handleReset = () => {
    const partIdsWithPrevColor = ModelController.resetColorOfSelectedParts();
    if (partIdsWithPrevColor.length > 0)
      resetPartColorMutation.mutate(
        {
          ids: partIdsWithPrevColor.map((partAndColor) => partAndColor.partId),
        },
        {
          onError: () => {
            ModelController.setColorToPartsById(partIdsWithPrevColor);
          },
        }
      );
  };

  const handleRandomize = () => {
    const partColors = ModelController.setRandomColorToSelectedParts(ASSEMBLIO_PART_COLORS);
    if (partColors.length > 0) {
      bulkSetParColorMutation.mutate(partColors, {
        onError: () => {
          ModelController.setColorToPartsById(partColors);
        },
      });
    }
  };

  const swatches = ASSEMBLIO_PART_COLORS.map((color) => (
    <ColorSwatch
      size={14}
      key={`swatch-color-${color.code.hex}-assemblio`}
      color={color.code.hex}
      radius="xs"
      onClick={() => handleColorChangeOnSelectedPart(color.code)}
      className={classes.colorPicker__colorButton}
    />
  ));

  const colorReelSwatches = COLOR_REEL.flatMap((colors, index) => {
    const swatches = colors.map((color) => (
      <ColorSwatch
        size={14}
        key={`swatch-color-${color.code.hex}-reel`}
        color={color.code.hex}
        radius="xs"
        onClick={() => handleColorChangeOnSelectedPart(color.code)}
        className={classes.colorPicker__colorButton}
      />
    ));

    return (
      <Stack gap={1} key={`color-stack-${index}`}>
        {swatches}
      </Stack>
    );
  });

  return (
    <Menu closeOnItemClick={false}>
      <Menu.Target>
        <ActionIcon
          color={theme.other.color.brand.yellow}
          size="lg"
          style={{
            pointerEvents: 'auto',
          }}
          variant="filled"
        >
          <IconPalette />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item className={classes.colorPicker__item}>
          <Group justify="center" gap="xs" style={{ flexWrap: 'nowrap' }}>
            {swatches}
            <Divider orientation="vertical" size="sm" />
            <Tooltip label="Reset Color" position="bottom" offset={20}>
              <ActionIcon size={20} variant="transparent" onClick={handleReset}>
                <IconRotate size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Randomize Color" position="bottom" offset={20}>
              <ActionIcon size={20} variant="transparent" onClick={() => handleRandomize()}>
                <IconDice6 size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Group gap="xs" mt={3}>
            {colorReelSwatches}
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
