import { useEffect, useRef, useState } from 'react';
import ReactCrop, {
  PixelCrop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';
import classes from '../UserProfile.module.scss';
import { ActionIcon, Box, Stack } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
interface ImageCropperProps {
  imageUrl: string;
  onCropImage: (croppedImage: Blob) => void;
}

const LOGO_ASPECT_RATIO = 16 / 9;

export const ImageCropper = ({ imageUrl, onCropImage }: ImageCropperProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    const initialCrop = centerAspectCrop(width, height, LOGO_ASPECT_RATIO);
    setCompletedCrop(convertToPixelCrop(initialCrop, width, height));
    generateBlobFromCroppedCanvas(
      convertToPixelCrop(initialCrop, width, height)
    );
  }

  const updateCrop = (crop: PixelCrop) => {
    // Ignore cropping if no crop is selected
    if (crop.height === 0) {
      return;
    }
    setCompletedCrop(crop);
  };

  const generateBlobFromCroppedCanvas = (crop: PixelCrop) => {
    const image = imgRef.current;
    if (!image) {
      console.debug('Image does not exist');
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('No 2d context for cropping');
      return;
    }

    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // Fill Canvas with white background
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);

    // Move the origin to the center of the original position
    ctx.translate(centerX, centerY);

    // Scale the image
    ctx.scale(scale, scale);

    // Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );
    canvas.toBlob((blob) => {
      if (blob) {
        onCropImage(blob);
      }
    }, 'image/png');
  };

  const handleZoom = (e: React.WheelEvent<HTMLDivElement>) => {
    const scrollUp = e.deltaY < 0;
    if (!scrollUp && scale <= 0.15) return;
    if (scrollUp && scale >= 5) return;
    setScale((scale) => (scrollUp ? scale + 0.1 : scale - 0.1));
  };

  useEffect(() => {
    if (completedCrop) generateBlobFromCroppedCanvas(completedCrop);
  }, [scale]);

  return (
    <Box onWheel={handleZoom} style={{ position: 'relative' }}>
      <ReactCrop
        className={classes.cropper}
        crop={completedCrop}
        onChange={updateCrop}
        onComplete={(crop) => generateBlobFromCroppedCanvas(crop)}
        aspect={LOGO_ASPECT_RATIO}
        minHeight={50}
      >
        <img
          ref={imgRef}
          style={{
            minHeight: '250px',
            maxHeight: '500px',
            transform: `scale(${scale})`,
            objectFit: 'contain',
          }}
          alt="Crop me"
          src={imageUrl}
          onLoad={onImageLoad}
        />
      </ReactCrop>
      <Stack
        style={{
          position: 'absolute',
          left: '2%',
          top: '3%',
        }}
      >
        <ActionIcon onClick={() => setScale((scale) => scale + 0.1)}>
          <IconPlus />
        </ActionIcon>
        <ActionIcon onClick={() => setScale((scale) => scale - 0.1)}>
          <IconMinus />
        </ActionIcon>
      </Stack>
    </Box>
  );
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
