import { useProjectDelete } from '@assemblio/frontend/data-access';
import { useIsOwner } from '@assemblio/frontend/hooks';
import { Modal, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClickOutside } from '@mantine/hooks';
import { NextProject } from '../../types/project-structure.types';
import { useState } from 'react';
import { ConfirmationDialog } from '../../../Utilities/ConfirmationDialog';
import { NameSelection } from './NameSelection';
import classes from '../Dialogs.module.scss';

interface ProjectChangeModalProps {
  project: NextProject;
  currendFolderId: string;
  opened: boolean;
  close: () => void;
}

export const ProjectChangeModal = ({
  project,
  currendFolderId,
  opened,
  close,
}: ProjectChangeModalProps) => {
  const isOwner = useIsOwner(project.owner.id);
  const menuRef = useClickOutside(() => handleClose());

  const deleteProject = useProjectDelete();

  const [deletionDialogOpened, setDeletionDialogOpened] = useState(false);
  const [ownerId, setOwnerId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const form = useForm({
    initialValues: {
      projectName: '',
    },
    validate: {
      projectName: (value) => {
        const trimmedName = value.trim();
        form.setFieldValue('projectName', trimmedName);
        if (trimmedName.length < 3 || trimmedName.length > 55) {
          return 'Field must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleClose = () => {
    form.reset();
    setOwnerId(null);
    setSearchQuery('');
    close();
  };

  return (
    <>
      <Modal
        title={`Edit${' '}${project.name}`}
        trapFocus
        withinPortal
        opened={opened}
        centered
        onClose={() => handleClose()}
        radius={0}
        classNames={{ header: classes.modalHeader, close: classes.modalClose }}
        data-cy="project-change-modal"
      >
        {isOwner ? (
          <Stack ref={menuRef}>
            <NameSelection
              parentFolderId={currendFolderId}
              project={project}
              projectName={project.name}
              form={form}
              handleClose={handleClose}
            />
          </Stack>
        ) : (
          <Stack ref={menuRef} data-cy="alert-modal">
            <Text size={'sm'} color="white" data-cy="alert-message">
              Only the owner {project.owner.fullName} can make changes to the
              project.
            </Text>
          </Stack>
        )}
      </Modal>
      <ConfirmationDialog
        onClose={(result) => {
          if (result) {
            deleteProject.mutate(project.id);
          }
          setDeletionDialogOpened(false);
          handleClose();
        }}
        opened={deletionDialogOpened}
        prompt={'Are you sure you want to delete this project?'}
      />
    </>
  );
};
