import { useUIStore } from '@assemblio/frontend/stores';
import { InstructionCreateButton } from './Dialogs/InstructionCreateButton';

interface InstructionExplorerHeaderProps {
  disabled?: boolean;
  projectId: string;
}

export const InstructionExplorerHeaderItems = ({
  disabled = false,
  projectId,
}: InstructionExplorerHeaderProps) => {
  const view = useUIStore((state) => state.view);
  return view === 'editor' ? (
    <InstructionCreateButton disabled={disabled} projectId={projectId} />
  ) : null;
};
