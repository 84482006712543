import { useUIStore } from '@assemblio/frontend/stores';
import { useParams } from 'react-router-dom';
import { forwardRef, useState } from 'react';
import { Group, Stack, Table, useMantineTheme } from '@mantine/core';
import { FolderCardMenu } from '../Cards/FolderCard/FolderCardMenu';
import classes from './Rows.module.scss';
import { IconFolder } from '@tabler/icons-react';
import { CombinedFolderItemProps, ExplorerRoutingParams } from '../types';
import { ProjectCount } from '../../Utilities';
import { useFolderUpdate } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { ToggableItemName } from '../ToggableItemName';
import { ProjectFolder } from '../types/project-structure.types';
import { useMediaQuery } from '@mantine/hooks';

export const FolderRow = forwardRef<
  HTMLTableRowElement,
  CombinedFolderItemProps
>(
  (
    { folder, rootFolderId, onFolderNavigation }: CombinedFolderItemProps,
    ref
  ) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

    const { folderId } =
      useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;
    const [loading, setLoading] = useState(false);
    const updateFolder = useFolderUpdate();

    const handleNameChange = (newName: string) => {
      setLoading(true);
      updateFolder.mutate(
        {
          parentFolderId: folderId ?? rootFolderId,
          folderId: folder.id,
          folderData: { ...folder, name: newName },
        },
        {
          onSuccess: () => {
            notifications.show({
              id: 'update-folderName-success',
              message: 'Folder has been renamed',
              color: 'green',
            });
          },
          onError: () => {
            notifications.show({
              id: 'update-folderName-error',
              message: 'Folder could not be renamed',
              color: 'red',
            });
          },
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    };
    return (
      <Table.Tr
        ref={ref}
        onClick={(e) => e.preventDefault()}
        onDoubleClick={(e) => {
          e.stopPropagation();
          onFolderNavigation(folder.id);
        }}
        key={folder.id}
        className={classes.itemRow}
      >
        {isMobile ? (
          <CompactRow
            folder={folder}
            onNameChange={handleNameChange}
            rootFolderId={rootFolderId}
            setLoading={setLoading}
            loading={loading}
          />
        ) : (
          <Row
            folder={folder}
            onNameChange={handleNameChange}
            rootFolderId={rootFolderId}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </Table.Tr>
    );
  }
);

interface RowContentProps {
  onNameChange: (newName: string) => void;
  folder: ProjectFolder;
  rootFolderId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const Row = ({
  onNameChange,
  folder,
  rootFolderId,
  loading,
  setLoading,
}: RowContentProps) => {
  const view = useUIStore((state) => state.view);
  const { folderId } =
    useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;
  return (
    <>
      <Table.Td>
        <Group>
          <IconFolder />
          <ToggableItemName
            handleChange={onNameChange}
            name={folder.name}
            withDoubleClick
          />
        </Group>
      </Table.Td>
      <Table.Td colSpan={2}>
        <ProjectCount projectCount={folder.projectCount} />
      </Table.Td>
      <Table.Td>
        {view === 'editor' && (
          <FolderCardMenu
            disabled={loading}
            parentFolderId={folderId ?? rootFolderId}
            setLoading={setLoading}
            item={folder}
            onListItem
          />
        )}
      </Table.Td>
    </>
  );
};

const CompactRow = ({
  onNameChange,
  folder,
  rootFolderId,
  loading,
  setLoading,
}: RowContentProps) => {
  const view = useUIStore((state) => state.view);
  const { folderId } =
    useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;
  return (
    <Table.Td colSpan={4}>
      <Group>
        <IconFolder />
        <Stack>
          <ToggableItemName
            handleChange={onNameChange}
            name={folder.name}
            withDoubleClick
          />
          <ProjectCount projectCount={folder.projectCount} />

          {view === 'editor' && (
            <FolderCardMenu
              disabled={loading}
              parentFolderId={folderId ?? rootFolderId}
              setLoading={setLoading}
              item={folder}
              onListItem
            />
          )}
        </Stack>
      </Group>
    </Table.Td>
  );
};
