import { UIController } from '@assemblio/frontend/stores';
import { ActionIcon, Menu, Tooltip, useMantineTheme } from '@mantine/core';
import {
  IconDeviceDesktop,
  IconDeviceTvOld,
  IconScreenShare,
} from '@tabler/icons-react';

export const PreviewSwitch = () => {
  const theme = useMantineTheme();

  return (
    <Menu position="right">
      <Menu.Target>
        <Tooltip
          position="right"
          label="Switch between different Preview Resolutions"
        >
          <ActionIcon
            style={{
              pointerEvents: 'auto',
            }}
            color={theme.other.color.brand.yellow}
            size="lg"
            variant="filled"
          >
            <IconDeviceDesktop />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() => UIController.setPreviewRatio('4/3')}
          leftSection={<IconDeviceTvOld />}
        >
          4:3
        </Menu.Item>
        {/*
        <Menu.Item
          onClick={() => UIController.setPreviewRatio('16/10')}
          leftSection={<DeviceDesktop />}
        >
          16:10
        </Menu.Item>
  */}
        <Menu.Item
          onClick={() => UIController.setPreviewRatio('16/9')}
          leftSection={<IconDeviceDesktop />}
        >
          16:9 (Default Export Option)
        </Menu.Item>
        {/*
        <Menu.Item
          onClick={() => UIController.setPreviewRatio('pdf')}
          leftSection={<Book2 />}
        >
          PDF
        </Menu.Item>
        */}
        <Menu.Item
          onClick={() => UIController.setPreviewRatio('auto')}
          leftSection={<IconScreenShare />}
        >
          Full Screen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
