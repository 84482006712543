import { Button, Modal, Text } from '@mantine/core';
import React, { useState } from 'react';

// type for the getConfirmation function
type GetConfirmation = (
  text: string,
  message: string,
  buttonLabel?: string
) => Promise<boolean>;
const useAsyncConfirm = (): [GetConfirmation, React.FC] => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState<{
    resolve: (status: boolean) => void;
  }>({ resolve: () => null });
  const [label, setLabel] = useState('');
  const [message, setMessage] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');

  const getConfirmation: GetConfirmation = async (
    text,
    message,
    buttonLabel = 'Open'
  ) => {
    setLabel(text);
    setMessage(message);
    setButtonLabel(buttonLabel);
    setOpen(true);
    const [promise, resolve] = createPromise();
    setResolver({ resolve });
    return promise;
  };

  const onClick = async (status: boolean) => {
    setOpen(false);
    resolver.resolve(status);
  };

  const Confirmation = () => (
    <Modal centered opened={open} onClose={() => setOpen(false)}>
      <Text fw={700} size={'lg'} mb={'xs'}>
        {label}
      </Text>
      <Text c="dimmed" size={'sm'}>
        {message}
      </Text>
      <Button.Group mt={'md'}>
        <Button onClick={() => onClick(true)}>{buttonLabel}</Button>
        <Button onClick={() => onClick(false)} variant={'outline'}>
          Cancel
        </Button>
      </Button.Group>
    </Modal>
  );

  return [getConfirmation, Confirmation];
};

const createPromise = (): [Promise<boolean>, (status: boolean) => unknown] => {
  let resolver: (status: boolean | PromiseLike<boolean>) => void = () => null;
  return [
    new Promise<boolean>((resolve) => {
      resolver = resolve;
    }),
    resolver,
  ];
};

export default useAsyncConfirm;
