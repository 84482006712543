import { useMantineTheme, Group, Tooltip, Checkbox } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

interface CheckboxWithHintProps {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
  hintText?: string;
}

export const CheckboxWithHint = ({
  onChange,
  checked,
  label,
  hintText,
}: CheckboxWithHintProps) => {
  const theme = useMantineTheme();
  return (
    <Group gap={0} mb={'xs'}>
      <Checkbox
        checked={checked}
        onChange={(e) => onChange(e.currentTarget.checked)}
        label={label}
      />
      {hintText && (
        <Tooltip label={hintText}>
          <IconInfoCircle
            style={{
              width: theme.fontSizes.md,
              height: theme.fontSizes.md,
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      )}
    </Group>
  );
};
