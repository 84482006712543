import { useProjectStore } from '@assemblio/frontend/stores';
import { Group, Text } from '@mantine/core';
import cx from 'clsx';
import { useLocation } from 'react-router-dom';
import { ImageMark } from '../ImageMark';
import classes from './BottomBar.module.scss';
import { ProjectButton } from './ProjectButton';
import UserButton from './UserButton';
interface BottomBarProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  showCenterinExplorer?: boolean;
}

export const BottomBar = ({
  left,
  center,
  right,
  showCenterinExplorer = true,
}: BottomBarProps) => {
  const location = useLocation();

  const inDisassembler = location.pathname.startsWith('/disassembler');
  const inExporter = location.pathname.startsWith('/exporter');
  const inExplorer = location.pathname.startsWith('/explorer');

  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <Group>
          <ImageMark accented={false} />
          {!inExplorer && <ProjectButton />}
          {left}
        </Group>
      </div>
      {(inDisassembler || inExporter) && <CurrentInstructionInformation />}

      <div className={cx(classes.center)}>
        {(showCenterinExplorer || !inExplorer) && center}
      </div>

      <div className={classes.right}>
        <UserButton />
      </div>
    </div>
  );
};

const CurrentInstructionInformation = () => {
  const instructionName = useProjectStore((state) => state.name);

  return (
    <Group>
      <Text size="xs" style={{ color: 'white', cursor: 'default' }}>
        {instructionName}
      </Text>
    </Group>
  );
};
