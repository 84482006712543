import {
  Box,
  Button,
  Divider,
  Group,
  List,
  Modal,
  Radio,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import { NextInstruction } from '../../types/project-structure.types';
import { useState } from 'react';
import classes from '../Dialogs.module.scss';
import { BetaBadge } from '../../../Common';
import { IconInfoSquareRounded } from '@tabler/icons-react';
import { useInstructionSyncProfileDelete } from '@assemblio/frontend/data-access';
import { ProjectRoutingParams } from '../../types';
import { useParams } from 'react-router-dom';
import { SyncProfileState } from '@assemblio/shared/next-types';
import { RadioItemWarning } from '../components/RadioItemUnavailableWarning';
import { notifications } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';

interface InstructionDeleteSyncProfileModalProps {
  instruction: NextInstruction;
  opened: boolean;
  onClose: () => void;
}

export const InstructionDeleteSyncProfileModal = ({
  instruction,
  opened,
  onClose,
}: InstructionDeleteSyncProfileModalProps) => {
  const queryClient = useQueryClient();
  const { projectId } =
    useParams<ProjectRoutingParams>() as ProjectRoutingParams;
  const deleteSyncProfileMutation = useInstructionSyncProfileDelete();
  const deletableSyncProfiles = instruction.synced.from;
  const [profileToDelete, setProfileToDelete] = useState<string | undefined>(
    undefined
  );
  const handleCloseModal = () => {
    setProfileToDelete(undefined);
    onClose();
  };

  const handleDelete = () => {
    if (!profileToDelete) return;
    deleteSyncProfileMutation.mutate(
      {
        sourceId: profileToDelete,
        targetId: instruction.id,
        projectId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'delete-sync-profile-success',
            message: 'Unlinked Product',
            color: 'green',
          });
          queryClient.invalidateQueries([
            'comparable-instructions',
            instruction.id,
          ]);
        },
      }
    );
    onClose();
  };
  return (
    <Modal
      onClose={onClose}
      opened={opened}
      size={'lg'}
      title={<BetaBadge />}
      styles={{
        header: {
          paddingBottom: 0,
        },
      }}
    >
      <Text>
        Unlink{' '}
        <Text span fw={700}>
          {instruction.name}
        </Text>
      </Text>

      <InstructionVariantUnlinkInfo />

      <Divider my={'xs'} />

      <Text>Select a base product to unlink: </Text>

      <Radio.Group value={profileToDelete} onChange={setProfileToDelete}>
        <Stack mx={'lg'} mt={'lg'} mb={'xl'} gap={'lg'}>
          {deletableSyncProfiles.map((profile) => {
            const isDeletable = !(
              profile.status === SyncProfileState.PROCESSING ||
              profile.status === SyncProfileState.INITIALISING
            );
            return (
              <Group
                key={`sync-profile-to-delete-${profile.id}`}
                justify="space-between"
                gap={0}
              >
                <Radio
                  label={profile.name}
                  value={profile.id}
                  disabled={!isDeletable}
                  maw={!isDeletable ? '60%' : '100%'}
                  classNames={{
                    labelWrapper: classes.variantRadioList__label,
                  }}
                />
                {!isDeletable && (
                  <RadioItemWarning reason={'Product is still processing'} />
                )}
              </Group>
            );
          })}
        </Stack>
      </Radio.Group>

      <Button.Group mt={'xl'} style={{ justifyContent: 'flex-end' }}>
        <Button
          variant={'outline'}
          onClick={(e) => {
            handleCloseModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!profileToDelete}
          onClick={() => {
            handleDelete();
          }}
        >
          Unlink
        </Button>
      </Button.Group>
    </Modal>
  );
};

const InstructionVariantUnlinkInfo = () => {
  return (
    <Box>
      <Text c={'dimmed'} size={'xs'}>
        After unlinking a product, you won't be able to synchronize assembly
        steps from the base product anymore. You can always re-link the product
        later.
      </Text>
      <List
        size={'xs'}
        pl={10}
        pt={10}
        pb={10}
        icon={
          <IconInfoSquareRounded style={{ width: rem(24), height: rem(24) }} />
        }
      >
        <List.Item>
          The current parts and steps for the product will not be affected.
        </List.Item>
      </List>

      <Text c={'dimmed'} size={'xs'}>
        If you have further questions, please contact our support.{' '}
      </Text>
    </Box>
  );
};
