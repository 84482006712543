import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
 import { BackgroundImage, Flex, Group, Loader, Text, ThemeIcon } from '@mantine/core';
import { IconEdit, IconLink } from '@tabler/icons-react';
import { getFormattedDateDifference } from '../../DateFormatter';
import classes from '../Cards.module.scss';
import { InstructionCardBadge } from '../ProjectCard/ProjectCardBadge';
import { InstructionCardMenu } from './InstructionCardMenu';
import { useInstructionStateQuery } from '@assemblio/frontend/data-access';
import ExplorerCard from '../ExplorerCard/ExplorerCard';
import { CombinedInstructionItemProps } from '../../types';
import { useState } from 'react';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { useHasFeatureFlag } from '../../../Utilities/useHasFeatureFlag.hook';
import { InstructionState } from '@assemblio/type/instruction';

const PLACEHOLDER_PATH = 'assets/editor/thumbnail-placeholder.png';

export const InstructionCard = ({
  project,
  instruction,
  navigationTarget,
  onInstructionSelection,
  openInstructionChangeModal,
  openCreateVariantModalOpened,
  onOpenInstruction,
  openDeleteSyncProfileModal,
}: CombinedInstructionItemProps) => {
  const view = useUIStore((state) => state.view);
  useInstructionStateQuery(instruction.id, project.id, instruction.state === InstructionState.INITIALIZING);

  const [isLoading, setLoading] = useState(false);
  const isVariantFeatureEnabled = useHasFeatureFlag(FeatureFlag.Variants);

  const isHighlighted = useProjectStore((state) => state.highlightedInstructionId) === instruction.id;
  const isSelected = useProjectStore((state) => state.selectedExplorerItem) === instruction.id;

  const showLoader =
    instruction.state === InstructionState.INITIALIZING || instruction.state === InstructionState.PROCESSING;

  const showContextMenu =
    instruction.state !== InstructionState.INITIALIZING && instruction.state !== InstructionState.PROCESSING;

  const showLinkIcon = isVariantFeatureEnabled && instruction.synced.from.length > 0;
  const handleInstructionSelection = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onInstructionSelection(instruction.id);
  };

  return (
    <ExplorerCard
      loading={isLoading}
      data-cy="instruction-card"
      onClick={handleInstructionSelection}
      onDoubleClick={() => onOpenInstruction(instruction)}
      href={`${navigationTarget}/${instruction.id}`}
      selected={isSelected}
      highlighted={isHighlighted}
    >
      <ExplorerCard.Header>
        <BackgroundImage
          className={classes.explorerCard__header__image}
          src={project.thumbnailPath ?? PLACEHOLDER_PATH}
          radius="sm"
        >
          {view === 'editor' && showContextMenu && (
            <InstructionCardMenu
              projectId={project.id}
              instruction={instruction}
              openCreateVariantModalOpened={openCreateVariantModalOpened}
              openInstructionChangeModal={openInstructionChangeModal}
              openDeleteSyncProfileModal={openDeleteSyncProfileModal}
              setLoading={setLoading}
            />
          )}
          <InstructionCardBadge />
        </BackgroundImage>
      </ExplorerCard.Header>

      <ExplorerCard.Body>
        <Text data-cy="instruction-title-text" className={classes.explorerCard__title} lineClamp={3}>
          {instruction.name}
        </Text>
        {showLinkIcon && (
          <ThemeIcon
            color="instructionGreen"
            style={{
              alignSelf: 'end',
              margin: 'var(--mantine-spacing-xs)',
            }}
          >
            <IconLink />
          </ThemeIcon>
        )}
      </ExplorerCard.Body>

      <ExplorerCard.Footer data-cy="instruction-card-footer">
        <Flex direction={'row'} justify={'space-between'} style={{ width: '100%' }}>
          <Group gap={0}>
            <IconEdit size={14} color="gray" />
            <Text ml={5} size={'10px'} c="dimmed">
              {getFormattedDateDifference(instruction.updatedAt)}
            </Text>
          </Group>
          <Group gap={0}>
            {showLoader && <Loader size="12px" variant="dots" />}
            <Text ml={5} size={'10px'} c="dimmed">
              {instruction.state}
            </Text>
          </Group>
        </Flex>
      </ExplorerCard.Footer>
    </ExplorerCard>
  );
};
