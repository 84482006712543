import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { SetStateAction, forwardRef, useState } from 'react';
import {
  Group,
  Stack,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { ProjectCardMenu } from '../Cards/ProjectCard/ProjectCardMenu';
import classes from './Rows.module.scss';
import textClasses from '../Cards/ExplorerCard/ExplorerCard.module.scss';

import { IconEdit, IconSquare } from '@tabler/icons-react';
import { getFormattedDateDifference } from '../DateFormatter';
import { CombinedProjectItemProps, ProjectItemHandlers } from '../types';
import { useMediaQuery } from '@mantine/hooks';
import { NextProject } from '../types/project-structure.types';
import cx from 'clsx';
export const ProjectRow = forwardRef<
  HTMLTableRowElement,
  CombinedProjectItemProps
>(
  (
    {
      project,
      folderId,
      onItemSelection,
      openInstructionCreateModal,
      openProjectChangeModal,
      openProjectMoveModal,
      onItemNavigation,
    }: CombinedProjectItemProps,
    ref
  ) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

    const isSelected =
      useProjectStore((state) => state.selectedExplorerItem) === project.id;
    const onProjectSelection = (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      onItemSelection(project.id);
    };

    const [loading, setLoading] = useState(false);

    const handleDoubleClick = () => {
      onItemNavigation(project.id);
    };
    return (
      <Table.Tr
        onDoubleClick={handleDoubleClick}
        onClick={onProjectSelection}
        ref={ref}
        key={project.id}
        data-selected={isSelected}
        className={classes.itemRow}
      >
        {isMobile ? (
          <CompactRow
            loading={loading}
            setLoading={setLoading}
            folderId={folderId}
            openInstructionCreateModal={openInstructionCreateModal}
            openProjectChangeModal={openProjectChangeModal}
            openProjectMoveModal={openProjectMoveModal}
            project={project}
          />
        ) : (
          <Row
            loading={loading}
            setLoading={setLoading}
            folderId={folderId}
            openInstructionCreateModal={openInstructionCreateModal}
            openProjectChangeModal={openProjectChangeModal}
            openProjectMoveModal={openProjectMoveModal}
            project={project}
          />
        )}
      </Table.Tr>
    );
  }
);

interface RowContentProps {
  project: NextProject;
  folderId: string;
  loading: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
}

const Row = ({
  project,
  folderId,
  loading,
  setLoading,
  openInstructionCreateModal,
  openProjectChangeModal,
  openProjectMoveModal,
}: RowContentProps &
  Omit<ProjectItemHandlers, 'onItemNavigation' | 'onItemSelection'>) => {
  const theme = useMantineTheme();
  const view = useUIStore((state) => state.view);
  return (
    <>
      <Table.Td>
        <Group>
          <IconSquare
            fill={theme.other.color.brand.purple}
            color={theme.other.color.brand.purple}
            stroke={theme.other.color.brand.purple}
          />
          <Text
            className={cx(
              textClasses.explorerCard__title,
              textClasses['explorerCard__title--list']
            )}
          >
            {project.name}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Text size={'xs'} c="dimmed">
          {project.instructions?.length ?? 0} Products
        </Text>
      </Table.Td>
      <Table.Td>
        <Tooltip label="Updated at" openDelay={500}>
          <Group gap={'xs'} display={'inline-flex'}>
            <IconEdit size={14} color={'gray'} />
            <Text size={'xs'} c="dimmed">
              {getFormattedDateDifference(project.updatedAt)}
            </Text>
          </Group>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        {view === 'editor' && (
          <ProjectCardMenu
            disabled={loading}
            folderId={folderId}
            item={project}
            onListItem
            setLoading={setLoading}
            openInstructionCreateModal={openInstructionCreateModal}
            openProjectChangeModal={openProjectChangeModal}
            openProjectMoveModal={openProjectMoveModal}
          />
        )}
      </Table.Td>
    </>
  );
};
const CompactRow = ({
  project,
  folderId,
  loading,
  setLoading,
  openInstructionCreateModal,
  openProjectChangeModal,
  openProjectMoveModal,
}: RowContentProps &
  Omit<ProjectItemHandlers, 'onItemNavigation' | 'onItemSelection'>) => {
  const theme = useMantineTheme();
  const view = useUIStore((state) => state.view);
  return (
    <Table.Td colSpan={4}>
      <Group>
        <IconSquare
          fill={theme.other.color.brand.purple}
          color={theme.other.color.brand.purple}
          stroke={theme.other.color.brand.purple}
        />
        <Stack>
          <Text
            className={cx(textClasses.explorerCard__title, {
              [textClasses['explorerCard__title--list']]: true,
            })}
          >
            {project.name}
          </Text>
          <Group>
            <Text size={'xs'} c="dimmed">
              {project.instructions?.length ?? 0} Products
            </Text>

            <Tooltip label="Updated at" openDelay={500}>
              <Group gap={'xs'} display={'inline-flex'}>
                <IconEdit size={14} color={'gray'} />
                <Text>{getFormattedDateDifference(project.updatedAt)}</Text>
              </Group>
            </Tooltip>
          </Group>

          {view === 'editor' && (
            <ProjectCardMenu
              disabled={loading}
              folderId={folderId}
              item={project}
              onListItem
              setLoading={setLoading}
              openInstructionCreateModal={openInstructionCreateModal}
              openProjectChangeModal={openProjectChangeModal}
              openProjectMoveModal={openProjectMoveModal}
            />
          )}
        </Stack>
      </Group>
    </Table.Td>
  );
};
