import { usePlaybackState } from '@assemblio/frontend/hooks';
import {
  AnimationController,
  MachineController,
  useAnimationStore,
} from '@assemblio/frontend/stores';
import {
  ActionIcon,
  Box,
  Card,
  Flex,
  Group,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import {
  IconArrowBarToRight,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlayerSkipBackFilled,
  IconPlayerSkipForwardFilled,
  IconPlayerStopFilled,
  IconRepeat,
  IconRepeatOff,
} from '@tabler/icons-react';
import { Seeker } from './Seeker';
import React, { useState } from 'react';
import classes from './PlaybackControls.module.scss';
import { useHover } from '@mantine/hooks';
import cx from 'clsx';
interface PlaybackControlsProps {
  showRepeatButton?: boolean;
}

export const PlaybackControls = ({
  showRepeatButton = true,
}: PlaybackControlsProps) => {
  const { colorScheme } = useMantineColorScheme();
  const [collapsed, setCollapsed] = useState(false);
  const theme = useMantineTheme();

  const brandYellow = theme.other.color.brand.yellow;

  const { hovered, ref } = useHover();

  const loopAnimation = useAnimationStore((state) => state.loop);

  const playbackState = usePlaybackState();

  const iconSize = 22;
  const actionIconSize = 'lg';

  const playIconSize = 32;
  const playActionIconSize = 'xl';

  const loopIconColorInactive =
    theme.colors.gray[colorScheme === 'dark' ? 6 : 5];

  const toggleCollapse = (e: React.MouseEvent) => {
    setCollapsed(!collapsed);
  };

  return (
    <Box
      ref={ref}
      className={cx(classes.wrapper, {
        [classes['wrapper--collapsed']]: collapsed,
      })}
    >
      <ActionIcon
        color="gray.6"
        variant="subtle"
        size={'xs'}
        className={cx(classes.wrapper__collapseButton, {
          [classes['wrapper__collapseButton--hidden']]: !hovered,
          [classes['wrapper__collapseButton--collapsed']]: collapsed,
        })}
        onClick={toggleCollapse}
      >
        <IconArrowBarToRight />
      </ActionIcon>
      <Card
        p={'xs'}
        radius={'md'}
        className={cx(classes.wrapper__card, {
          [classes['wrapper__card--collapsed']]: collapsed,
        })}
      >
        <Flex
          gap="xs"
          justify="center"
          align="center"
          direction={'column'}
          style={{
            width: '100%',
          }}
        >
          <Group
            className={cx(classes.wrapper__card__actions, {
              [classes['wrapper__card__actions--collapsed']]: collapsed,
            })}
            wrap="nowrap"
            justify="center"
            gap="md"
          >
            <ActionIcon
              data-cy="icon-player-stop-button"
              color={brandYellow}
              variant="transparent"
              style={{
                background: 'transparent',
              }}
              disabled={playbackState === 'stopped'}
              size={actionIconSize}
              onClick={() => MachineController.stopAnimation()}
            >
              <IconPlayerStopFilled size={iconSize} />
            </ActionIcon>
            <ActionIcon
              data-cy="icon-player-skip-back"
              color={brandYellow}
              variant="transparent"
              size={actionIconSize}
              onClick={() => MachineController.skipBackward()}
            >
              <IconPlayerSkipBackFilled size={iconSize} />
            </ActionIcon>
            {playbackState === 'playing' ? (
              <ActionIcon
                data-cy="icon-player-pause-button"
                color={brandYellow}
                variant="light"
                size={playActionIconSize}
                onClick={() => MachineController.pauseAnimation()}
              >
                <IconPlayerPauseFilled size={playIconSize} />
              </ActionIcon>
            ) : (
              <ActionIcon
                data-cy="icon-player-play-button"
                color={brandYellow}
                variant="light"
                size={playActionIconSize}
                onClick={() => MachineController.playAnimation()}
              >
                <IconPlayerPlayFilled size={playIconSize} />
              </ActionIcon>
            )}
            <ActionIcon
              data-cy="icon-player-skip-forward"
              color={brandYellow}
              variant="transparent"
              size={actionIconSize}
              onClick={() => MachineController.skipForward()}
            >
              <IconPlayerSkipForwardFilled size={iconSize} />
            </ActionIcon>
            {showRepeatButton && (
              <ActionIcon
                data-looping={loopAnimation}
                variant="transparent"
                color={loopAnimation ? brandYellow : loopIconColorInactive}
                size={actionIconSize}
                onClick={() => AnimationController.toggleLoopAnimations()}
              >
                {loopAnimation ? (
                  <IconRepeat size={iconSize} />
                ) : (
                  <IconRepeatOff size={iconSize} />
                )}
              </ActionIcon>
            )}
          </Group>
          <Seeker />
        </Flex>
      </Card>
    </Box>
  );
};
