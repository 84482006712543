import { useUpdateAnnotation } from '@assemblio/frontend/data-access';
import { AnnotationController, useAnnotationStore } from '@assemblio/frontend/stores';
import { AnnotationMetaDto, ArrowAnnotation } from '@assemblio/shared/next-types';
import { Group } from '@mantine/core';
import _ from 'lodash';
import { useRef } from 'react';
import { useCameraCast } from '../hooks';
import { useAnchor } from './Anchor';
import { ColorChooser } from './ColorChooser';
import { CommonTools } from './CommonTools';
import { MarkerChooser } from './MarkerChooser';
import { SVGAnnotation } from './SVGAnnotation';
import { DragData, DragInterface, SVGDraggable } from './SVGDraggable';
import { SizeSetter } from './SizeSetter';
import { ToolbarGroup } from './ToolbarGroup';

const getPath = ([x, y]: [number, number], [tx, ty]: [number, number]) => {
  return `M ${x} ${y} L ${tx} ${ty}`;
};

interface Props {
  annotationId: string;
  annotation: ArrowAnnotation;
}

export const Arrow = ({ annotation, annotationId }: Props) => {
  const headInterface = useRef<DragInterface>(null);
  const tailInterface = useRef<DragInterface>(null);
  const head = useRef({ x: annotation.x, y: annotation.y });
  const tail = useRef({ x: annotation.tx, y: annotation.ty });
  const updateAnnotationMutation = useUpdateAnnotation();

  const cast = useCameraCast();
  const mode = useAnnotationStore((state) => state.annotationMap.get(annotationId)?.mode);

  const lineRef = useRef<SVGPathElement>(null);
  useAnchor(
    annotation.anchorHead,
    lineRef,
    annotation.anchorHead.type === 'annotation'
      ? { x: annotation.tx, y: annotation.ty }
      : { x: annotation.x, y: annotation.y },
    (pos) => {
      head.current.x = pos.x;
      head.current.y = pos.y;
      headInterface.current?.setPosition(pos.x, pos.y);
      lineRef.current && lineRef.current.setAttribute('d', getPath([pos.x, pos.y], [tail.current.x, tail.current.y]));
    }
  );

  useAnchor(
    annotation.anchorTail,
    lineRef,
    annotation.anchorTail.type === 'annotation'
      ? { x: annotation.x, y: annotation.y }
      : { x: annotation.tx, y: annotation.ty },
    (pos) => {
      tail.current.x = pos.x;
      tail.current.y = pos.y;
      tailInterface.current?.setPosition(pos.x, pos.y);
      lineRef.current && lineRef.current.setAttribute('d', getPath([head.current.x, head.current.y], [pos.x, pos.y]));
    }
  );

  const onDragFrom = (d: DragData) => {
    if (lineRef.current) {
      const position = {
        x: d.localX - d.offsetLocalX,
        y: d.localY - d.offsetLocalY,
      };
      lineRef.current.setAttribute('d', getPath([position.x, position.y], [tail.current.x, tail.current.y]));
      head.current.x = position.x;
      head.current.y = position.y;
    }
  };
  const onDragTo = (d: DragData) => {
    if (lineRef.current) {
      const position = {
        x: d.localX - d.offsetLocalX,
        y: d.localY - d.offsetLocalY,
      };
      lineRef.current.setAttribute('d', getPath([head.current.x, head.current.y], [position.x, position.y]));
      tail.current.x = position.x;
      tail.current.y = position.y;
    }
  };

  const persistAnnotation = (meta: Partial<AnnotationMetaDto>, persist = true) => {
    const wasUpdated = AnnotationController.updateAnnotation(annotationId, meta);
    if (wasUpdated && persist) {
      updateAnnotationMutation.mutate({ id: annotationId, meta });
    }
  };

  const changeArrowHead = (start: boolean, head: 'none' | 'arrow') => {
    const meta = start ? { markerHead: head } : { markerTail: head };
    persistAnnotation(meta);
  };

  const changeArrowSize = (size: number) => {
    persistAnnotation({ lineWidth: size / 100 });
  };

  const changeArrowColor = (color: string, persist: boolean) => {
    persistAnnotation({ color }, persist);
  };

  const updateArrow = (d: DragData, isHead: boolean) => {
    const targetAnnotation = useAnnotationStore.getState().hoveredAnnotation;
    const data = isHead ? { x: d.cndcX, y: d.cndcY } : { tx: d.cndcX, ty: d.cndcY };
    const endField = isHead ? 'anchorHead' : 'anchorTail';

    if (targetAnnotation) {
      _.set(data, endField, {
        type: 'annotation',
        annotationId: targetAnnotation,
        position: null,
      });
    } else {
      const target = cast({ x: d.cndcX, y: d.cndcY });
      if (target) {
        _.set(data, endField, {
          type: '3d',
          position: target.position.clone(),
          annotationId: null,
        });
      } else {
        _.set(data, endField, {
          type: 'relative',
          position: null,
          annotationId: null,
        });
      }
    }

    persistAnnotation(data);
  };

  return (
    <SVGAnnotation
      initialMode="display"
      annotationId={annotationId}
      toolbar={
        <Group gap="sm">
          <ToolbarGroup label="Line Options">
            <SizeSetter onChange={changeArrowSize} size={annotation.lineWidth * 100} />
            <MarkerChooser
              start={true}
              value={annotation.markerHead}
              onChange={(head) => changeArrowHead(true, head)}
            />
            <MarkerChooser
              start={false}
              value={annotation.markerTail}
              onChange={(head) => changeArrowHead(false, head)}
            />
            <ColorChooser color={annotation.color} onChange={(color, persist) => changeArrowColor(color, persist)} />
          </ToolbarGroup>
          <ToolbarGroup label="Common Options">
            <CommonTools annotationId={annotationId} />
          </ToolbarGroup>
        </Group>
      }
    >
      <>
        <defs>
          <marker
            id={`arrowhead-${annotationId}`}
            viewBox={'0 0 1 1'}
            refX={'0.5'}
            refY={'0.5'}
            orient={'auto-start-reverse'}
            preserveAspectRatio={'xMinYMin'}
            fill={annotation.color ? annotation.color : 'white'}
          >
            <path d={'M 0 0 L 1 0.5 L 0 1 z'} />
          </marker>
        </defs>
        <path
          d={getPath([head.current.x, head.current.y], [tail.current.x, tail.current.y])}
          id={annotationId}
          markerEnd={annotation.markerTail === 'none' ? '' : `url(#arrowhead-${annotationId})`}
          markerStart={annotation.markerHead === 'none' ? '' : `url(#arrowhead-${annotationId})`}
          style={{
            strokeWidth: annotation.lineWidth,
            pointerEvents: mode === 'display' ? 'auto' : 'none',
          }}
          stroke={annotation.color ? annotation.color : 'white'}
          fill={'none'}
          ref={lineRef}
        />

        <text dy={-0.04} textAnchor="middle" fill="#fff" style={{ fontSize: 0.02 }}>
          <textPath startOffset={'50%'} href={`#${annotationId}`}>
            {annotation.text}
          </textPath>
        </text>
        {mode === 'editing' && (
          <>
            <SVGDraggable
              x={head.current.x}
              y={head.current.y}
              onDrag={onDragFrom}
              onDragEnd={(d: DragData) => updateArrow(d, true)}
              ref={headInterface}
            >
              <circle r={0.015} fill="black" fillOpacity={0.3} stroke={'white'} strokeWidth={0.005} />
            </SVGDraggable>
            <SVGDraggable
              x={tail.current.x}
              y={tail.current.y}
              onDrag={onDragTo}
              onDragEnd={(d: DragData) => updateArrow(d, false)}
              ref={tailInterface}
            >
              <circle r={0.015} fill="black" fillOpacity={0.3} stroke={'white'} strokeWidth={0.005} />
            </SVGDraggable>
          </>
        )}
      </>
    </SVGAnnotation>
  );
};
