import { ListItemDto, TextChild, TextDto, TextElement } from '@assemblio/shared/dtos';

interface LeafProps {
  child: TextChild;
  attributes?: { [key: string]: any };
}

export const Leaf = ({ child, attributes }: LeafProps): JSX.Element => {
  let text = <span {...attributes}>{child.text}</span>;
  if (child.bold) {
    text = <strong>{text}</strong>;
  }
  if (child.italic) {
    text = <em>{text}</em>;
  }
  if (child.underline) {
    text = <u>{text}</u>;
  }
  if (child.color) {
    text = <span style={{ color: child.color }}>{text}</span>;
  }
  return <span {...attributes}>{text}</span>;
};

const ListItem = ({ item }: { item: ListItemDto }) => {
  return (
    <li>
      {item.children.map((child) => {
        return <Leaf child={child} />;
      })}
    </li>
  );
};

const renderTextChildren = (leafs: Array<TextChild>) => {
  return leafs.map((leaf, index) => {
    return <Leaf child={leaf} key={`leaf-${index}`} />;
  });
};

const renderListItemChildren = (leafs: Array<ListItemDto>) => {
  return leafs.map((leaf, index) => {
    return <ListItem item={leaf} key={`list-item-${index}`} />;
  });
};

interface ElementProps {
  element: TextElement;
  attributes?: { [key: string]: any };
}

export const Element = ({ element, attributes }: ElementProps) => {
  const style = { textAlign: element.align, wordWrap: 'break-word', overflowWrap: 'break-word' } as React.CSSProperties;

  switch (element.type) {
    case 'heading': {
      const children = renderTextChildren(element.children);
      switch (element.level) {
        case 1:
          return <h1 style={style}>{children}</h1>;
        case 2:
          return <h2 style={style}>{children}</h2>;
        case 3:
          return <h3 style={style}>{children}</h3>;
        default:
          return (
            <p style={style} {...attributes}>
              {children}
            </p>
          );
      }
    }
    case 'ordered-list': {
      const children = renderListItemChildren(element.children);
      return <ol style={style}>{children}</ol>;
    }
    case 'unordered-list': {
      const children = renderListItemChildren(element.children);
      return <ul style={style}>{children}</ul>;
    }
    case 'paragraph': //Fallthrough, default is paragraph for now
    default: {
      const children = renderTextChildren(element.children);
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
    }
  }
};

export const TextRenderer = ({ text: { elements } }: { text: TextDto }) => {
  return (
    <div style={{ whiteSpace: 'normal', overflowX: 'hidden', userSelect: 'none' }}>
      {elements.map((element, index) => {
        return <Element element={element} key={`text-renderer-element-${index}`} />;
      })}
    </div>
  );
};

export const MultiTextRenderer = ({ texts }: { texts: TextDto[] }) => {
  return (
    <div style={{ whiteSpace: 'normal', overflowX: 'hidden', userSelect: 'none', wordBreak: 'break-word' }}>
      {texts.map((text, textIndex) => {
        return text.elements.map((element, index) => {
          return <Element element={element} key={`text-renderer-text-${textIndex}-element-${index}`} />;
        });
      })}
    </div>
  );
};
