import { Checkbox, Group } from '@mantine/core';
import { useStepModification } from '../Hooks/useStepModification';
import classes from '../Styles/StepSettings.module.scss';
import { StepNumberInput } from './StepNumberInput';
import cx from 'clsx';
interface StepSettingsProps {
  stepId: string;
  selected: boolean;
  stepType: string;
  mtm: number;
  animationSpeed: number;
  playWithAbove: boolean;
  isInteractable: boolean;
  isAlignmentStep: boolean;
}

export const StepSettings = ({
  stepId,
  selected,
  stepType,
  mtm,
  animationSpeed,
  playWithAbove,
  isInteractable,
  isAlignmentStep,
}: StepSettingsProps) => {
  const { handleChangeType, handleChangePlayWithAbove, handleMTMChange, handleChangeAnimationSpeed } =
    useStepModification();

  return (
    <>
      <Group
        pl={3}
        my={'xs'}
        gap={0}
        justify="space-between"
        data-cy="sequencer-step-settings"
        className={classes.settings_group}
      >
        <Checkbox
          className={classes.settings_group__section}
          classNames={{
            label: cx(classes.checkboxLabel, 'global__label', {
              'global__label--selected': selected,
            }),
          }}
          data-cy="is-alignment-step-checkbox"
          size="xs"
          label={'Is Alignment Step'}
          disabled={!isInteractable}
          checked={isAlignmentStep}
          onChange={() => handleChangeType(stepId, stepType)}
        />
        <Checkbox
          className={classes.settings_group__section}
          classNames={{
            label: cx(classes.checkboxLabel, 'global__label', {
              'global__label--selected': selected,
            }),
          }}
          size="xs"
          disabled={!isInteractable}
          label={'Play With Above'}
          checked={playWithAbove}
          onChange={(e) => handleChangePlayWithAbove(e, stepId)}
        />
      </Group>
      <Group pl={3} mb={'lg'} className={classes.settings_group}>
        <StepNumberInput
          stepId={stepId}
          selected={selected}
          disabled={!isInteractable}
          value={mtm}
          onSetValue={handleMTMChange}
          label={'MTM'}
          min={1}
          max={36000}
        />
        <StepNumberInput
          stepId={stepId}
          selected={selected}
          disabled={!isInteractable}
          value={animationSpeed}
          onSetValue={handleChangeAnimationSpeed}
          label={'Animation Speed'}
          min={0.1}
          max={10}
        />
      </Group>
    </>
  );
};
