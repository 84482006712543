import { Step as StepDef } from '@assemblio/shared/next-types';
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';
import { Box, Flex, Tooltip, useMantineTheme, Text } from '@mantine/core';
import { InnerStepListMemo } from './InnerStepList';

interface StepListProps {
  listId: string;
  listType?: string;
  steps: StepDef[];
  isDropDisabled?: boolean;
}

export const StepList = ({
  listId,
  listType,
  steps,
  isDropDisabled,
}: StepListProps) => {
  const theme = useMantineTheme();
  return (
    <Droppable
      droppableId={listId}
      type={listType}
      isDropDisabled={isDropDisabled}
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot
      ) => (
        <div {...dropProvided.droppableProps}>
          <Box>
            <Box ref={dropProvided.innerRef} mih={'35px'}>
              <InnerStepListMemo steps={steps} />
              {steps.length === 0 && !dropSnapshot.isDraggingOver ? (
                <Flex
                  align={'center'}
                  justify={'center'}
                  my={'5px'}
                  h={'28px'}
                  style={{
                    border: '1px dashed black',
                    background: theme.other.color.editor.white_10,
                    borderRadius: theme.radius.sm,
                  }}
                >
                  <Tooltip
                    multiline
                    offset={10}
                    label="Drop Step here to add to Step Group"
                  >
                    <Text size={'xs'}>No Steps yet</Text>
                  </Tooltip>
                </Flex>
              ) : (
                dropProvided.placeholder
              )}
            </Box>
          </Box>
        </div>
      )}
    </Droppable>
  );
};
