export enum SelectedItemType {
  'Folder',
  'Project',
}

export interface SelectedFolder {
  type: SelectedItemType.Folder;
  name: string;
  itemId: string;
}

export interface SelectedProject {
  type: SelectedItemType.Project;
  name: string;
  itemId: string;
}

export interface SelectedInstruction {
  name: string;
  itemId: string;
}

export type SelectedItem = SelectedFolder | SelectedProject;

export type ExplorerRoutingParams = {
  folderId?: string;
  projectId?: string;
};

export type ProjectRoutingParams = {
  projectId: string;
};
