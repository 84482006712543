import {
  Badge,
  BadgeVariant,
  MantineSize,
  MantineStyleProp,
  useMantineColorScheme,
} from '@mantine/core';

interface BetaBadgeProps {
  size?: MantineSize;
  style?: MantineStyleProp;
  variant?: BadgeVariant;
}

export const BetaBadge = ({
  size = 'xs',
  style = {},
  variant,
}: BetaBadgeProps) => {
  const { colorScheme } = useMantineColorScheme();
  const defaultVariant = colorScheme === 'dark' ? 'outline' : 'light';
  return (
    <Badge
      style={style}
      variant={variant ?? defaultVariant}
      color={'brandPink'}
      styles={{
        label: {
          paddingTop: '2px',
        },
      }}
      size={size}
    >
      Beta
    </Badge>
  );
};
