import { useAnnotationCreate, useAnnotationImageCreate } from '@assemblio/frontend/data-access';
import { AnnotationController, useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { AnnotationType } from '@assemblio/type/annotation';
import { ActionIcon, Menu, useMantineTheme } from '@mantine/core';
import { IconArrowRight, IconCircle, IconMessagePlus, IconPhoto, IconRectangle } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import { annotationCreators } from './helper/annotation.helper';
import { AnnotationImageDto } from '@assemblio/shared/next-types';
import { notifications } from '@mantine/notifications';
import classes from '../Viewport.module.scss';

export const AnnotationChooser = () => {
  const theme = useMantineTheme();
  const [display, setDisplay] = useState('block');

  const fileInput = useRef<HTMLInputElement>(null);

  const annotationCreateMutation = useAnnotationCreate();
  const imageAnnotationCreateMutation = useAnnotationImageCreate();

  const selectedStep = useUIStore((store) => store.selectedStep);

  const handleExpand = () => {
    display === 'none' ? setDisplay('block') : setDisplay('none');
  };

  const createAnnotation = (type: AnnotationType) => {
    const instructionId = useProjectStore.getState().instructionId;

    const create = annotationCreators[type];
    const annotation = create ? create() : undefined;

    if (annotation && selectedStep) {
      AnnotationController.addAnnotation(annotation);
      AnnotationController.addAnnotationToStep(selectedStep.id, annotation.id);
      annotationCreateMutation.mutate(
        {
          id: instructionId,
          data: {
            stepId: selectedStep.id,
            annotation,
          },
        },
        {
          onError: (_e, values) => {
            notifications.show({
              id: `add-annotation-error`,
              message: 'Annotation could not be added to Step',
              color: 'red',
            });
            AnnotationController.removeAnnotationFromStep(
              values.data.stepId,
              values.data.annotation.id
            );
            AnnotationController.removeAnnotation(values.data.annotation.id);
          },
        }
      );
    }
  };

  const createImageAnnotation = (files: FileList | undefined) => {
    if (files && selectedStep) {
      const instructionId = useProjectStore.getState().instructionId;

      const create = annotationCreators[AnnotationType.IMAGE];
      const annotation = create ? create() : undefined;

      if (annotation) {
        imageAnnotationCreateMutation.mutate(
          {
            id: instructionId,
            data: {
              stepId: selectedStep.id,
              annotation,
              file: files[0],
            },
          },
          {
            onSuccess: (result) => {
              (annotation as AnnotationImageDto).file = {
                id: result.id,
                originalName: result.originalName,
              };
              AnnotationController.addAnnotation(annotation);
              AnnotationController.addAnnotationToStep(
                selectedStep.id,
                annotation.id
              );
            },
          }
        );
      }
    } else {
      console.log('none');
    }
  };

  return (
    <>
      <input
        data-cy="upload-annotation-image"
        type="file"
        name="File"
        accept=".png, .jpeg, .jpg"
        ref={fileInput}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            createImageAnnotation(e.target.files);
          }
          e.target.value = '';
        }}
        style={{ display: 'none' }}
      />
      <Menu classNames={{ item: classes.colorPicker__item }}>
        <Menu.Target>
          <ActionIcon
            color={theme.other.color.brand.yellow}
            size="lg"
            style={{
              pointerEvents: 'auto',
            }}
            variant="filled"
            onClick={handleExpand}
          >
            <IconMessagePlus />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconPhoto />}
            onClick={() => fileInput?.current?.click()}
          >
            Image
          </Menu.Item>
          <Menu.Item
            leftSection={<IconArrowRight />}
            onClick={() => createAnnotation(AnnotationType.ARROW)}
          >
            Arrow
          </Menu.Item>
          <Menu.Item
            leftSection={<IconRectangle />}
            onClick={() => createAnnotation(AnnotationType.RECT)}
          >
            Rectangle
          </Menu.Item>
          <Menu.Item
            leftSection={<IconCircle />}
            onClick={() => createAnnotation(AnnotationType.ELLIPSE)}
          >
            Ellipse
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
