import { Box, Text, List, rem } from '@mantine/core';
import { IconInfoSquareRounded } from '@tabler/icons-react';

export const InstructionVariantCreationInfo = () => {
  return (
    <Box>
      <Text c={'dimmed'} size={'xs'}>
        When this product is linked to a selected base product, all assembly
        steps will be synchronized.
        <br />
        This process is suitable for variants and changes of a base product.
      </Text>
      <List
        size={'xs'}
        pl={10}
        pt={10}
        pb={10}
        icon={
          <IconInfoSquareRounded style={{ width: rem(24), height: rem(24) }} />
        }
      >
        <List.Item>
          You can only link products within the <strong>same</strong> project
          folder.
        </List.Item>
      </List>

      <Text c={'dimmed'} size={'xs'}>
        If you have further questions, please contact our support.{' '}
      </Text>
    </Box>
  );
};
