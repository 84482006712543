import { useEffect } from 'react';
import { EventController, useEventStore } from '@assemblio/frontend/stores';
import { useQueryClient } from '@tanstack/react-query';

/**
 * Hook that subscribes to all relevant Websocket events for a product.
 */
export const useProductWebsocketListeners = (instructionId: string) => {
  const queryClient = useQueryClient();

  const socket = useEventStore((state) => state.socket);

  useEffect(() => {
    socket.emit('subscribe-product', instructionId);
    return () => {
      socket.emit('unsubscribe-product', instructionId);
    };
  }, [instructionId]);

  useEffect(() => {
    socket.on('product-job-update', (event) => {
      if (typeof event.progress === 'undefined') {
        queryClient.invalidateQueries(['artefacts', instructionId]);
        queryClient.invalidateQueries(['jobs', instructionId]);
      } else {
        EventController.setJobProgress({
          type: 'status',
          jobType: 'project initialization job',
          jobId: event.jobId,
          payload: {
            type: 'progress',
            fraction: event.progress,
          },
        });
      }
    });
    return () => {
      socket.off('product-job-update');
    };
  }, []);
};
