import { useFolderDelete } from '@assemblio/frontend/data-access';
import { ProjectFolder } from '../../types/project-structure.types';
import {
  ActionIcon,
  Group,
  Menu,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { isAxiosError } from 'axios';
import { CardMenuItem } from '../ExplorerCard/CardMenuItem';
import classes from '../Cards.module.scss';
import cx from 'clsx';

type FolderCardMenuProps = {
  item: ProjectFolder;
  onListItem?: boolean;
  parentFolderId: string;
  disabled?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onRename?: () => void;
};

export function FolderCardMenu({
  item,
  onListItem = false,
  parentFolderId,
  disabled = false,
  setLoading,
  onRename,
}: FolderCardMenuProps) {
  const theme = useMantineTheme();

  const deleteFolderMutation = useFolderDelete();

  const hasProjects = (item.projectCount ?? 0) > 0;

  const deleteItem = () => {
    if (!item) return;
    setLoading(true);
    deleteFolderMutation.mutate(
      {
        folderId: item.id,
        parentId: parentFolderId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'delete-folder-success',
            message: 'Folder has been deleted',
            color: 'green',
          });
          setLoading(false);
        },
        onError: (error) => {
          setLoading(false);
          let reason;
          if (isAxiosError(error)) {
            reason = error.response?.data.message;
          }
          notifications.show({
            id: 'delete-folder-error',
            title: 'Folder could not be deleted',
            message: reason,
            color: 'red',
          });
        },
      }
    );
  };

  return onListItem ? (
    <Group>
      <Tooltip
        openDelay={500}
        label={
          hasProjects ? 'Only empty folders can be deleted' : 'Delete Folder'
        }
      >
        <ActionIcon
          variant={'filled'}
          disabled={hasProjects}
          onClick={deleteItem}
          color={theme.other.color.brand.pink}
        >
          <IconTrash />
        </ActionIcon>
      </Tooltip>
    </Group>
  ) : (
    <Menu
      disabled={disabled}
      position="bottom-start"
      withinPortal
      trapFocus={false}
      classNames={{
        itemLabel: classes.explorerCard__menuButton__itemLabel,
      }}
      trigger={'hover'}
    >
      <Menu.Target>
        <ActionIcon
          data-cy="icon-dots-vertical"
          color="gray"
          className={cx(classes.explorerCard__menuButton, {
            [classes['explorerCard__menuButton--list']]: onListItem,
          })}
          variant="filled"
        >
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown data-cy="menu-dropdown">
        <CardMenuItem
          Icon={IconEdit}
          label={'Rename'}
          onClick={() => onRename && onRename()}
        />
        <CardMenuItem
          disabled={hasProjects}
          tooltipLabel={
            hasProjects ? 'Only empty folders can be deleted' : undefined
          }
          Icon={IconTrash}
          color={theme.other.color.brand.pink}
          label={'Delete'}
          onClick={deleteItem}
        />
      </Menu.Dropdown>
    </Menu>
  );
}
