import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classes from './Resizable.module.scss';
import cx from 'clsx';
interface CollapseSectionButtonProps {
  right?: boolean;
  onClick: () => unknown;
  collapsed: boolean;
}

export const CollapseSectionButton = ({
  right = false,
  collapsed = false,
  onClick,
}: CollapseSectionButtonProps) => {
  const theme = useMantineTheme();
  const iconColor = theme.colors.gray[4];

  const openIcon = right ? (
    <IconChevronRight color={iconColor} />
  ) : (
    <IconChevronLeft color={iconColor} />
  );

  const collapsedIcon = !right ? (
    <IconChevronRight color={iconColor} />
  ) : (
    <IconChevronLeft color={iconColor} />
  );

  return (
    <ActionIcon
      size="xs"
      variant="filled"
      color="gray"
      className={cx(classes.collapseIcon, {
        [classes['collapseIcon--left']]: !right,
        [classes['collapseIcon--right']]: right,
      })}
      onClick={onClick}
    >
      {collapsed ? collapsedIcon : openIcon}
    </ActionIcon>
  );
};
