import { Button } from '@mantine/core';
import classes from './ItemMoveModal.module.scss';

interface ItemMoveModalButtonsProps {
  currentFolderId: string;
  parentFolderId: string | null;
  onClose: () => unknown;
  onProjectMove: (targetId: string) => unknown;
}

export const ItemMoveModalButtons = ({
  currentFolderId,
  parentFolderId,
  onClose,
  onProjectMove,
}: ItemMoveModalButtonsProps) => {
  return (
    <Button.Group mt={20} className={classes.listFooterButtons}>
      <Button
        variant={'outline'}
        onClick={(e) => {
          if (currentFolderId) {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }
        }}
      >
        Cancel
      </Button>
      <Button
        data-cy="move-here-button"
        disabled={parentFolderId === currentFolderId}
        onClick={(e) => {
          if (currentFolderId) {
            e.preventDefault();
            e.stopPropagation();
            onProjectMove(currentFolderId);
          }
        }}
      >
        Move here
      </Button>
    </Button.Group>
  );
};
