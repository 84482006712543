import { UIController, useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { AssemblyMetaData } from '@assemblio/type/input';
import { ScrollArea, Stack } from '@mantine/core';
import { useMemo, useState } from 'react';
import { HierarchyMenu } from './HierarchyMenu';
import classes from './Hierarchy.module.scss';
import { HierarchyTree } from './HierarchyTree';
import { SearchBar } from '@assemblio/frontend/components';
import { HierarchyFilters } from '../types/hierarchy.types';

const findRoot = (assemblyData: AssemblyMetaData): number => {
  if (assemblyData.assemblies.length === 1) {
    return 0;
  }

  const keys = assemblyData.assemblies
    .map((assembly) => assembly.assemblies)
    .reduce((previous, current) => previous.concat(current), []);

  for (let i = 0; i < assemblyData.assemblies.length; i++) {
    if (!keys.includes(i)) {
      return i;
    }
  }
  return -1;
};

export const Hierarchy = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filter = useUIStore((state) => state.hierarchyFilter);
  const view = useUIStore((state) => state.view);

  const assemblyMetaData: AssemblyMetaData = useProjectStore((state) => state.input);

  const root = useMemo(() => findRoot(assemblyMetaData), [assemblyMetaData]);
  const rootAssembly = useMemo(() => assemblyMetaData.assemblies.at(root), [root, assemblyMetaData]);

  return (
    <Stack
      data-cy="hierarchy-tab"
      align={'stretch'}
      style={{
        height: '100%',
        width: '100%',
        userSelect: 'none',
        overflow: 'hidden',
      }}
    >
      {view === 'editor' && (
        <div className={classes.header}>
          <HierarchyMenu
            showDisassembled={filter.showDisassembled}
            showExcluded={filter.showExcluded}
            onChange={(filter: HierarchyFilters) =>
              UIController.setFilter(filter.showExcluded, filter.showDisassembled)
            }
          />
          <div style={{ flexGrow: 1 }}>
            <SearchBar searchTerm={searchTerm} onSearchTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)} />
          </div>
        </div>
      )}

      <ScrollArea
        type="hover"
        mt={view === 'editor' ? 0 : 10}
        className={classes.main}
        classNames={{
          scrollbar: classes.scrollbar,
          thumb: classes.scrollbarThumb,
        }}
        offsetScrollbars
        px="xs"
        pr={0}
      >
        {rootAssembly && (
          <HierarchyTree
            assemblyMetaData={assemblyMetaData}
            rootAssembly={rootAssembly}
            filter={{ ...filter, searchTerm }}
          />
        )}
      </ScrollArea>
      {/* Disabled until every undo redo action is working correctly */}
      {/* {view === 'editor' && (
        <div className={classes.bottomPanel}>
          <UndoRedoButtons />
        </div>
      )} */}
    </Stack>
  );
};
