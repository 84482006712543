import { ModelController } from '@assemblio/frontend/stores';
import { Text, TextInput, useMantineTheme } from '@mantine/core';
import { useFocusTrap, useMergedRef } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from './TreeItem.module.scss';
import { usePartGroupRename, usePartRename } from '@assemblio/frontend/data-access';

export interface ToggableTextInputProps {
  elementId: string;
  isLeaf: boolean;
  gltfIndex: number;
  name: string;
  visible: boolean;
  excluded: boolean;
}

const ToggableTextInput = ({ isLeaf, elementId, gltfIndex, name, visible, excluded }: ToggableTextInputProps) => {
  const theme = useMantineTheme();
  const location = useLocation();
  const inDisassembler = location.pathname.startsWith('/disassembler');
  const [nameToggle, setNameToggle] = useState(false);

  const partRenameMutation = usePartRename();
  const partGroupRenameMutation = usePartGroupRename();

  const nameRef = useRef<HTMLInputElement>(null);
  const focusTrapRef = useFocusTrap();
  const mergedRef = useMergedRef(nameRef, focusTrapRef);

  useEffect(() => {
    if (nameRef.current && nameToggle) {
      nameRef.current.value = name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameToggle]);

  const handleNameChange = () => {
    if (nameRef && nameRef.current) {
      const newName = nameRef.current.value.trim();
      if (name.length > 0 && name.length <= 255) {
        ModelController.setPartOrAssemblyName(gltfIndex, newName);
        (isLeaf ? partRenameMutation : partGroupRenameMutation).mutate(
          {
            id: elementId,
            data: { name: newName },
          },
          {
            onError: () => {
              ModelController.setPartOrAssemblyName(gltfIndex, name);
            },
          },
        );
      } else {
        notifications.show({
          id: 'update-part-name-error',
          message: 'Part names must have at least 1 and less than 255 characters',
          color: 'red',
        });
      }
    }
    setNameToggle(false);
  };

  return (
    <div
      onDoubleClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setNameToggle(true);
      }}
    >
      {nameToggle && inDisassembler ? (
        <TextInput
          size="xs"
          color={visible ? theme.colors.gray[3] : 'dimmed'}
          className={classes.textInput}
          ref={mergedRef}
          onBlur={() => {
            handleNameChange();
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleNameChange();
              event.preventDefault();
              event.stopPropagation();
            } else if (event.key === 'Escape') {
              setNameToggle(false);
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      ) : (
        <Text
          size="xs"
          lh={1.55}
          className={classes.text}
          data-visible={visible}
          data-excluded={excluded}
          td={excluded ? 'line-through' : 'initial'}
        >
          {name}
        </Text>
      )}
    </div>
  );
};

export default ToggableTextInput;
