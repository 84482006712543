import { useConfirmationDialog } from '@assemblio/frontend/components';
import {
  useDeleteAnnotation,
  useDeleteImageAnnotation,
} from '@assemblio/frontend/data-access';
import {
  AnnotationController,
  StepController,
} from '@assemblio/frontend/stores';
import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

type CommonToolProps = {
  annotationId: string;
  isImage?: boolean;
};

export const CommonTools = ({
  annotationId,
  isImage = false,
}: CommonToolProps) => {
  const theme = useMantineTheme();
  const deleteAnnotationMutation = useDeleteAnnotation();
  const deleteImageAnnotationMutation = useDeleteImageAnnotation();

  const handleRemoveAnnotation = () => {
    const stepIds =
      AnnotationController.disassociateAnnotationFromAllSteps(annotationId);
    AnnotationController.removeAnnotationFromSteps(stepIds, annotationId);
    AnnotationController.removeAnnotation(annotationId);

    if (isImage) {
      deleteImageAnnotationMutation.mutate(annotationId);
    } else {
      deleteAnnotationMutation.mutate(annotationId);
    }
  };

  const { openDialog, RenderConfirmationDialog } = useConfirmationDialog(
    'This annotation is used in several steps, are you sure you want to delete it?',
    handleRemoveAnnotation
  );

  const handleClick = () => {
    const count = AnnotationController.getAnnotationStepCount(annotationId);
    if (count && count > 1) {
      openDialog();
    } else {
      handleRemoveAnnotation();
    }
  };

  return (
    <>
      {RenderConfirmationDialog()}
      <ActionIcon
        size="lg"
        variant="filled"
        color={theme.other.color.brand.yellow}
        onClick={() => handleClick()}
      >
        <IconTrash />
      </ActionIcon>
    </>
  );
};
