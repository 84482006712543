import { Box, Text } from '@mantine/core';
import { IconFoldersOff } from '@tabler/icons-react';
import classes from '../ItemMoveModal.module.scss';

export const EmptyFolderView = () => {
  return (
    <Box className={classes.emptyList}>
      <IconFoldersOff size={'54'} />
      <Text mt={10}>Folder is emtpy</Text>
    </Box>
  );
};
