import { Box, Button, Flex, Skeleton, Text, Tooltip } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import classes from './ProjectExplorer.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ExplorerRoutingParams } from './types';
import { useQueryClient } from '@tanstack/react-query';
import { NextProject, ProjectFolder } from './types/project-structure.types';

interface ExplorerHeaderTitleProps {
  isLoading: boolean;
}

export const ExplorerHeaderTitle = ({ isLoading }: ExplorerHeaderTitleProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { folderId, projectId } = useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;

  const handleGoBack = () => {
    if (folderId) {
      const currentFolder = queryClient.getQueryData(['folder', folderId, null]) as ProjectFolder;
      navigate(`/explorer/${currentFolder.parentFolderId}`);
    } else if (projectId) {
      const currentProject = queryClient.getQueryData(['project', projectId]) as NextProject;
      navigate(`/explorer/${currentProject.folderId}`);
    }
  };

  const getTitle = (): { title: string; parentTitle: string } => {
    if (projectId) {
      const data = queryClient.getQueryData<NextProject>(['project', projectId]);
      if (data)
        return {
          title: data.name,
          parentTitle: 'Back',
        };
    }
    const data = queryClient.getQueryData<ProjectFolder>(folderId ? ['folder', folderId, null] : ['rootFolder']);
    if (data) {
      return {
        title: data.name,
        parentTitle: data.parentFolderName ?? '',
      };
    }
    return {
      title: '',
      parentTitle: '',
    };
  };

  const { title, parentTitle } = getTitle();

  return (
    <Flex ml={'lg'} direction={'column'} className={classes.explorerHeader__title} align={'start'}>
      <Box h={'75%'}>
        {isLoading ? (
          <Skeleton h={65} mb={5} w={200} />
        ) : (
          <>
            <Button
              aria-label="project explorer header title"
              styles={{
                root: {
                  color: 'var(--mantine-color-dimmed)',
                },
              }}
              className={classes.explorerHeader__backButton}
              variant="transparent"
              p={0}
              leftSection={parentTitle ? <IconChevronLeft /> : null}
              onClick={handleGoBack}
            >
              {parentTitle || ''}
            </Button>
            <Tooltip openDelay={500} label={title}>
              <Text mb={5} truncate size={'xl'} fw={700}>
                {title}
              </Text>
            </Tooltip>
          </>
        )}
      </Box>
      {/* <ExplorerSortingSelect /> */}
    </Flex>
  );
};
