import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useUserProfile = () => {
  const [userProfileOpened, setUserProfileOpened] = useState(false);

  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const closeUserProfile = () => {
    setUserProfileOpened(false);
  };

  const openUserProfile = () => {
    setUserProfileOpened(true);
  };

  useEffect(() => {
    if (searchParams.has('profile')) {
      openUserProfile();
    } else {
      closeUserProfile();
    }
  }, [search]);

  return userProfileOpened;
};
