import { Box, Divider, Skeleton } from '@mantine/core';

export const FolderListSkeleton = () => {
  return (
    <Box>
      <Skeleton h={28} w={'50%'} />
      <Divider my={10} />
      <Skeleton h={25} w={'90%'} mb={5} ml={'5%'} />
      <Skeleton h={25} w={'90%'} mb={5} ml={'5%'} />
      <Skeleton h={25} w={'90%'} mb={5} ml={'5%'} />
    </Box>
  );
};
