import { ActionIcon, ColorPicker, Popover, useMantineTheme } from '@mantine/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BaseEditor, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { IconPaint } from '@tabler/icons-react';
import { ASSEMBLIO_PART_COLORS } from '@assemblio/frontend/types';

interface Props {
  editor: (BaseEditor & ReactEditor) | null;
  color: string;
}

export const TextColorPicker = ({ editor, color }: Props) => {
  const theme = useMantineTheme();
  const [currentColor, setCurrentColor] = useState(color);

  const getMarkState = (editor: Editor): string | undefined => {
    const marks = Editor.marks(editor);
    return marks ? _.get(marks, 'color') : undefined;
  };

  const setColor = (color: string) => {
    if (editor) {
      const state = getMarkState(editor);
      if (state) {
        Editor.removeMark(editor, 'color');
      }
      Editor.addMark(editor, 'color', color);
    }
  };

  useEffect(() => {
    if (editor) {
      const previousOnChange = editor.onChange;
      editor.onChange = () => {
        const state = getMarkState(editor);
        if (state) {
          setCurrentColor(state);
        }
        previousOnChange && previousOnChange();
      };
      return previousOnChange;
    }
    return _.noop;
  }, [editor]);

  return editor ? (
    <Popover>
      <Popover.Target>
        <ActionIcon size="lg" variant="filled" color={theme.other.color.brand.yellow}>
          <IconPaint />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <ColorPicker
          onMouseDown={(e) => e.preventDefault()}
          format="hex"
          onChange={setColor}
          value={currentColor}
          swatchesPerRow={6}
          swatches={ASSEMBLIO_PART_COLORS.map((color) => color.code.hex)}
        />
      </Popover.Dropdown>
    </Popover>
  ) : null;
};
