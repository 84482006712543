import { useUIStore } from '@assemblio/frontend/stores';
import { AddFolderButton } from './Dialogs/AddFolderButton';
import { ProjectCreateButton } from './Dialogs/ProjectCreateButton';
import { ExplorerSearchBar } from './ExplorerSearchBar';
import { SelectedItem, SelectedItemType } from './types/project-explorer.types';
import { ProjectFolder } from './types/project-structure.types';

interface ProjectExplorerHeaderProps {
  currentFolder: ProjectFolder;
  handleFolderNavigation: (folderId?: string) => unknown;
  onItemSelection: (itemId: string) => unknown;
}

export const ProjectExplorerHeaderItems = ({
  currentFolder,
  handleFolderNavigation,
  onItemSelection,
}: ProjectExplorerHeaderProps) => {
  const view = useUIStore((state) => state.view);

  const handleSetSelectedItem = (selectedItem: SelectedItem, projectFolderId?: string) => {
    if (selectedItem.type === SelectedItemType.Folder) {
      handleFolderNavigation(selectedItem.itemId);
    }
    if (selectedItem.type === SelectedItemType.Project) {
      if (projectFolderId && currentFolder.id !== projectFolderId) {
        handleFolderNavigation(projectFolderId);
      }
      onItemSelection(selectedItem.itemId);
    }
  };

  const showAddFolderButton = currentFolder.folderDepth !== undefined && currentFolder.folderDepth < 2;

  return (
    <>
      <ExplorerSearchBar onItemSelection={handleSetSelectedItem} />
      {view === 'editor' && (
        <>
          {showAddFolderButton && <AddFolderButton currentFolder={currentFolder} />}
          <ProjectCreateButton />
        </>
      )}
    </>
  );
};
