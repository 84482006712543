import { MantineTransition, Text, Transition } from '@mantine/core';

import { useEffect, useState } from 'react';
interface TextTransitionProps {
  from: string;
  to: string;
  on: boolean;
  delay?: number;
  transition?: MantineTransition;
}

const scaleXFromCenter = {
  in: { opacity: 1, transform: 'scaleX(1)' },
  out: { opacity: 0, transform: 'scaleX(0)' },
  common: { transformOrigin: 'center' },
  transitionProperty: 'transform, opacity',
};

export const TextTransition = ({
  from,
  to,
  on,
  delay = 0,
  transition = scaleXFromCenter,
}: TextTransitionProps) => {
  const [switchText, setSwitchText] = useState<boolean | null>(null);
  const [showTo, setShowTo] = useState(false);

  const handleSwitchText = async () => {
    await wait(delay).then(() => {
      setSwitchText(false);
    });
  };
  useEffect(() => {
    //prevents animation on first render
    if (switchText === null) {
      setSwitchText(false);
      return;
    }
    setSwitchText(true);
    setShowTo(on);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on]);

  return (
    <>
      <Transition
        mounted={!showTo && !switchText}
        onExited={handleSwitchText}
        transition={transition}
      >
        {(styles) => (
          <Text fw={600} size={'sm'} style={styles}>
            {from}
          </Text>
        )}
      </Transition>
      <Transition
        mounted={showTo && !switchText}
        onExited={handleSwitchText}
        transition={transition}
      >
        {(styles) => (
          <Text fw={600} size={'sm'} style={styles}>
            {to}
          </Text>
        )}
      </Transition>
    </>
  );
};

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
