import { Menu, Tooltip, useMantineTheme } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';
import { ComponentType } from 'react';
import classes from './ExplorerCard.module.scss';

interface CardMenuItemProps {
  label: string;
  Icon: ComponentType<IconProps>;
  rightSection?: React.ReactNode;
  iconSize?: number;
  color?: string;
  disabled?: boolean;
  tooltipLabel?: string;
  onClick?: () => unknown;
}

const MENU_ICON_SIZE = 20;

export const CardMenuItem = ({
  label,
  Icon,
  rightSection,
  iconSize = MENU_ICON_SIZE,
  color,
  disabled = false,
  tooltipLabel,
  onClick = () => null,
}: CardMenuItemProps) => {
  const theme = useMantineTheme();
  return (
    <Tooltip disabled={typeof tooltipLabel === 'undefined'} openDelay={500} label={tooltipLabel}>
      <Menu.Item
        className={classes.explorerCard__menuItem}
        leftSection={<Icon size={iconSize} color={color ?? theme.other.color.brand.yellow} />}
        disabled={disabled}
        rightSection={rightSection}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }}
      >
        {label}
      </Menu.Item>
    </Tooltip>
  );
};
