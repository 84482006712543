import { AvailableSyncProfileDto, SyncProfileData, SyncProfileRelationDto } from '@assemblio/shared/next-types';
import { axiosHandler, axiosHandlerWithResponse } from '../../utils/axios-utils';
import {
  CreateSyncProfile,
  InstructionSyncProfile,
  InstructionSyncResolve,
  SyncInstruction,
} from '../types/instruction.request.types';

export const fetchComparableInstructions = async (
  instructionId: string | undefined
): Promise<AvailableSyncProfileDto[]> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Instruction Id'))
    : axiosHandler('get', `instructions/${instructionId}/sync-profile`);
};

export const fetchSyncProfiles = async (instructionId: string | undefined): Promise<SyncProfileRelationDto[]> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Instruction Id'))
    : axiosHandler('get', `instructions/${instructionId}/sync-profiles`);
};

export const createInstructionSyncProfile = async (data: CreateSyncProfile): Promise<void> => {
  return axiosHandler('post', `instructions/${data.from}/sync-profile/${data.to}`);
};

export const syncInstructionWithSource = async (data: SyncInstruction): Promise<void> => {
  return axiosHandler('post', `instructions/${data.targetId}/sync-from/${data.sourceId}`, { data: data.config });
};

export const fetchInstructionSyncProfile = async (data: InstructionSyncProfile): Promise<SyncProfileData> => {
  return axiosHandler('get', `instructions/${data.sourceId}/sync-profile/${data.targetId}/data`);
};

export const fetchInstructionSyncReport = async (data: InstructionSyncProfile): Promise<Blob | null> => {
  const response = await axiosHandlerWithResponse(
    'get',
    `instructions/${data.sourceId}/sync-profile/${data.targetId}/report`,
    { responseType: 'blob' }
  );

  if (response.status === 204) {
    return null;
  }

  return response.data;
};

export const resolveInstructionSync = async (
  data: InstructionSyncResolve
  //TODO: use correct DTO
): Promise<any> => {
  return axiosHandler('post', `instructions/${data.targetId}/sync-profile/${data.sourceId}/resolve`, {
    data: data.resolve,
  });
};

export const deleteInstructionSyncProfile = async (data: InstructionSyncProfile): Promise<void> => {
  return axiosHandler('delete', `instructions/${data.sourceId}/sync-profile/${data.targetId}`);
};
