import { Group, Space, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';
import classes from './Annotations.module.scss';
export interface Props {
  label: string;
}

export const ToolbarGroup = (props: PropsWithChildren<Props>) => {
  return (
    <div className={classes.toolbar}>
      <Group gap="sm" justify="center" style={{ pointerEvents: 'auto' }}>
        {props.children}
      </Group>
      <Space h="xs" />
      <Text
        size="xs"
        style={{
          pointerEvents: 'none',
        }}
        ta="center"
      >
        {props.label}
      </Text>
    </div>
  );
};
