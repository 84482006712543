import { useLocalStorage } from '@mantine/hooks';

export const useExplorerState = () => {
  const [isListView, setListView] = useLocalStorage({
    key: 'assemblio-explorer-list-view',
    defaultValue: false,
  });

  const toggleListView = () => {
    setListView((current) => !current);
  };

  return {
    isListView,
    toggleListView,
  };
};
