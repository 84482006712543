import { Step as StepDef } from '@assemblio/shared/next-types';
import { DraggableProvided } from '@hello-pangea/dnd';
import { ActionIcon, Collapse, Group, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconGripVertical,
  IconPackage,
  IconSquareArrowUp,
  IconSquareLetterA,
} from '@tabler/icons-react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStepDisplayState } from './Hooks/StepDisplayState';
import { useStepModification } from './Hooks/useStepModification';
import classes from './Styles/Step.module.scss';
import { CollapsibleSection } from './UI/CollapsibleSection';
import Path from './UI/Path';
import { StepMenu } from './UI/StepMenu';
import { StepSettings } from './UI/StepSettings';
import ToggableTextInput from './UI/ToggableTextInput';
import cx from 'clsx';
import { StepTextDisplay } from './UI/StepTextDisplay';

export interface StepProps {
  step: StepDef;
  onClick: (step: StepDef) => void;
  onRemoveStep: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onResetCamera: () => void;
  onSetCamera: () => void;
  onSetCameraPositionToStep: () => void;
  onCopyCameraSettings: () => void;
  onPasteCameraSettings: () => void;
  provided: DraggableProvided;
  isDragging: boolean;
}

type Collapsibles = {
  stepOpen: boolean;
  pathOpen: boolean;
};

const Step = ({
  step,
  onClick,
  onRemoveStep,
  onSetCamera,
  onResetCamera,
  onSetCameraPositionToStep,
  provided,
  isDragging,
  onCopyCameraSettings,
  onPasteCameraSettings,
}: StepProps) => {
  const scrollTarget = useRef<HTMLDivElement>(null);
  const theme = useMantineTheme();
  const [collapse, setCollapse] = useState<Collapsibles>({
    stepOpen: false,
    pathOpen: false,
  });

  const isAlignmentStep = step.type === 'alignment';
  const { isInteractable, selected, animated, highlighted, annotationHighlight } = useStepDisplayState(step);

  const iconColor = selected ? 'white' : theme.colors.gray[6];

  const { handleNameChange } = useStepModification();

  useEffect(() => {
    if (scrollTarget.current && (animated || (!animated && selected))) {
      scrollTarget.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [animated, selected, scrollTarget]);

  const handleExpand = useCallback(
    (target: keyof Collapsibles) => {
      setCollapse({ ...collapse, [target]: !collapse[target] });
    },
    [collapse]
  );

  return (
    <div
      className={cx(classes.item, {
        [classes.itemDragging]: isDragging,
        [classes['item--selected']]: selected,
        [classes['item--animated']]: animated,
        [classes['item--disabled']]: !isInteractable,
      })}
      ref={provided.innerRef}
      {...provided.draggableProps}
      data-cy="individual-sequence-row"
    >
      <div onClick={() => onClick(step)} ref={scrollTarget}>
        <div className={classes.header} data-cy="individual-sequence">
          <div style={{ flex: '0 1 auto' }}>
            <ActionIcon
              data-cy="sequencer-step-dropdown"
              size="sm"
              variant="transparent"
              onClick={() => handleExpand('stepOpen')}
            >
              {collapse.stepOpen ? (
                <IconChevronDown size={16} color={iconColor} />
              ) : (
                <IconChevronRight size={16} color={iconColor} />
              )}
            </ActionIcon>
          </div>
          <div style={{ flex: '1 1 auto', minWidth: '0' }}>
            <ToggableTextInput
              selected={selected}
              id={step.id}
              name={step.name}
              disabled={!isInteractable}
              onNameChange={handleNameChange}
            />
          </div>
          <div style={{ flex: '0 1 auto' }}>
            <Group gap={4} className={classes.headerRight}>
              {highlighted && (
                <Tooltip label="Step includes selected part">
                  <ThemeIcon size="sm" className={classes.partIndicator}>
                    <IconPackage color={iconColor} />
                  </ThemeIcon>
                </Tooltip>
              )}
              {step.playWithAbove && <IconSquareArrowUp size={18} color={iconColor} />}
              {isAlignmentStep && (
                <Tooltip label="Is Alignment Step">
                  <IconSquareLetterA size={18} color={iconColor} />
                </Tooltip>
              )}
              <StepMenu
                onRemoveStep={onRemoveStep}
                onResetCamera={onResetCamera}
                onSetCamera={onSetCamera}
                onSetCameraPositionToStep={onSetCameraPositionToStep}
                onCopyCameraSettings={onCopyCameraSettings}
                onPasteCameraSettings={onPasteCameraSettings}
                isStepSelected={selected}
                disabled={!isInteractable}
              />
              <div {...provided.dragHandleProps} className={classes.dragHandle} data-cy="grip-vertical-icon">
                <IconGripVertical color={iconColor} size={18} />
              </div>
            </Group>
          </div>
        </div>

        <Collapse in={collapse.stepOpen}>
          <StepSettings
            stepId={step.id}
            selected={selected}
            stepType={step.type}
            mtm={step.mtm}
            animationSpeed={step.animationSpeed}
            playWithAbove={step.playWithAbove}
            isInteractable={isInteractable}
            isAlignmentStep={isAlignmentStep}
          />
          <StepTextDisplay step={step} selected={selected} />
          <CollapsibleSection
            title={'Animation Path'}
            collapsed={collapse.pathOpen}
            selected={selected}
            data-selected={selected}
            setCollapsed={() => handleExpand('pathOpen')}
          >
            <Path stepId={step.id} stepSelected={selected} stepData={step.data} disabled={!isInteractable} />
          </CollapsibleSection>
        </Collapse>
      </div>
    </div>
  );
};

export default memo(Step);
