import { SyncProfileState } from '@assemblio/shared/next-types';
import { Group, Text, Box } from '@mantine/core';
import { IconRefresh, IconRefreshAlert } from '@tabler/icons-react';

interface InstructionSyncProfileVisualizationProps {
  sourceName: string;
  targetName: string;
  state?: SyncProfileState;
}

export const InstructionSyncProfileVisualization = ({
  sourceName,
  targetName,
  state = SyncProfileState.DONE,
}: InstructionSyncProfileVisualizationProps) => {
  return (
    <Group gap={0} w={'100%'} px={'md'}>
      <Text
        ta={'end'}
        truncate
        lineClamp={1}
        style={{ display: 'inline-block' }}
        w={'40%'}
      >
        {sourceName}
        <Text c={'dimmed'} size="xs">
          (Base)
        </Text>
      </Text>
      <Box px={'sm'}>
        {state === SyncProfileState.UNRESOLVED ? (
          <IconRefreshAlert color="orange" />
        ) : (
          <IconRefresh />
        )}
      </Box>
      <Text
        ta={'start'}
        truncate
        lineClamp={1}
        style={{ display: 'inline-block' }}
        w={'40%'}
      >
        {targetName}
        <Text c={'dimmed'} size="xs">
          (Variant)
        </Text>
      </Text>
    </Group>
  );
};
