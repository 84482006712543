import {
  SyncProfilePair,
  SyncProfilePairs,
} from '@assemblio/shared/next-types';
import {
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  rem,
  Text,
  Radio,
  Tooltip,
  Group,
  Checkbox,
} from '@mantine/core';
import produce from 'immer';
import { useEffect, useState } from 'react';
import classes from '../InstructionVariantSyncModal.module.scss';
interface UpdatedSyncPair {
  pair: SyncProfilePair;
  state: 'Keep' | 'Replace' | 'Unresolved';
}

interface SyncProfilePairEditTableProps {
  pairs: SyncProfilePairs;
  onChange: (updatedPairs: SyncProfilePairs['resolved']) => void;
  label?: string;
}

export const SyncProfilePairEditTable = ({
  pairs,
  onChange,
  label,
}: SyncProfilePairEditTableProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [updatedPairs, setUpdatedPairs] = useState<UpdatedSyncPair[]>(
    transformPairs(pairs)
  );

  const movePair = (index: number, newState: UpdatedSyncPair['state']) => {
    setUpdatedPairs(
      produce<UpdatedSyncPair[]>((state) => {
        state[index].state = newState;
        if (selectedRows.length > 0) {
          selectedRows.forEach((index) => {
            state[index].state = newState;
          });
        }
        return state;
      })
    );
  };

  useEffect(() => {
    onChange(
      updatedPairs.reduce(
        (prev, cur) => {
          switch (cur.state) {
            case 'Keep':
              prev.keep.push(cur.pair);
              break;

            case 'Replace':
              prev.replace.push(cur.pair);
              break;

            default:
              break;
          }
          return prev;
        },
        { keep: [], replace: [] } as SyncProfilePairs['resolved']
      )
    );
  }, [updatedPairs]);

  useEffect(() => {
    setUpdatedPairs(transformPairs(pairs));
  }, [pairs]);

  const rows = updatedPairs.map((updatedPair, index) => {
    const pair = updatedPair.pair;
    const state = updatedPair.state;
    return (
      <Table.Tr key={`pair-${pair.source.id}`}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>
          <Checkbox
            aria-label="Select row"
            checked={selectedRows.includes(index)}
            onChange={(event) =>
              setSelectedRows(
                event.currentTarget.checked
                  ? [...selectedRows, index]
                  : selectedRows.filter((position) => position !== index)
              )
            }
          />
        </Table.Td>
        <Table.Td maw={'9vw'}>
          <Tooltip openDelay={250} label={pair.source.name}>
            <Text
              className={classes.syncProfileTable__table__nameColumn}
              lineClamp={1}
              truncate
              fw={state === 'Replace' ? 700 : 400}
            >
              {pair.source.name}
            </Text>
          </Tooltip>
        </Table.Td>
        <Table.Td maw={'9vw'}>
          <Tooltip openDelay={250} label={pair.target.name}>
            <Text
              className={classes.syncProfileTable__table__nameColumn}
              lineClamp={1}
              truncate
              fw={state === 'Keep' ? 700 : 400}
            >
              {pair.target.name}
            </Text>
          </Tooltip>
        </Table.Td>
        <Table.Td>
          <Radio.Group
            className={classes.syncProfileTable__radioGroup}
            onChange={(state) =>
              movePair(index, state as UpdatedSyncPair['state'])
            }
            value={updatedPair.state}
          >
            <Tooltip
              label="Will replace the Part in Variant with Part in Base"
              refProp="rootRef"
              openDelay={250}
            >
              <Radio
                className={classes.syncProfileTable__radioGroup__radio}
                value={'Replace'}
                label="Replace"
              />
            </Tooltip>
            <Tooltip
              label="Will keep the Part in Variant and ignore the Part in Base"
              refProp="rootRef"
              openDelay={250}
            >
              <Radio
                className={classes.syncProfileTable__radioGroup__radio}
                value={'Keep'}
                label="Ignore"
              />
            </Tooltip>
            <Radio
              className={classes.syncProfileTable__radioGroup__radio}
              value={'Unresolved'}
              label="Undecided"
            />
          </Radio.Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return rows.length > 0 ? (
    <Stack>
      {label && <Text size="sm">{label}</Text>}
      <ScrollArea.Autosize mah={'30vh'} w={'100%'}>
        <Table w={'95%'} mx={'2.5%'} stickyHeader stickyHeaderOffset={0}>
          <Table.Thead
            style={{
              zIndex: 10,
            }}
          >
            <Table.Tr>
              <Table.Th style={{ width: rem(2) }} />
              <Table.Th>
                <Checkbox
                  aria-label="Select all rows"
                  checked={selectedRows.length === updatedPairs.length}
                  onChange={() =>
                    setSelectedRows(
                      selectedRows.length === updatedPairs.length
                        ? []
                        : updatedPairs.map((_, index) => index)
                    )
                  }
                />
              </Table.Th>
              <Table.Th>Part in Base</Table.Th>
              <Table.Th>Part in Variant</Table.Th>
              <Table.Th style={{ width: rem(260) }}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollArea.Autosize>
    </Stack>
  ) : (
    <Group align="center" justify="center">
      <Text c={'dimmed'} size="sm">
        No parts have been recognized as similar yet.
      </Text>
    </Group>
  );
};

export const SyncProfilePairEditTableSkeleton = () => {
  return (
    <Stack>
      <ScrollArea.Autosize mah={'30vh'}>
        <Table w={'95%'} mx={'2.5%'} stickyHeader stickyHeaderOffset={0}>
          <Table.Thead
            style={{
              zIndex: 10,
            }}
          >
            <Table.Tr>
              <Table.Th style={{ width: rem(2) }}></Table.Th>
              <Table.Th>Base Part</Table.Th>
              <Table.Th>Variant Part</Table.Th>
              <Table.Th style={{ width: rem(220) }}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>
                <Skeleton w={'100%'} h={'30px'} />
              </Table.Td>
              <Table.Td>
                <Skeleton w={'100%'} h={'30px'} />
              </Table.Td>

              <Table.Td>
                <Skeleton w={'100%'} h={'30px'} />
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </ScrollArea.Autosize>
    </Stack>
  );
};

const transformPairs = (pairs: SyncProfilePairs): UpdatedSyncPair[] => {
  const toKeep: UpdatedSyncPair[] = pairs.resolved.keep.map((pair) => {
    return { pair, state: 'Keep' };
  });
  const toReplace: UpdatedSyncPair[] = pairs.resolved.replace.map((pair) => {
    return { pair, state: 'Replace' };
  });
  const unresolved: UpdatedSyncPair[] = pairs.unresolved.map((pair) => {
    return { pair, state: 'Replace' };
  });

  return [...toKeep, ...toReplace, ...unresolved];
};
