import {
  useInstructionOwnerChange,
  useTenantUsersQuery,
} from '@assemblio/frontend/data-access';
import {
  Box,
  Button,
  Card,
  Loader,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconSearch } from '@tabler/icons-react';
import { useEffect } from 'react';
import classes from './InstructionChangeModal.module.scss';
import cx from 'clsx';
interface OwnerSelectionProps {
  instructionId: string;
  instructionOwnerId: string;
  newOwnerId: string | null;
  setNewOwnerId: React.Dispatch<React.SetStateAction<string | null>>;
  projectId: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OwnerSelection = ({
  instructionId,
  instructionOwnerId,
  newOwnerId,
  setNewOwnerId,
  projectId,
  searchQuery,
  setSearchQuery,
  handleClose,
  setDirty,
}: OwnerSelectionProps) => {
  const theme = useMantineTheme();
  const instructionOwnerMutation = useInstructionOwnerChange();
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);
  const tenantUserQuery = useTenantUsersQuery({
    exclude: 'viewer',
    searchBy: debouncedSearchQuery ?? undefined,
  });
  useEffect(() => {
    if (!tenantUserQuery.isFetching) {
      tenantUserQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  // Filter out current instruction owner
  const users = tenantUserQuery.data?.items
    .map((u) => u)
    .filter((u) => u.id !== instructionOwnerId);

  const updateCreator = () => {
    const newOwner = users?.find((user) => user.id === newOwnerId);
    if (newOwnerId && newOwner) {
      instructionOwnerMutation.mutate(
        {
          instructionId: instructionId,
          newOwnerId: newOwnerId,
          projectId,
        },
        {
          onSuccess: () => {
            notifications.show({
              id: 'update-instruction-owner-success',
              message: 'Instruction Owner has been updated',
              color: 'green',
            });
          },
        }
      );
      setDirty(true);
      handleClose();
    }
  };

  return (
    <>
      <Text size={'sm'}>Change Product Owner</Text>
      <TextInput
        placeholder="Search For Users"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.currentTarget.value)}
        rightSection={
          tenantUserQuery.isFetching ? (
            <Loader size={16} />
          ) : (
            <IconSearch color="grey" size={16} />
          )
        }
        data-cy="search-specific-owner"
      />
      <Box
        p="xs"
        style={{
          border: '1px solid #373A40',
          borderRadius: '4px',
          minHeight: '100px',
        }}
      >
        {users &&
          users.map((ou) => {
            return (
              <Card
                data-cy="owner-selection-card"
                key={`user-select-card-${ou.id}`}
                p={3}
                className={cx(classes.card, {
                  [classes['card--selected']]: ou.id === newOwnerId,
                })}
                onClick={() => setNewOwnerId(ou.id ?? newOwnerId)}
              >
                <Text size={'xs'}>{ou.fullName}</Text>
              </Card>
            );
          })}
      </Box>
      <Button
        size="xs"
        onClick={() => updateCreator()}
        data-cy="assign-new-owner"
      >
        Assign new owner
      </Button>
    </>
  );
};
