export type ColorCode = { hex: string; dec: number };

export type PartColor = {
  name: string;
  code: ColorCode;
};

export const ASSEMBLIO_PART_COLORS: Array<PartColor> = [
  { name: 'pink', code: { hex: '#E64980', dec: 15092096 } },
  { name: 'red', code: { hex: '#FA5252', dec: 16405074 } },
  { name: 'grape', code: { hex: '#BE4BDB', dec: 12471259 } },
  { name: 'indigo', code: { hex: '#4c6ef5', dec: 5009141 } },
  { name: 'blue', code: { hex: '#228be6', dec: 2264038 } },
  { name: 'cyan', code: { hex: '#15aabf', dec: 1419967 } },
  { name: 'teal', code: { hex: '#12b886', dec: 1226886 } },
  { name: 'lime', code: { hex: '#82c91e', dec: 8571166 } },
  { name: 'yellow', code: { hex: '#fab005', dec: 16429061 } },
  { name: 'orange', code: { hex: '#fd7e14', dec: 16612884 } },
];

export const COLOR_REEL: Array<Array<PartColor>> = [
  [
    { name: 'white', code: { hex: '#FFFFFF', dec: 16777215 } },
    { name: 'white-25', code: { hex: '#d9d9d9', dec: 14277081 } },
    { name: 'white-50', code: { hex: '#bfbfbf', dec: 12566463 } },
    { name: 'white-70', code: { hex: '#a6a6a6', dec: 10921638 } },
    { name: 'white-80', code: { hex: '#7f7f7f', dec: 8355711 } },
  ],
  [
    { name: 'black', code: { hex: '#030303', dec: 197379 } },
    { name: 'black-15', code: { hex: '#7f7f7f', dec: 8355711 } },
    { name: 'black-25', code: { hex: '#595959', dec: 5855577 } },
    { name: 'black-50', code: { hex: '#404040', dec: 4210752 } },
    { name: 'black-70', code: { hex: '#262626', dec: 2500134 } },
  ],
  [
    { name: 'lightGray', code: { hex: '#e7e6e6', dec: 15197926 } },
    { name: 'lightGray-25', code: { hex: '#afabab', dec: 11512747 } },
    { name: 'lightGray-50', code: { hex: '#767171', dec: 7762289 } },
    { name: 'lightGray-70', code: { hex: '#3b3838', dec: 3881016 } },
    { name: 'lightGray-80', code: { hex: '#181717', dec: 1578775 } },
  ],
  [
    { name: 'blueGray', code: { hex: '#44546A', dec: 4478058 } },
    { name: 'blueGray-25', code: { hex: '#adb9ca', dec: 11385290 } },
    { name: 'blueGray-50', code: { hex: '#8497b0', dec: 8689584 } },
    { name: 'blueGray-70', code: { hex: '#333f50', dec: 3358544 } },
    { name: 'blueGray-80', code: { hex: '#222a35', dec: 2239029 } },
  ],
  [
    { name: 'blue', code: { hex: '#4472c4', dec: 4485828 } },
    { name: 'blue-25', code: { hex: '#b4c7e7', dec: 11847655 } },
    { name: 'blue-50', code: { hex: '#8faadc', dec: 9415388 } },
    { name: 'blue-70', code: { hex: '#2f5597', dec: 3102103 } },
    { name: 'blue-80', code: { hex: '#203864', dec: 2111588 } },
  ],
  [
    { name: 'orange', code: { hex: '#ed7d31', dec: 15564081 } },
    { name: 'orange-25', code: { hex: '#f8cbad', dec: 16305069 } },
    { name: 'orange-50', code: { hex: '#f4b183', dec: 16036227 } },
    { name: 'orange-70', code: { hex: '#c55a11', dec: 12933649 } },
    { name: 'orange-80', code: { hex: '#843c0c', dec: 8666124 } },
  ],
  [
    { name: 'gray', code: { hex: '#a5a5a5', dec: 10855845 } },
    { name: 'gray-25', code: { hex: '#dbdbdb', dec: 14408667 } },
    { name: 'gray-50', code: { hex: '#c9c9c9', dec: 13224393 } },
    { name: 'gray-70', code: { hex: '#7c7c7c', dec: 8158332 } },
    { name: 'gray-80', code: { hex: '#525252', dec: 5395026 } },
  ],
  [
    { name: 'gold', code: { hex: '#ffc000', dec: 16760832 } },
    { name: 'gold-25', code: { hex: '#ffe699', dec: 16770713 } },
    { name: 'gold-50', code: { hex: '#ffd966', dec: 16767334 } },
    { name: 'gold-70', code: { hex: '#bf9000', dec: 12554240 } },
    { name: 'gold-80', code: { hex: '#7f6000', dec: 8347648 } },
  ],
  [
    { name: 'lightBlue', code: { hex: '#5b9bd5', dec: 6003669 } },
    { name: 'lightBlue-25', code: { hex: '#bdd7ee', dec: 12441582 } },
    { name: 'lightBlue-50', code: { hex: '#9dc3e6', dec: 10339302 } },
    { name: 'lightBlue-70', code: { hex: '#2e75b6', dec: 3044790 } },
    { name: 'lightBlue-80', code: { hex: '#1f4e79', dec: 2051705 } },
  ],
  [
    { name: 'green', code: { hex: '#70ad47', dec: 7384391 } },
    { name: 'green-25', code: { hex: '#c5e0b4', dec: 12968116 } },
    { name: 'green-50', code: { hex: '#a9d18e', dec: 11129230 } },
    { name: 'green-70', code: { hex: '#548235', dec: 5538357 } },
    { name: 'green-80', code: { hex: '#385723', dec: 3692323 } },
  ],
];
