import { useStepDeleteText } from '@assemblio/frontend/data-access';
import { StepController } from '@assemblio/frontend/stores';
import { Step } from '@assemblio/shared/next-types';
import { ActionIcon, Button, Modal, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import classes from '../Styles/StepText.module.scss';
import { StepTextEditor } from './StepTextEditor';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import cx from 'clsx';

interface Props {
  step: Step;
  selected: boolean;
}

export const StepTextDisplay = ({ step, selected }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const deleteStepTextMutation = useStepDeleteText();

  const stepTextExists = step.text !== null && step.text !== undefined;

  const deleteText = () => {
    deleteStepTextMutation.mutate(step.id, {
      onSuccess: () => {
        StepController.setStepText(step.id, null);
        notifications.show({
          id: 'delete-step-text-success',
          message: 'Text has been deleted',
          color: 'green',
        });
      },
      onError: () => {
        notifications.show({
          id: 'save-step-text-error',
          message: 'Text could not be deleted',
          color: 'red',
        });
      },
    });
  };

  return (
    <>
      <Stack pl={3} mb={'md'} gap={'0'}>
        <Text size="xs" data-selected={selected} className={cx(classes.rowLabel, 'global__label')}>
          Text
        </Text>
        {stepTextExists ? (
          <ActionIcon.Group w={'50%'}>
            <Tooltip openDelay={500} label={'Edit Text'}>
              <ActionIcon
                w={'50%'}
                h={'24px'}
                variant={'default'}
                disabled={deleteStepTextMutation.isLoading}
                onClick={open}
                size="xs"
                color="#fff"
              >
                <IconEdit style={{ width: '70%', height: '70%' }} />
              </ActionIcon>
            </Tooltip>
            <ActionIcon
              w={'50%'}
              h={'24px'}
              variant={'default'}
              onClick={deleteText}
              loading={deleteStepTextMutation.isLoading}
              size="xs"
              color="#fff"
            >
              <IconTrash style={{ width: '70%', height: '70%' }} />
            </ActionIcon>
          </ActionIcon.Group>
        ) : (
          <Button w={'50%'} h={'24px'} variant={'default'} pt={'2px'} onClick={open} size="compact-xs" color="#fff">
            Create Step Text
          </Button>
        )}
      </Stack>
      <Modal opened={opened} onClose={close} title="Step Text" centered size={'50vw'}>
        <StepTextEditor step={step} />
      </Modal>
    </>
  );
};
