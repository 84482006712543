import {
  AnnotationController,
  useAnnotationStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Portal } from '@mantine/core';

interface Props {
  annotationId: string;
  toolbar: JSX.Element;
  initialMode: 'editing' | 'display';
  children: JSX.Element;
}

export const SVGAnnotation = ({
  annotationId,
  toolbar,
  children,
}: Props): JSX.Element => {
  const { visible, mode, highlight } = useAnnotationStore((state) => {
    const annotationState = state.annotationMap.get(annotationId);
    return (
      annotationState || {
        visible: true,
        mode: 'editing',
        highlight: false,
      }
    );
  });
  const view = useUIStore.getState().view;
  const isAnimating = useUIStore((state) => state.isAnimating);
  const disabled = view === 'viewer' || isAnimating;

  return (
    <svg
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        float: 'left',
        pointerEvents: 'none',
        filter: highlight ? 'drop-shadow(0 0 4px rgb(194,39,134))' : 'initial',
        zIndex: highlight || mode === 'editing' ? 2 : 'initial',
      }}
      viewBox={'-1 -1 2 2'}
      preserveAspectRatio={'xMidYMid meet'}
      onPointerDown={(e) => mode === 'editing' && e.stopPropagation()}
      onMouseEnter={(e) => {
        e.stopPropagation();
        AnnotationController.setHighlight(annotationId, true);
      }}
      onMouseLeave={() => {
        AnnotationController.setHighlight(annotationId, false);
      }}
    >
      {mode === 'editing' && <Portal target={'#toolbar'}>{toolbar}</Portal>}
      <g
        visibility={visible || highlight ? 'initial' : 'hidden'}
        style={{ pointerEvents: 'visiblePainted' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!disabled) {
            AnnotationController.editAnnotation(annotationId);
          }
        }}
      >
        {children}
      </g>
    </svg>
  );
};

