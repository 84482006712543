import { Box, Loader, Stack, Text } from '@mantine/core';

import { useElementSize } from '@mantine/hooks';
import classes from '../../UserProfile.module.scss';
import { BrandingOptionsDTO } from '@assemblio/shared/dtos';
import cx from 'clsx';
import { PdfContent } from './components/PdfContent';
import { BrandingColorsType } from '../BrandingColors';

interface PdfPreviewProps {
  isLoading: boolean;
  imgSource: string | undefined;
  colors: BrandingColorsType;
  headerInfo: BrandingOptionsDTO | null;
}

export const PdfPreview = ({
  isLoading,
  imgSource,
  colors,
  headerInfo,
}: PdfPreviewProps) => {
  const { ref, width } = useElementSize();
  //550px is reference width for FullHD --> scaling 1
  const scalingFactor = width / 550;
  return (
    <Box h={'100%'} pt={'3%'}>
      <Box
        ref={ref}
        className={cx(classes.pdf_preview, {
          [classes['pdf_preview--loading']]: isLoading,
        })}
        style={{
          transform: `scale(${scalingFactor})`,
        }}
      >
        {isLoading ? (
          <PdfLoader />
        ) : (
          <PdfContent
            scalingFactor={scalingFactor}
            colors={colors}
            imgSource={imgSource}
            headerInfo={headerInfo}
          />
        )}
      </Box>
    </Box>
  );
};

const PdfLoader = () => {
  return (
    <Stack align={'center'} h={'10%'}>
      <Loader />
      <Text c={'dimmed'}>Loading...</Text>
    </Stack>
  );
};
