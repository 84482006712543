export const viewCube = `# Blender 4.0.1
# www.blender.org
mtllib viewcube.mtl
o Cube
v -0.700000 0.000000 -1.000000
v -0.700000 1.000000 0.000000
v -0.000000 0.000000 -1.000000
v -0.700000 1.000000 -0.700000
v 0.000000 1.000000 0.000000
v -1.000000 0.700000 0.000000
v -0.000000 1.000000 -0.700000
v -1.000000 0.700000 -0.700000
v -1.000000 0.000000 -0.700000
v -1.000000 0.000000 0.000000
v -0.000000 0.700000 -1.000000
v -0.700000 0.700000 -1.000000
v 0.700000 0.000000 -1.000000
v 0.700000 1.000000 -0.000000
v 0.700000 1.000000 -0.700000
v 1.000000 0.700000 -0.000000
v 1.000000 0.700000 -0.700000
v 1.000000 0.000000 -0.700000
v 1.000000 0.000000 -0.000000
v 0.700000 0.700000 -1.000000
v -0.700000 0.000000 1.000000
v 0.000000 0.000000 1.000000
v -0.700000 1.000000 0.700000
v 0.000000 1.000000 0.700000
v -1.000000 0.700000 0.700000
v -1.000000 0.000000 0.700000
v 0.000000 0.700000 1.000000
v -0.700000 0.700000 1.000000
v 0.700000 0.000000 1.000000
v 0.700000 1.000000 0.700000
v 1.000000 0.700000 0.700000
v 1.000000 0.000000 0.700000
v 0.700000 0.700000 1.000000
v -0.700000 -1.000000 0.000000
v -0.700000 -1.000000 -0.700000
v 0.000000 -1.000000 0.000000
v -1.000000 -0.700000 0.000000
v -0.000000 -1.000000 -0.700000
v -1.000000 -0.700000 -0.700000
v -0.000000 -0.700000 -1.000000
v -0.700000 -0.700000 -1.000000
v 0.700000 -1.000000 -0.000000
v 0.700000 -1.000000 -0.700000
v 1.000000 -0.700000 -0.000000
v 1.000000 -0.700000 -0.700000
v 0.700000 -0.700000 -1.000000
v -0.700000 -1.000000 0.700000
v 0.000000 -1.000000 0.700000
v -1.000000 -0.700000 0.700000
v 0.000000 -0.700000 1.000000
v -0.700000 -0.700000 1.000000
v 0.700000 -1.000000 0.700000
v 1.000000 -0.700000 0.700000
v 0.700000 -0.700000 1.000000
vn -0.0000 0.7071 -0.7071
vn -0.0000 -0.0000 -1.0000
vn -1.0000 -0.0000 -0.0000
vn -0.0000 1.0000 -0.0000
vn -0.7071 0.7071 -0.0000
vn -0.7071 -0.0000 -0.7071
vn -0.5774 0.5774 -0.5774
vn 1.0000 -0.0000 -0.0000
vn 0.7071 0.7071 -0.0000
vn 0.7071 -0.0000 -0.7071
vn 0.5774 0.5774 -0.5774
vn -0.0000 0.7071 0.7071
vn -0.0000 -0.0000 1.0000
vn -0.7071 -0.0000 0.7071
vn -0.5774 0.5774 0.5774
vn 0.7071 -0.0000 0.7071
vn 0.5774 0.5774 0.5774
vn -0.0000 -0.7071 -0.7071
vn -0.0000 -1.0000 -0.0000
vn -0.7071 -0.7071 -0.0000
vn -0.5774 -0.5774 -0.5774
vn 0.7071 -0.7071 -0.0000
vn 0.5774 -0.5774 -0.5774
vn -0.0000 -0.7071 0.7071
vn -0.5774 -0.5774 0.5774
vn 0.5774 -0.5774 0.5774
vt 0.050047 0.050050
vt 0.322758 0.050050
vt 0.303068 0.500000
vt 0.050047 0.500000
vt 0.949908 0.500000
vt 0.949908 0.949910
vt 0.499998 0.949910
vt 0.500000 0.500000
vt 0.500000 0.500000
vt 0.499998 0.949910
vt 0.050088 0.949910
vt 0.050088 0.500000
vt 0.050088 0.500000
vt 0.500000 0.500000
vt 0.050088 0.949910
vt 0.322758 0.949950
vt 0.050047 0.949950
vt 0.303068 0.500000
vt 0.322759 0.949950
vt 0.050047 0.949950
vt 0.050047 0.500000
vt 0.829359 0.949916
vt 0.050081 0.500000
vt 0.829359 0.050084
vt 0.050088 0.500000
vt 0.050088 0.949910
vt 0.500000 0.500000
vt 0.949908 0.500000
vt 0.499998 0.949910
vt 0.949908 0.500000
vt 0.949908 0.949910
vt 0.050047 0.500000
vt 0.303068 0.500000
vt 0.322758 0.949951
vt 0.050047 0.949951
vt 0.050047 0.500000
vt 0.303068 0.500000
vt 0.322759 0.949951
vt 0.829359 0.050084
vt 0.829359 0.949916
vt 0.050081 0.500000
vt 0.050047 0.050050
vt 0.050088 0.500000
vt 0.499998 0.949910
vt 0.050088 0.949910
vt 0.949908 0.500000
vt 0.949908 0.949910
vt 0.050088 0.050090
vt 0.499998 0.050090
vt 0.303068 0.500000
vt 0.050047 0.949951
vt 0.050081 0.500000
vt 0.829359 0.050084
vt 0.829359 0.949916
vt 0.949908 0.500000
vt 0.050088 0.949910
vt 0.050088 0.500000
vt 0.949908 0.050090
vt 0.322759 0.949950
vt 0.050081 0.050084
vt 0.865957 0.500000
vt 0.050081 0.949916
vt 0.322759 0.050049
vt 0.050047 0.500000
vt 0.050047 0.050050
vt 0.499998 0.050090
vt 0.949908 0.050090
vt 0.050088 0.050090
vt 0.499998 0.050090
vt 0.050090 0.500000
vt 0.050090 0.050090
vt 0.500000 0.050090
vt 0.500000 0.500000
vt 0.050047 0.949951
vt 0.050047 0.050050
vt 0.322759 0.050049
vt 0.865957 0.500000
vt 0.050081 0.949916
vt 0.050088 0.050090
vt 0.499998 0.050090
vt 0.949908 0.050090
vt 0.949910 0.500000
vt 0.949910 0.050090
vt 0.303067 0.500000
vt 0.050047 0.949950
vt 0.050047 0.500000
vt 0.322759 0.050050
vt 0.829359 0.949916
vt 0.050082 0.500000
vt 0.829359 0.050084
vt 0.050047 0.050050
vt 0.322758 0.050050
vt 0.050088 0.050090
vt 0.499998 0.050090
vt 0.949908 0.050090
vt 0.500000 0.949910
vt 0.050090 0.949910
vt 0.050047 0.050050
vt 0.322758 0.050050
vt 0.050082 0.050084
vt 0.865957 0.500000
vt 0.050082 0.949917
vt 0.949908 0.050090
vt 0.050088 0.050090
vt 0.949910 0.949910
vt 0.050047 0.050050
vt 0.322759 0.050050
vt 0.050081 0.949916
vt 0.050082 0.050084
vt 0.865957 0.500000
s 0
usemtl FrontTopRight
f 30/60/17 33/61/17 31/62/17
usemtl Front
f 21/43/13 22/14/13 27/44/13 28/45/13
f 29/55/13 33/31/13 27/44/13 22/14/13
f 21/43/13 51/93/13 50/94/13 22/14/13
f 29/55/13 22/14/13 50/94/13 54/103/13
usemtl FrontTop
f 28/2/12 27/33/12 24/32/12 23/42/12
f 33/34/12 30/35/12 24/32/12 27/33/12
usemtl Top
f 2/13/4 5/14/4 7/7/4 4/15/4
f 14/30/4 15/31/4 7/7/4 5/14/4
f 2/13/4 23/48/4 24/49/4 5/14/4
f 14/30/4 5/14/4 24/49/4 30/58/4
usemtl FrontTopLeft
f 23/52/15 25/53/15 28/54/15
usemtl FrontLeft
f 26/4/14 21/50/14 28/34/14 25/51/14
f 26/4/14 49/98/14 51/99/14 21/50/14
usemtl FrontRight
f 32/3/16 31/59/16 33/17/16 29/21/16
f 32/3/16 29/21/16 54/106/16 53/107/16
usemtl FrontBottom
f 51/91/24 47/92/24 48/84/24 50/86/24
f 54/85/24 50/86/24 48/84/24 52/16/24
usemtl FrontBottomRight
f 52/108/26 53/109/26 54/110/26
usemtl FrontBottomLeft
f 47/100/25 51/101/25 49/102/25
usemtl Bottom
f 34/70/19 35/71/19 38/72/19 36/73/19
f 42/82/19 36/73/19 38/72/19 43/83/19
f 34/70/19 36/73/19 48/96/19 47/97/19
f 42/82/19 52/105/19 48/96/19 36/73/19
usemtl TopRight
f 14/32/9 16/33/9 17/34/9 15/35/9
f 14/32/9 30/42/9 31/2/9 16/33/9
usemtl TopLeft
f 2/3/5 4/16/5 8/17/5 6/4/5
f 2/3/5 6/4/5 25/1/5 23/2/5
usemtl BottomRight
f 42/84/22 43/16/22 45/85/22 44/86/22
f 42/84/22 44/86/22 53/91/22 52/92/22
usemtl BottomLeft
f 34/64/20 37/37/20 39/38/20 35/74/20
f 34/64/20 47/65/20 49/63/20 37/37/20
usemtl Right
f 19/27/8 18/28/8 17/6/8 16/29/8
f 19/27/8 16/29/8 31/56/8 32/57/8
f 19/27/8 44/80/8 45/81/8 18/28/8
f 19/27/8 32/57/8 53/104/8 44/80/8
usemtl Left
f 10/9/3 6/10/3 8/11/3 9/12/3
f 10/9/3 26/46/3 25/47/3 6/10/3
f 10/9/3 9/12/3 39/68/3 37/69/3
f 10/9/3 37/69/3 49/95/3 26/46/3
usemtl BackRight
f 18/36/10 13/37/10 20/38/10 17/35/10
f 18/36/10 45/65/10 46/87/10 13/37/10
usemtl BackLeft
f 9/18/6 8/19/6 12/20/6 1/21/6
f 9/18/6 1/21/6 41/75/6 39/76/6
usemtl Back
f 1/5/2 12/6/2 11/7/2 3/8/2
f 13/25/2 3/8/2 11/7/2 20/26/2
f 1/5/2 3/8/2 40/66/2 41/67/2
f 13/25/2 46/79/2 40/66/2 3/8/2
usemtl BackTop
f 12/1/1 4/2/1 7/3/1 11/4/1
f 20/17/1 11/4/1 7/3/1 15/16/1
usemtl BackBottom
f 41/63/18 40/37/18 38/64/18 35/65/18
f 46/38/18 43/74/18 38/64/18 40/37/18
usemtl BackBottomLeft
f 35/60/21 39/77/21 41/78/21
usemtl BackBottomRight
f 43/88/23 46/89/23 45/90/23
usemtl BackTopLeft
f 4/22/7 12/23/7 8/24/7
usemtl BackTopRight
f 15/39/11 17/40/11 20/41/11
`;
