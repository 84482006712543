import { useNavigate } from 'react-router-dom';
import { Button, Flex, Text } from '@mantine/core';

export const ErrorScreen = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate('/explorer');
  return (
    <Flex
      h={'100%'}
      w={'50%'}
      ml={'25%'}
      direction={'column'}
      align={'center'}
      justify={'center'}
    >
      <Text size={'xl'} ta={'center'} fw={700}>
        No Project found
      </Text>
      <Text size={'md'} c={'dimmed'} ta={'center'}>
        We could not find any resource under the provided URL. Please go back to
        the Explorer to discover available Projects.
      </Text>
      <Button mt={'lg'} size={'md'} onClick={handleBack}>
        Back to Explorer
      </Button>
    </Flex>
  );
};
