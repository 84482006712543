import { ActionIcon, Popover, SegmentedControl, useMantineTheme } from '@mantine/core';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { BaseEditor, Editor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { IconHeading } from '@tabler/icons-react';

interface Props {
  editor: (BaseEditor & ReactEditor) | null;
}

export const HeadingSwitch = ({ editor }: Props) => {
  const theme = useMantineTheme();
  const [selected, setSelected] = useState('paragraph');

  const getActiveHeading = useCallback(() => {
    if (editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => _.get(n, 'type') === 'heading',
      });
      if (match) {
        if (_.get(match[0], 'level') !== undefined) {
          return `h${_.get(match[0], 'level')}`;
        }
      }
    }

    return 'paragraph';
  }, [editor]);

  useEffect(() => {
    if (editor) {
      const previousOnChange = editor.onChange;
      editor.onChange = () => {
        setSelected(getActiveHeading());
        previousOnChange && previousOnChange();
      };
      return previousOnChange;
    }
    return _.noop;
  }, [editor, getActiveHeading]);

  const setHeading = (heading: string) => {
    setSelected(heading);
    const newProperties = {};
    if (heading === 'paragraph') {
      _.set(newProperties, 'type', 'paragraph');
      editor && Transforms.unsetNodes<SlateElement>(editor, 'level');
    } else {
      if (heading.length === 2 && _.isNumber(+heading[1])) {
        _.set(newProperties, 'type', 'heading');
        _.set(newProperties, 'level', +heading[1]);
      }
    }
    editor && Transforms.setNodes<SlateElement>(editor, newProperties);
  };
  return editor ? (
    <Popover>
      <Popover.Target>
        <ActionIcon style={{ cursor: 'pointer' }} variant="filled" color={theme.other.color.brand.yellow} size={'lg'}>
          <IconHeading />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <SegmentedControl
          onChange={setHeading}
          onMouseDown={(e) => e.preventDefault()}
          value={selected}
          data={[
            {
              value: 'paragraph',
              label: 'Paragraph',
            },
            {
              value: 'h1',
              label: 'H1',
            },
            {
              value: 'h2',
              label: 'H2',
            },
            {
              value: 'h3',
              label: 'H3',
            },
          ]}
        />
      </Popover.Dropdown>
    </Popover>
  ) : null;
};
