export const capitalizeString = <T extends string>(s: T) =>
  (s[0].toUpperCase() + s.slice(1)) as Capitalize<typeof s>;

export const capitalizeSentence = <T extends string>(s: T) =>
  s.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

/**
 * Function that returns difference between past date and now in a human readable way.
 * @param date - Past Date
 * @returns Human readable difference in days (e.g. Two days ago)
 */
export const getFormattedDateDifference = (date: Date, capitalize = true) => {
  const now = new Date();
  const pastDate = new Date(date);
  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  const diff = Math.round(
    (pastDate.getTime() - now.getTime()) / (1000 * 3600 * 24)
  );
  const formattedString = formatter.format(diff, 'day');
  return capitalize ? capitalizeString(formattedString) : formattedString;
};

export const getFormatedTimeDifference = (date: Date) => {
  const now = new Date();
  const pastDate = new Date(date);

  const diffInSeconds = Math.round((now.getTime() - pastDate.getTime()) / 1000);
  const diffInMinutes = Math.round(diffInSeconds / 60);
  const diffInHours = Math.round(diffInMinutes / 60);
  const diffInDays = Math.round(diffInHours / 24);

  let diff: number;
  let unit: Intl.RelativeTimeFormatUnit;

  if (diffInDays >= 1) {
    diff = diffInDays;
    unit = 'day';
  } else if (diffInHours >= 1) {
    diff = diffInHours;
    unit = 'hour';
  } else if (diffInMinutes >= 1) {
    diff = diffInMinutes;
    unit = 'minute';
  } else {
    diff = diffInSeconds;
    unit = 'second';
  }

  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  const formattedString = formatter.format(-diff, unit);

  return diffInDays >= 1 ? getFormattedDateString(pastDate) : capitalizeString(formattedString);
};

export const getFormattedDateString = (date: Date) => {
  const dateToFormat = new Date(date);
  const formatter = new Intl.DateTimeFormat('de');
  return formatter.format(dateToFormat.getTime());
};
