import {
  downloadArtefact,
  useArtefactDelete,
} from '@assemblio/frontend/data-access';
import { useUIStore } from '@assemblio/frontend/stores';
 import {
  ActionIcon,
  Badge,
  Box,
  Flex,
  LoadingOverlay,
  MantineSize,
  Menu,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconDotsVertical, IconDownload, IconTrash } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import { useConfirmationDialog } from '../../../Utilities/useConfirmationDialog';
import { ArtefactCardSubtitle } from './ArtefactCardSubtitle';
import { CardMenuItem } from '../ExplorerCard/CardMenuItem';
import cx from 'clsx';
import classes from '../Cards.module.scss';
import { ArtefactDto } from '@assemblio/shared/dtos';

interface ArtefactCardProps {
  artefact: ArtefactDto;
  instructionId: string;
}

export const ArtefactCard = ({
  artefact,
  instructionId,
}: ArtefactCardProps) => {
  const view = useUIStore((state) => state.view);
  const theme = useMantineTheme();

  const deleteArtefactMutation = useArtefactDelete();

  const handleDelete = () => {
    deleteArtefactMutation.mutate({ instructionId, artefactId: artefact.id });
  };

  const { openDialog, RenderConfirmationDialog } = useConfirmationDialog(
    'Are you sure you want to delete this manual?',
    handleDelete
  );

  const handleDownload = () => {
    downloadArtefact(instructionId, artefact.id).then((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, artefact.originalName);
    });
  };

  return (
    <>
      <Flex
        px={'2.5%'}
        h={'70px'}
        mb={10}
        align={'center'}
        className={cx(classes.listCard, classes['listCard--artefact'])}
      >
        <LoadingOverlay
          overlayProps={{ blur: 2, radius: 'var(--mantine-radius-md)' }}
          visible={deleteArtefactMutation.isLoading}
        />
        <Flex
          direction={'column'}
          mb={5}
          h={'100%'}
          w={'60%'}
          justify={'center'}
          gap={'xs'}
          style={{ marginRight: 'auto' }}
        >
          <Tooltip openDelay={500} label={artefact.originalName}>
            <Text w={'90%'} truncate>
              {artefact.originalName}
            </Text>
          </Tooltip>

          <Box h={'1rem'}>
            <ArtefactCardSubtitle artefact={artefact} />
          </Box>
        </Flex>

        <ArtefactCardBadges type={artefact.type} />

        <Menu trigger={'hover'}>
          <Menu.Target>
            <ActionIcon color="gray.0" variant="transparent">
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <CardMenuItem
              Icon={IconDownload}
              label={'Download'}
              onClick={handleDownload}
            />
            {view === 'editor' && (
              <CardMenuItem
                Icon={IconTrash}
                label={'Delete'}
                color={theme.other.color.brand.pink}
                onClick={openDialog}
              />
            )}
          </Menu.Dropdown>
        </Menu>
      </Flex>
      {RenderConfirmationDialog()}
    </>
  );
};

interface ArtefactCardBadgesProps {
  type: string;
  latest?: boolean;
}

const ArtefactCardBadges = ({
  type,
  latest = false,
}: ArtefactCardBadgesProps) => {
  //Using custom breakpoint here to avoid unwanted behavior the default breakpoints would not cover
  const xxlScreen = useMediaQuery(`(min-width: 2000px)`);
  const iconSize: MantineSize = xxlScreen ? 'md' : 'sm';
  return (
    <Flex h={'90%'} justify={'end'} align={'center'} gap={'xs'} mr={'md'}>
      {latest && (
        <Badge size={iconSize} color="green">
          Latest
        </Badge>
      )}
      {type && (
        <Badge variant="light" size={iconSize}>
          {type}
        </Badge>
      )}
    </Flex>
  );
};
