import React, { useState } from 'react';
import { TextInput, Text, Group, ActionIcon } from '@mantine/core';
import classes from './Cards/ExplorerCard/ExplorerCard.module.scss';
import { IconPencil } from '@tabler/icons-react';
import { useUIStore } from '@assemblio/frontend/stores';
import { useExplorerState } from './useExplorerState';
import cx from 'clsx';
import { notifications } from '@mantine/notifications';

interface ToggableItemNameProps {
  name: string;
  handleChange: (newName: string) => void;
  withIcon?: boolean;
  withDoubleClick?: boolean;
}

export const ToggableItemName = ({
  name,
  handleChange,
  withDoubleClick = false,
  withIcon = false,
}: ToggableItemNameProps) => {
  const view = useUIStore((state) => state.view);

  const { isListView } = useExplorerState();

  const [editing, setEditing] = useState(false);

  const captureNewName = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
    setEditing(false);
    const newName = event.currentTarget.value;
    if (newName.length <= 3) {
      notifications.show({
        id: 'folder-rename-error',
        message: 'Folder name must be at least 3 characters long',
      });
      return;
    }
    handleChange(newName);
  };

  const handleEdit = () => {
    if (view !== 'editor') return;
    setEditing(true);
  };

  return editing ? (
    <TextInput
      classNames={{
        input: classes.explorerCard__title__input,
      }}
      variant={'unstyled'}
      autoFocus
      onKeyUpCapture={(e) => {
        if (e.key === 'Enter') {
          captureNewName(e);
        }
      }}
      placeholder={name}
      onBlur={captureNewName}
    />
  ) : (
    <Group gap={'xs'}>
      <Text
        data-cy="folder-title-text"
        onDoubleClick={(e) => {
          e.stopPropagation();
          withDoubleClick && handleEdit();
        }}
        className={cx(classes.explorerCard__title, {
          [classes['explorerCard__title--list']]: isListView,
        })}
        lineClamp={3}
      >
        {name}
      </Text>
      {withIcon && (
        <ActionIcon
          className={classes.explorerCard__title__icon}
          size={'xs'}
          color="gray"
          variant="transparent"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEdit();
          }}
        >
          <IconPencil style={{ width: '100%' }} />
        </ActionIcon>
      )}
    </Group>
  );
};
