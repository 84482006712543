import { Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconInfoSquareRounded } from '@tabler/icons-react';

interface InstructionSyncWarningProps {
  sourceName: string;
  targetName: string;
}

export const InstructionSyncWarning = ({
  sourceName,
  targetName,
}: InstructionSyncWarningProps) => {
  const theme = useMantineTheme();
  return (
    <Stack>
      <Text c={'dimmed'} size={'xs'}>
        This process will transfer the assembly plan from {sourceName} to{' '}
        {targetName} for all identical and similar parts.
      </Text>
      <Stack gap={'xs'}>
        <Group gap={'xs'}>
          <IconInfoSquareRounded
            color={theme.other.color.brand.yellow}
            style={{ width: theme.fontSizes.md, height: theme.fontSizes.md }}
          />
          <Text fw={700} size="sm" c={theme.other.color.brand.yellow}>
            All assembly steps in {targetName} will be overwritten
          </Text>
        </Group>
        <Group gap={'xs'}>
          <IconInfoSquareRounded
            color={theme.other.color.brand.yellow}
            style={{ width: theme.fontSizes.md, height: theme.fontSizes.md }}
          />
          <Text fw={700} size="sm" c={theme.other.color.brand.yellow}>
            This action cannot be undone
          </Text>
        </Group>
      </Stack>
    </Stack>
  );
};
