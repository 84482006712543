import classes from './FolderCard.module.scss';
import { IconFolder, IconFolderCheck } from '@tabler/icons-react';
import { useMantineColorScheme } from '@mantine/core';

// Todo iconName needs typing
interface FolderCardIconProps {
  iconName?: string | null;
  color?: string;
}

export const FolderCardIcon = ({
  iconName = 'folder',
  color,
}: FolderCardIconProps) => {
  const { colorScheme } = useMantineColorScheme();
  const effectiveColor = color
    ? color
    : colorScheme === 'dark'
    ? '#fff'
    : '#000';
  const IconComponent =
    iconName === 'folder-check' ? IconFolderCheck : IconFolder;

  return (
    <IconComponent size={72} color={effectiveColor} className={classes.icon} />
  );
};
