import { useQuery } from '@tanstack/react-query';
import { fetchArtefactCount, fetchArtefactsForInstruction, fetchV1ArtefactsForProduct } from './artefact.requests';

export const useInstructionArtefactsQuery = (instructionId: string) => {
  return useQuery(['artefacts', instructionId], () => fetchArtefactsForInstruction(instructionId), {
    refetchOnWindowFocus: false,
  });
};
export const useV1ProductArtefactsQuery = (instructionId: string | undefined) => {
  return useQuery(['artefacts', instructionId], () => fetchV1ArtefactsForProduct(instructionId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(instructionId),
  });
};

export const useInstructionArtefactsCountQuery = (instructionId: string | undefined) => {
  return useQuery(['artefact-count', instructionId], () => fetchArtefactCount(instructionId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(instructionId),
  });
};
