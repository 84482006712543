import { Vector } from '@assemblio/type/3d';
import { ActionIcon, Flex, Grid, Text, Tooltip } from '@mantine/core';
import { memo } from 'react';
import classes from '../Styles/Segment.module.scss';
import { IconTrash } from '@tabler/icons-react';

interface Props {
  vector: Vector;
  disabled: boolean;
  deletable: boolean;
  onDelete: () => unknown;
}

const VectorDisplay = ({ vector, disabled, deletable, onDelete }: Props) => {
  return (
    <Grid
      columns={7}
      gutter={0}
      h={'100%'}
      w={'100%'}
      styles={{
        inner: {
          height: '100%',
        },
        col: {
          padding: '5px 2px',
        },
      }}
    >
      <Grid.Col span={2}>
        <Tooltip label={vector.x.toPrecision(2)} openDelay={250}>
          <Flex className={classes.vectorBadge}>
            <Text>
              <Text span>x&nbsp;</Text>
              {vector.x.toPrecision(2)}
            </Text>
          </Flex>
        </Tooltip>
      </Grid.Col>

      <Grid.Col span={2}>
        <Tooltip label={vector.y.toPrecision(2)} openDelay={250}>
          <Flex className={classes.vectorBadge}>
            <Text>
              <Text span>y&nbsp;</Text>
              {vector.y.toPrecision(2)}
            </Text>
          </Flex>
        </Tooltip>
      </Grid.Col>
      <Grid.Col span={2}>
        <Tooltip label={vector.z.toPrecision(2)} openDelay={250}>
          <Flex className={classes.vectorBadge}>
            <Text>
              <Text span>z&nbsp;</Text>
              {vector.z.toPrecision(2)}
            </Text>
          </Flex>
        </Tooltip>
      </Grid.Col>
      <Grid.Col span={1}>
        {deletable && (
          <ActionIcon
            size="xs"
            disabled={disabled}
            variant="light"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              onDelete();
            }}
            color="dark"
          >
            <IconTrash size="12" />
          </ActionIcon>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default memo(VectorDisplay);
