import { Button, Group, Modal, Text } from '@mantine/core';
import { BetaBadge } from '../../../Common';

interface InstructionUnresolvedErrorModalProps {
  instructionName: string;
  opened: boolean;
  onClose: () => void;
}

export const InstructionUnresolvedErrorModal = ({
  instructionName,
  opened,
  onClose,
}: InstructionUnresolvedErrorModalProps) => {
  return (
    <Modal
      opened={opened}
      title={<BetaBadge />}
      withCloseButton
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      centered
      withinPortal
    >
      <Text fw={700} mb={'lg'}>
        {instructionName} is unresolved
      </Text>
      <Text size="sm" mb={'xl'}>
        The product has unresolved synchronization issues. The Owner needs to
        resolve these issues before the instruction can be opened.
      </Text>

      <Group justify="end">
        <Button variant={'outline'} onClick={() => onClose()}>
          Close
        </Button>
      </Group>
    </Modal>
  );
};
