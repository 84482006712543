import { Card, Paper, Stack, Text } from '@mantine/core';
import { ProjectCreateButton } from '../Dialogs/ProjectCreateButton';
import { IconFolderOpen } from '@tabler/icons-react';
import classes from '../ProjectExplorer.module.scss';
import { useParams } from 'react-router-dom';
import { ExplorerRoutingParams } from '../types';
import { InstructionCreateButton } from '../Dialogs/InstructionCreateButton';
import { useDebouncedValue } from '@mantine/hooks';
import cx from 'clsx';
import { forwardRef } from 'react';

export const EmptyViewCard = forwardRef<HTMLDivElement>((_, ref) => {
  const { projectId } =
    useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;

  const [inInstruction] = useDebouncedValue(Boolean(projectId), 100);
  return (
    <Paper
      ref={ref}
      data-cy="empty-folder-card"
      radius={'lg'}
      className={cx(
        classes.mainGrid__explorerPanel,
        classes['mainGrid__explorerPanel--empty']
      )}
    >
      <Card className={classes.emptyViewCard}>
        <Text size="xl" fw={700}>
          Nothing here yet
        </Text>
        <Stack align="center">
          <IconFolderOpen size={'8rem'} />
          <Text c={'dimmed'}>
            Start by creating a new {inInstruction ? 'Product' : 'Project'}
          </Text>
        </Stack>
        {inInstruction && projectId ? (
          <InstructionCreateButton
            projectId={projectId}
            responsive={false}
            buttonProps={{ fullWidth: true }}
          />
        ) : (
          <ProjectCreateButton
            buttonProps={{ size: 'lg', fullWidth: true }}
            responsive={false}
          />
        )}
      </Card>
    </Paper>
  );
});
