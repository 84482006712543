import { useInstructionRename } from '@assemblio/frontend/data-access';
import { Button, Group, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { NextInstruction } from '../../types/project-structure.types';

interface NameSelectionProps {
  instruction: NextInstruction;
  instructionName: string;
  form: UseFormReturnType<{
    instructionName: string;
  }>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NameSelection = ({
  instruction,
  instructionName,
  form,
  setDirty,
}: NameSelectionProps) => {
  const renameInstructionMutation = useInstructionRename();

  const updateName = (projectName: string) => {
    const newName = projectName.trim();

    renameInstructionMutation.mutate(
      {
        id: instruction.id,
        data: { name: newName },
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'update-instruction-success',
            message: 'Instruction has been renamed',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'update-instruction-error',
            message: 'Instruction could not be renamed',
            color: 'red',
          });
        },
      }
    );
    setDirty(true);
  };
  return (
    <>
      <Text size={'sm'}>Rename Product</Text>

      <form
        onSubmit={form.onSubmit((values) => {
          updateName(values.instructionName);
        })}
      >
        <Group
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
          }}
        >
          <TextInput
            style={{ width: '320px' }}
            placeholder={instructionName}
            {...form.getInputProps('instructionName')}
            data-cy="edit-instruction-name"
          />
          <Button
            disabled={renameInstructionMutation.isLoading}
            style={{ height: '36px' }}
            size="xs"
            type="submit"
            onClick={(event) => {
              event.stopPropagation();
            }}
            data-cy="instruction-name-apply-button"
          >
            Apply
          </Button>
        </Group>
      </form>
    </>
  );
};
