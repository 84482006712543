import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconArrowsMove, IconRotate } from '@tabler/icons-react';

export const TransformActionSwitch = () => {
  const theme = useMantineTheme();
  const action = useUIStore((state) => state.transformGizmo.action);
  return (
    <Tooltip position="right" label="Switch between Rotation and Translation">
      {action === 'rotate' ? (
        <ActionIcon
          color={theme.other.color.brand.yellow}
          size="lg"
          style={{
            pointerEvents: 'auto',
          }}
          variant="filled"
          onClick={() => UIController.setTransformAction('translate')}
        >
          <IconRotate />
        </ActionIcon>
      ) : (
        <ActionIcon
          color={theme.other.color.brand.yellow}
          size="lg"
          style={{
            pointerEvents: 'auto',
          }}
          variant="filled"
          onClick={() => UIController.setTransformAction('rotate')}
        >
          <IconArrowsMove />
        </ActionIcon>
      )}
    </Tooltip>
  );
};
