import { Button } from '@mantine/core';
import classes from '../Cards.module.scss';
interface CardFooterButtonProps {
  label: string;
  onClick: () => unknown;
}

export const CardFooterButton = ({ label, onClick }: CardFooterButtonProps) => {
  return (
    <Button
      data-cy="card-footer-button"
      className={classes.explorerCard__footer__button}
      classNames={{ label: classes.explorerCard__footer__button__label }}
      onClick={onClick}
      radius={'md'}
      variant={'light'}
      color={'gray.6'}
      size={'xs'}
      py={0}
      px={5}
    >
      {label}
    </Button>
  );
};
