import { HorizontalAlignment, VerticalAlignment } from '@assemblio/type/annotation';
import { ActionIcon, Popover, SegmentedControl, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { BaseEditor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import {
  IconAlignBoxLeftBottom,
  IconAlignBoxLeftMiddle,
  IconAlignBoxLeftTop,
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
} from '@tabler/icons-react';

interface Props {
  editor: (BaseEditor & ReactEditor) | null;
  verticalChange?: (position: VerticalAlignment) => void;
  verticalAlignment?: VerticalAlignment;
  horizontalAlignment: HorizontalAlignment;
}

export const AlignmentSwitch = ({ editor, verticalChange, verticalAlignment, horizontalAlignment }: Props) => {
  const theme = useMantineTheme();
  const [horizontalSelected, setHorizontalSelected] = useState<HorizontalAlignment>(horizontalAlignment);
  const setAlignment = (alignment: HorizontalAlignment) => {
    setHorizontalSelected(alignment);
    editor && Transforms.setNodes(editor, { align: alignment });
  };

  const getIcon = (value: string) => {
    switch (value) {
      case 'right':
        return <IconAlignRight />;
      case 'center':
        return <IconAlignCenter />;
      case 'top':
        return <IconAlignBoxLeftTop />;
      case 'middle':
        return <IconAlignBoxLeftMiddle />;
      case 'bottom':
        return <IconAlignBoxLeftBottom />;
      default:
        return <IconAlignLeft />;
    }
  };
  return editor ? (
    <Popover>
      <Popover.Target>
        <ActionIcon style={{ cursor: 'pointer' }} variant="filled" color={theme.other.color.brand.yellow} size={'lg'}>
          {getIcon(horizontalSelected)}{' '}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <SegmentedControl
          onChange={(value) => setAlignment(value as HorizontalAlignment)}
          value={horizontalSelected}
          data={[
            {
              value: 'left',
              label: getIcon('left'),
            },
            {
              value: 'center',
              label: getIcon('center'),
            },
            {
              value: 'right',
              label: getIcon('right'),
            },
          ]}
        />
        {verticalAlignment && (
          <SegmentedControl
            value={verticalAlignment}
            onChange={(value) => verticalChange && verticalChange(value as VerticalAlignment)}
            data={[
              {
                value: 'top',
                label: getIcon('top'),
              },
              {
                value: 'middle',
                label: getIcon('middle'),
              },
              {
                value: 'bottom',
                label: getIcon('bottom'),
              },
            ]}
          />
        )}
      </Popover.Dropdown>
    </Popover>
  ) : null;
};
