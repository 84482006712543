import {
  CanvasController,
  UIController,
  useModelStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { CameraControlsAPI } from '@assemblio/frontend/types';
import { GizmoHelper, GizmoViewcube } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Orbiter } from './Orbiter';
import { ViewCube } from './ViewCube';

interface Props {
  active: boolean;
  onChange?: () => void;
}

const activateSelection = () => {
  _.defer(() => {
    UIController.setSelectionActive(true);
  });
};

const deactivateSelection = () => {
  UIController.setSelectionActive(false);
};

export const CameraControls = ({ active, onChange }: Props) => {
  const controlsReference = useRef<CameraControlsAPI>(null);
  const sidebarWidth = useUIStore((state) => state.sidebarWidth);

  const { size } = useThree();

  useEffect(() => {
    if (controlsReference.current) {
      CanvasController.setCameraControls(controlsReference.current);
      const reference = controlsReference.current;
      reference.addEventListener('update', deactivateSelection);
      reference.addEventListener('end', activateSelection);
      return () => {
        reference.removeEventListener('update', deactivateSelection);
        reference.removeEventListener('end', activateSelection);
      };
    }
    return _.noop;
  }, [controlsReference]);

  if (active) {
    return (
      <>
        <Orbiter ref={controlsReference} />
        <ViewCube
          marginX={Math.max(sidebarWidth.left, size.left) + 80 - size.left}
        />
      </>
    );
  } else {
    return null;
  }
};
