import {
  Box,
  Collapse,
  Group,
  Stack,
  StackProps,
  Text,
  ThemeIcon,
} from '@mantine/core';
import classes from './Navbar.module.scss';
import cx from 'clsx';
import { NavbarItemType } from './types/navbar.types';

interface ItemProps extends StackProps {
  item: NavbarItemType;
  isSelected: boolean;
  onItemClick?: (id: string) => unknown;
  isChildSelected?: (child: NavbarItemType) => boolean;
  logoOnly?: boolean;
}

export const NavbarItem = ({
  item,
  onItemClick,
  isChildSelected,
  isSelected,
  ...props
}: ItemProps) => {
  const { label, color, icon, description, slug, children } = item;

  return (
    <Stack {...props} gap={0}>
      <Box
        data-cy={`adminpanel-button-${slug}`}
        className={cx(classes['navbar__button'], {
          [classes['navbar__button--current']]: isSelected,
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>
          <Stack gap={0}>
            <Text size="sm">{label}</Text>
            <Text size="xs" c={'dimmed'}>
              {description}
            </Text>
          </Stack>
        </Group>
      </Box>
      {children?.map((item) => (
        <Collapse
          in={isChildSelected ? isChildSelected(item) : false}
          key={`navbar-child-${item.slug}`}
        >
          <Box
            data-cy={`adminpanel-button-${slug}`}
            className={cx(
              classes['navbar__button'],
              classes['navbar__button--nested']
            )}
          >
            <Group>
              <ThemeIcon color={item.color} variant="light">
                {item.icon}
              </ThemeIcon>
              <Stack gap={0}>
                <Text size="sm">{item.label}</Text>
                <Text size="xs" c={'dimmed'}>
                  {item.description}
                </Text>
              </Stack>
            </Group>
          </Box>
        </Collapse>
      ))}
    </Stack>
  );
};
