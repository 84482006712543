import { Text } from '@mantine/core';
import { useExplorerState } from '../ProjectExplorer/useExplorerState';

export interface ProjectCountProps {
  projectCount?: number;
}

export const ProjectCount = ({ projectCount = 0 }: ProjectCountProps) => {
  const { isListView } = useExplorerState();

  return (
    <Text
      size={'xs'}
      c="dimmed"
      data-cy={isListView ? 'row-projectCount-Text' : 'card-footer-text'}
    >
      {projectCount === 0
        ? 'No Project'
        : projectCount === 1
        ? '1 Project'
        : `${projectCount} Projects`}
    </Text>
  );
};
