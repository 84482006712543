import { ScrollArea, Stack, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';
import classes from './Variants.module.scss';
interface VariantListProps {
  label: string;
}

export const VariantList = ({
  label,
  children,
}: PropsWithChildren<VariantListProps>) => {
  return (
    <Stack
      mah={'10vh'}
      w={'100%'}
      gap={0}
      style={{
        overflow: 'hidden',
      }}
    >
      <Text size="sm">{label}</Text>
      <ScrollArea
        classNames={{
          viewport: classes.variantList__scrollWrapper,
        }}
      >
        {children}
      </ScrollArea>
    </Stack>
  );
};
