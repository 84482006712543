import { useParams } from 'react-router-dom';
import { ProjectRoutingParams } from '../types';
import { useEffect } from 'react';
import { useEventStore } from '@assemblio/frontend/stores';
import { useQueryClient } from '@tanstack/react-query';

/**
 * Hook that subscribes to all relevant Websocket events for a project.
 */
export const useProjectWebsocketListeners = () => {
  const queryClient = useQueryClient();

  const socket = useEventStore((state) => state.socket);

  const { projectId } = useParams<ProjectRoutingParams>() as ProjectRoutingParams;

  useEffect(() => {
    socket.emit('subscribe-project', projectId);
    return () => {
      socket.emit('unsubscribe-project', projectId);
    };
  }, [projectId]);

  useEffect(() => {
    socket.on('project-changed', () => {
      queryClient.invalidateQueries(['project', projectId]);
    });
    return () => {
      socket.off('project-changed');
    };
  }, []);
};
