import { useAuthStore } from '@assemblio/frontend/data-access';
import { ProjectFolder } from '../../../types/project-structure.types';
import { Button, Divider } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { EmptyFolderView } from './EmptyFolderView';
import { PopulatedFolderList } from './PopulatedFolderList';
import classes from '../ItemMoveModal.module.scss';

interface FolderListViewProps {
  currentFolder: ProjectFolder;
  onFolderNavigation: (targetId?: string) => unknown;
}

export const FolderListView = ({
  currentFolder,
  onFolderNavigation,
}: FolderListViewProps) => {
  const tenantName = useAuthStore((state) => state.tenant.name);
  return (
    <>
      <>
        <Button
          data-cy="folder-list-view"
          className={classes.listHeaderButton}
          leftSection={currentFolder.parentFolderId ? <IconArrowLeft /> : null}
          variant={'subtle'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (currentFolder.parentFolderId) onFolderNavigation();
          }}
        >
          {currentFolder.parentFolderId ? currentFolder.name : tenantName}
        </Button>
        <Divider my={10} />
      </>

      {currentFolder.subFolders?.length || currentFolder.projects?.length ? (
        <PopulatedFolderList
          currentFolder={currentFolder}
          onFolderNavigation={onFolderNavigation}
        />
      ) : (
        <EmptyFolderView />
      )}
    </>
  );
};
