import { UIController } from '@assemblio/frontend/stores';
import { useMouse, useViewportSize } from '@mantine/hooks';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { ResizeHandle, ResizeHandleSide } from './ResizeHandle';
import { ResizableContainerRef } from '@assemblio/frontend/types';
import classes from './Resizable.module.scss';
import { useMantineTheme } from '@mantine/core';
import cx from 'clsx';

interface ResizableContainerProps {
  resizeHandle: ResizeHandleSide;
  maxWidthMargin: number;
  children?: React.ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
  onResize?: (collapsed: boolean) => unknown;
}

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export const ResizableContainer = React.forwardRef(
  (
    {
      resizeHandle,
      maxWidthMargin,
      children,
      containerRef,
      onResize,
    }: ResizableContainerProps,
    ref: React.ForwardedRef<ResizableContainerRef>
  ) => {
    const { width, height } = useViewportSize();
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.style.width = `300px`;
      }
    }, [containerRef]);

    useImperativeHandle(ref, () => ({
      collapse() {
        if (containerRef.current && !collapsed) {
          containerRef.current.style.width = '5px';
          UIController.setSidebarWidth(resizeHandle, 5);
          setCollapsed(true);
        }
      },
      expand() {
        if (containerRef.current && collapsed) {
          containerRef.current.style.width = '300px';
          UIController.setSidebarWidth(resizeHandle, 300);
          setCollapsed(false);
        }
      },
      toggle() {
        if (containerRef.current) {
          if (collapsed) {
            this.expand();
          } else {
            this.collapse();
          }
        }
      },
      isCollapsed: collapsed,
    }));

    const theme = useMantineTheme();

    const { x } = useMouse();

    function handleResize(
      event: React.SyntheticEvent,
      { size }: ResizeCallbackData
    ) {
      if (size.width <= 200 && !collapsed) {
        if (containerRef.current) {
          UIController.setSidebarWidth(resizeHandle, 5);
          containerRef.current.style.width = `5px`;
          setCollapsed(true);
          onResize && onResize(true);
        }
      } else {
        if (containerRef.current) {
          let newWidth = resizeHandle === 'w' ? width - x : x;
          newWidth = clamp(newWidth, 0, width / 2 - maxWidthMargin);
          UIController.setSidebarWidth(resizeHandle, newWidth);
          containerRef.current.style.width = `${newWidth}px`;
        }
      }

      if (size.width > 100 && collapsed) {
        setCollapsed(false);
        onResize && onResize(false);
      }
    }

    if (!containerRef.current) return null;
    else {
      return (
        <Resizable
          height={height - theme.other.navbarHeight}
          width={containerRef.current.offsetWidth}
          onResize={handleResize}
          resizeHandles={[resizeHandle]}
          maxConstraints={[width / 2 - maxWidthMargin, Infinity]}
          handle={
            <ResizeHandle
              handleSide={resizeHandle}
              height={height - theme.other.navbarHeight}
            />
          }
        >
          <div
            style={{
              width:
                containerRef.current?.offsetWidth >= 100
                  ? containerRef.current?.offsetWidth
                  : 5,
              height: height - theme.other.navbarHeight,
            }}
            className={cx([
              classes.container,
              { [classes.container__left]: resizeHandle === 'e' },
              { [classes.container__right]: resizeHandle === 'w' },
              { [classes['container--collapsed']]: collapsed === true },
            ])}
          >
            {!collapsed && children}
          </div>
        </Resizable>
      );
    }
  }
);
