import { useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import {
  Box,
  Flex,
  Group,
  Loader,
  Table,
  Text,
  Tooltip,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { InstructionCardMenu } from '../Cards/InstructionCard/InstructionCardMenu';
import { useInstructionStateQuery } from '@assemblio/frontend/data-access';
 import classes from './Rows.module.scss';
import textClasses from '../Cards/ExplorerCard/ExplorerCard.module.scss';

import { getFormattedDateDifference } from '../DateFormatter';
import { forwardRef, useState } from 'react';
import { CombinedInstructionItemProps } from '../types';
import { IconEdit } from '@tabler/icons-react';
import { NextInstruction } from '../types/project-structure.types';
import { useMediaQuery } from '@mantine/hooks';
import cx from 'clsx';
import { InstructionState } from '@assemblio/type/instruction';
export const InstructionRow = forwardRef<
  HTMLTableRowElement,
  CombinedInstructionItemProps
>(
  (
    {
      project,
      instruction,
      onInstructionSelection,
      openInstructionChangeModal,
      onOpenInstruction,
      openCreateVariantModalOpened,
      openDeleteSyncProfileModal,
    }: CombinedInstructionItemProps,
    ref
  ) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);
    const isSelected =
      useProjectStore((state) => state.selectedExplorerItem) === instruction.id;
    const [loading, setLoading] = useState(false);

    const showContextMenu =
      instruction.state !== InstructionState.INITIALIZING &&
      instruction.state !== InstructionState.PROCESSING;

    useInstructionStateQuery(
      instruction.id,
      project.id,
      instruction.state === InstructionState.INITIALIZING
    );

    return (
      <Table.Tr
        key={instruction.id}
        ref={ref}
        className={classes.itemRow}
        data-selected={isSelected}
        onDoubleClick={() => onOpenInstruction(instruction)}
        onClick={(e) => {
          e.stopPropagation();
          onInstructionSelection(instruction.id);
        }}
      >
        {isMobile ? (
          <CompactRow
            instruction={instruction}
            loading={loading}
            projectId={project.id}
            showContextMenu={showContextMenu}
            setLoading={setLoading}
            openInstructionChangeModal={openInstructionChangeModal}
            openCreateVariantModalOpened={openCreateVariantModalOpened}
            openDeleteSyncProfileModal={openDeleteSyncProfileModal}
          />
        ) : (
          <Row
            instruction={instruction}
            projectId={project.id}
            showContextMenu={showContextMenu}
            loading={loading}
            setLoading={setLoading}
            openInstructionChangeModal={openInstructionChangeModal}
            openCreateVariantModalOpened={openCreateVariantModalOpened}
            openDeleteSyncProfileModal={openDeleteSyncProfileModal}
          />
        )}
      </Table.Tr>
    );
  }
);

interface RowContentProps {
  instruction: NextInstruction;
  projectId: string;
  showContextMenu: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  openInstructionChangeModal: (instructionId: string) => void;
  openCreateVariantModalOpened: (instructionId: string) => void;
  openDeleteSyncProfileModal: (instructionId: string) => void;
}

const Row = ({
  instruction,
  projectId,
  showContextMenu,
  loading,
  setLoading,
  openInstructionChangeModal,
  openCreateVariantModalOpened,
  openDeleteSyncProfileModal,
}: RowContentProps) => {
  const view = useUIStore((state) => state.view);

  return (
    <>
      <Table.Td>
        <Group>
          <Box className={classes.itemRow__instructionSquare} />
          <Text
            className={cx(
              textClasses.explorerCard__title,
              textClasses['explorerCard__title--list']
            )}
          >
            {instruction.name}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Tooltip label="State" openDelay={500}>
          <Group display={'inline-flex'}>
            {instruction.state === InstructionState.INITIALIZING && (
              <Loader size="12px" variant="dots" />
            )}
            <Text c={'dimmed'} size="xs">
              {instruction.state}
            </Text>
          </Group>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        <Tooltip label="Updated at" openDelay={500}>
          <Group gap={'xs'} display={'inline-flex'}>
            <IconEdit size={14} color={'gray'} />
            <Text c={'dimmed'} size="xs">
              {getFormattedDateDifference(instruction.updatedAt)}
            </Text>
          </Group>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        {view === 'editor' && showContextMenu && (
          <InstructionCardMenu
            disabled={loading}
            setLoading={setLoading}
            projectId={projectId}
            instruction={instruction}
            onListItem
            openCreateVariantModalOpened={openCreateVariantModalOpened}
            openInstructionChangeModal={openInstructionChangeModal}
            openDeleteSyncProfileModal={openDeleteSyncProfileModal}
          />
        )}
      </Table.Td>
    </>
  );
};

const CompactRow = ({
  instruction,
  projectId,
  showContextMenu,
  loading,
  setLoading,
  openInstructionChangeModal,
  openCreateVariantModalOpened,
  openDeleteSyncProfileModal,
}: RowContentProps) => {
  const view = useUIStore((state) => state.view);

  const theme = useMantineTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isExtraSmall = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Table.Td>
      <Flex
        justify={'space-between'}
        direction={isSmall ? 'column' : 'row'}
        gap={'xs'}
      >
        <Group maw={rem(270)}>
          <Box className={classes.itemRow__instructionSquare} />
          <Text
            className={cx(
              textClasses.explorerCard__title,
              textClasses['explorerCard__title--list']
            )}
          >
            {instruction.name}
          </Text>
        </Group>
        <Group gap={'xs'} mr={'15%'}>
          <Tooltip label="State" openDelay={500}>
            <Group display={'inline-flex'}>
              {instruction.state === InstructionState.INITIALIZING && (
                <Loader size="12px" variant="dots" />
              )}
              <Text c={'dimmed'} size={'xs'}>
                {instruction.state}
              </Text>
            </Group>
          </Tooltip>
          <Text c={'dimmed'}>|</Text>
          <Tooltip label="Updated at" openDelay={500}>
            <Group gap={'xs'} display={'inline-flex'}>
              <IconEdit size={14} color={'gray'} />
              <Text c={'dimmed'} size={'xs'}>
                {getFormattedDateDifference(instruction.updatedAt)}
              </Text>
            </Group>
          </Tooltip>
        </Group>

        {view === 'editor' && showContextMenu && (
          <InstructionCardMenu
            disabled={loading}
            setLoading={setLoading}
            projectId={projectId}
            instruction={instruction}
            onListItem={isExtraSmall}
            openCreateVariantModalOpened={openCreateVariantModalOpened}
            openInstructionChangeModal={openInstructionChangeModal}
            openDeleteSyncProfileModal={openDeleteSyncProfileModal}
          />
        )}
      </Flex>
    </Table.Td>
  );
};
