import { StepController, useSequenceStore, useUIStore } from '@assemblio/frontend/stores';
import { MultiTextRenderer } from './TextRenderer';
import { ActionIcon, Paper, Popover, ScrollArea, SegmentedControl } from '@mantine/core';
import { IconArrowDown, IconArrowLeft, IconArrowRight, IconArrowUp, IconSettings } from '@tabler/icons-react';
import { useState } from 'react';

export const SidebarTextRenderer = () => {
  const [position, setPosition] = useState('right');
  const [opened, setOpened] = useState(false);
  const selectedStep = useUIStore((store) => store.selectedStep);

  const step = useSequenceStore((state) => {
    if (selectedStep) {
      return state.stepGroups[selectedStep.index.stepGroupIndex].steps[selectedStep.index.stepIndex];
    } else return undefined;
  });

  if (selectedStep && step) {
    const texts = StepController.getStepTexts(selectedStep.id);
    if (texts.length > 0) {
      return (
        <Paper
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.3)',
            pointerEvents: 'auto',
            display: 'flex',
            flexDirection: 'column',
            ...getPositionalStyles(position),
          }}
          p="md"
        >
          <Popover width={160} opened={opened} onChange={setOpened}>
            <Popover.Target>
              <ActionIcon variant="outline" size={'xs'} color="gray" onClick={() => setOpened((o) => !o)}>
                <IconSettings />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <SegmentedControl
                fullWidth
                withItemsBorders={false}
                value={position}
                size="xs"
                data={[
                  {
                    value: 'top',
                    label: <IconArrowUp size={16} />,
                  },
                  {
                    value: 'bottom',
                    label: <IconArrowDown size={16} />,
                  },
                  {
                    value: 'left',
                    label: <IconArrowLeft size={16} />,
                  },
                  {
                    value: 'right',
                    label: <IconArrowRight size={16} />,
                  },
                ]}
                onChange={(value) => {
                  setPosition(value);
                  setOpened(false);
                }}
              />
            </Popover.Dropdown>
          </Popover>
          <ScrollArea.Autosize scrollbars="y" offsetScrollbars>
            <MultiTextRenderer texts={texts} />
          </ScrollArea.Autosize>
        </Paper>
      );
    }
  }
  return undefined;
};

const getPositionalStyles = (position: string) => {
  if (position === 'top' || position === 'bottom') {
    return {
      maxHeight: '30vh',
      width: '100%',
      top: position === 'top' ? 0 : 'auto',
      bottom: position === 'bottom' ? 0 : 'auto',
    };
  } else {
    return {
      maxWidth: '20vw',
      height: '100%',
      left: position === 'left' ? 0 : 'auto',
      right: position === 'right' ? 0 : 'auto',
    };
  }
};
