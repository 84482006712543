import { IconLayoutGrid, IconUser } from '@tabler/icons-react';
import { NavbarItemType } from '../Navbar/types/navbar.types';
import { UserSettings } from '@assemblio/frontend/components';
import { LayoutDefinition } from './components/LayoutDefinition';

export const userProfileNavbarItems = (appRole: string | undefined): NavbarItemType[] => {
  const items: NavbarItemType[] = [
    {
      slug: 'profile-me',
      icon: <IconUser size={16} />,
      color: 'blue',
      label: 'Your Profile',
      component: <UserSettings />,
    },
    ...(appRole === 'manager'
      ? ([
          {
            slug: 'layout-def',
            icon: <IconLayoutGrid size={16} />,
            color: 'blue',
            label: 'Layout',
            component: <LayoutDefinition />,
          },
        ] as NavbarItemType[])
      : []),
  ];

  return items;
};
