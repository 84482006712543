import {
  NextInstruction,
  NextInstructionVariant,
} from '../../types/project-structure.types';
import { Box, Divider, SimpleGrid, Text } from '@mantine/core';
import { VariantList } from './VariantList';
import { VariantCard } from './VariantCard';
import { InstructionState } from '@assemblio/type/instruction';
interface VariantInformationProps {
  instruction: NextInstruction;
  syncMutationLoading: boolean;
  onOpenResolveModal: (
    instruction: NextInstruction,
    sourceVariant: NextInstructionVariant
  ) => void;
}

export const VariantInformation = ({
  instruction,
  syncMutationLoading,
  onOpenResolveModal,
}: VariantInformationProps) => {
  const handleResolve = (sourceVariant: NextInstructionVariant) => {
    onOpenResolveModal(instruction, sourceVariant);
  };

  const syncedFrom = instruction.synced.from;
  const syncedTo = instruction.synced.to;
  return (
    <Box mah={'20%'} mih={'7rem'}>
      <Divider my={10} />
      <Text fw={700} mb={'xs'}>
        Synced
      </Text>
      <SimpleGrid cols={2}>
        <VariantList label="From:">
          {syncedFrom.map((variant) => (
            <VariantCard
              type={'from'}
              key={`variant-card-${variant.id}`}
              variant={variant}
              onResolve={handleResolve}
              disableSync={instruction.state === InstructionState.PROCESSING}
            />
          ))}
        </VariantList>
        <VariantList label="To:">
          {syncedTo.map((variant) => (
            <VariantCard
              syncMutationLoading={syncMutationLoading}
              type={'to'}
              key={`variant-card-${variant.id}`}
              variant={variant}
              onResolve={handleResolve}
              disableSync={instruction.state === InstructionState.PROCESSING}
            />
          ))}
        </VariantList>
      </SimpleGrid>
    </Box>
  );
};
