import {
  Button,
  Divider,
  Group,
  Modal,
  Radio,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { useState } from 'react';
import { IconExclamationCircle } from '@tabler/icons-react';
import { NextInstruction } from '../../types/project-structure.types';
import {
  useComparableInstructionsQuery,
  useInstructionCreateSyncProfile,
} from '@assemblio/frontend/data-access';
import classes from '../Dialogs.module.scss';
import { InstructionVariantCreationInfo } from './InstructionVariantCreationInfo';
import { BetaBadge } from '../../../Common';
import { ProjectRoutingParams } from '../../types';
import { useParams } from 'react-router-dom';
import { RadioItemWarning } from '../components/RadioItemUnavailableWarning';
import { notifications } from '@mantine/notifications';

interface InstructionCreateVariantModalProps {
  instruction: NextInstruction;
  opened: boolean;
  onClose: () => unknown;
}

export const InstructionCreateVariantModal = ({
  instruction,
  opened,
  onClose,
}: InstructionCreateVariantModalProps) => {
  const { projectId } =
    useParams<ProjectRoutingParams>() as ProjectRoutingParams;
  const [syncProfileSource, setSyncProfileSource] = useState<
    string | undefined
  >(undefined);

  const createSyncProfileMutation = useInstructionCreateSyncProfile();

  const { data, isLoading, isError } = useComparableInstructionsQuery(
    instruction.id
  );

  const comparableVariantsAvailable = data?.some(
    (instruction) => instruction.isAvailable
  );

  const handleCreate = () => {
    if (!syncProfileSource) return;
    createSyncProfileMutation.mutate(
      {
        from: syncProfileSource,
        to: instruction.id,
        projectId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'create-sync-profile-success',
            message: 'Linked Product',
            color: 'green',
          });
        },
      }
    );
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSyncProfileSource(undefined);
    onClose();
  };
  return (
    <Modal
      size={'lg'}
      opened={opened}
      title={<BetaBadge />}
      onClose={handleCloseModal}
      centered
      styles={{
        header: {
          paddingBottom: 0,
        },
      }}
    >
      <Text size={'lg'}>
        Link
        <Text span fw={700}>
          &nbsp;{instruction.name}
        </Text>
      </Text>

      <InstructionVariantCreationInfo />

      <Divider my={'xs'} />

      <Text>Select a base product: </Text>
      <Radio.Group value={syncProfileSource} onChange={setSyncProfileSource}>
        <Stack mx={'lg'} mt={'lg'} mb={'xl'} gap={'lg'}>
          {isLoading && (
            <>
              <Skeleton h={'25px'} w={'100%'} />
              <Skeleton h={'25px'} w={'100%'} />
            </>
          )}
          {data && (
            <>
              {data.map((ins) => (
                <Group
                  key={`available-instruction-${ins.id}`}
                  justify={'space-between'}
                  gap={0}
                >
                  <Tooltip label={ins.name} openDelay={500} refProp="rootRef">
                    <Radio
                      maw={ins.isAvailable ? '100%' : '60%'}
                      disabled={!ins.isAvailable}
                      value={ins.id}
                      label={ins.name}
                      classNames={{
                        labelWrapper: classes.variantRadioList__label,
                      }}
                    />
                  </Tooltip>
                  {!ins.isAvailable && <RadioItemWarning reason={ins.reason} />}
                </Group>
              ))}
              {!comparableVariantsAvailable && (
                <Text size={'xs'}>
                  There are no Products in this project that can be used as a
                  base for a Synchronization Profile.
                </Text>
              )}
            </>
          )}
          {isError && (
            <Group>
              <IconExclamationCircle />
              <Text>Could not fetch instructions</Text>
            </Group>
          )}
        </Stack>
      </Radio.Group>

      <Button.Group mt={'xl'} style={{ justifyContent: 'flex-end' }}>
        <Button
          variant={'outline'}
          onClick={(e) => {
            handleCloseModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!syncProfileSource}
          onClick={(e) => {
            handleCreate();
          }}
        >
          Link product
        </Button>
      </Button.Group>
    </Modal>
  );
};
