import {
  ModelController,
  ModelVisibilityController,
  StepController,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Assembly, Part } from '@assemblio/type/input';
import { Group, Tooltip, useMantineTheme } from '@mantine/core';
import {
  IconArrowsJoin,
  IconEye,
  IconEyeOff,
  IconSquare,
  IconSquareOff,
} from '@tabler/icons-react';
import { LightWeightIcon } from './LightWeightIcon';

interface Props {
  isLeaf: boolean;
  assembly: Assembly | Part;
  visible: boolean;
  transparent: boolean;
}

export const TreeItemActions = (props: Props) => {
  const theme = useMantineTheme();
  const isAnimating = useUIStore((state) => state.isAnimating);
  const hasPath = StepController.hasStep(props.assembly.gltfIndex);

  return (
    <Group wrap="nowrap" gap={5}>
      <Tooltip
        label={props.visible ? 'Hide object' : 'Display object'}
        openDelay={2000}
      >
        <LightWeightIcon
          color={theme.other.color.brand.purple}
          size={'16px'}
          disabled={isAnimating}
          onClick={() =>
            onChangeVisiblity(props.isLeaf, props.assembly, !props.visible)
          }
        >
          {props.visible ? (
            <IconEye style={{ width: '100%', height: '100%' }} />
          ) : (
            <IconEyeOff style={{ width: '100%', height: '100%' }} />
          )}
        </LightWeightIcon>
      </Tooltip>

      <Tooltip
        label={
          props.transparent
            ? 'Display object non-transparently'
            : 'Display object transparently'
        }
        openDelay={2000}
      >
        <LightWeightIcon
          color={theme.other.color.brand.pink}
          size={'16px'}
          disabled={isAnimating}
          onClick={() =>
            onChangeTransparency(
              props.isLeaf,
              props.assembly,
              !props.transparent
            )
          }
        >
          {props.transparent ? (
            <IconSquare style={{ width: '100%', height: '100%' }} />
          ) : (
            <IconSquareOff style={{ width: '100%', height: '100%' }} />
          )}
        </LightWeightIcon>
      </Tooltip>

      {props.isLeaf ? (
        <LightWeightIcon
          color={theme.other.color.brand.yellow}
          size={'16px'}
          disabled={isAnimating}
          onClick={() => {
            if (hasPath)
              ModelController.selectNextStepByGltfIndex(
                props.assembly.gltfIndex
              );
          }}
        >
          <IconArrowsJoin style={{ width: '100%', height: '100%' }} />
        </LightWeightIcon>
      ) : (
        <div style={{ width: 16 }}></div>
      )}
    </Group>
  );
};

const onChangeVisiblity = (
  isLeaf: boolean,
  data: Assembly | Part,
  visible: boolean
) => {
  if (isLeaf)
    ModelVisibilityController.setPartVisible(data.gltfIndex, visible, true);
  else
    ModelVisibilityController.setAssemblyVisible(data.gltfIndex, visible, true);
};

const onChangeTransparency = (
  isLeaf: boolean,
  data: Assembly | Part,
  transparent: boolean
) => {
  if (isLeaf)
    ModelVisibilityController.setPartTransparent(data.gltfIndex, transparent);
  else
    ModelVisibilityController.setAssemblyTransparent(
      data.gltfIndex,
      transparent
    );
};
