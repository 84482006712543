import { NextProject } from '../../../types/project-structure.types';
import { Text, Box } from '@mantine/core';
import classes from '../ItemMoveModal.module.scss';

interface ProjectListItemProps {
  project: NextProject;
}

export const ProjectListItem = ({ project }: ProjectListItemProps) => {
  return (
    <Box className={classes.listItem} style={{ cursor: 'default' }}>
      <Box className={classes.listItem__name}>
        <Box className={classes.listItem__square} />
        <Text ml={5}>{project.name}</Text>
      </Box>
    </Box>
  );
};
