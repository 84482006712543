import { useUIStore } from '@assemblio/frontend/stores';
import { Group, Stack } from '@mantine/core';
import { shallow } from 'zustand/shallow';
import { CameraCenterButton } from './CameraCenterButton';
import { TransformActionSwitch } from './TransformActionSwitch';
import { AnnotationChooser } from './UI/AnnotationChooser';
import { ColorPicker } from './UI/ColorPicker';
import { PreviewSwitch } from './PreviewSwitch';
import { RotationSnapSettings } from './RotationSnapSettings';
import { SidebarTextRendererButton } from './SidebarTextRendererButton';

export const ViewportControls = () => {
  const { cameraControlsActive, toolBarActive, stepSelected } = useUIStore(
    (state) => ({
      cameraControlsActive: state.viewportControlsActive,
      toolBarActive: state.toolbarActive,
      stepSelected: state.selectedStep !== undefined,
    }),
    shallow,
  );
  return (
    <>
      <Group
        style={{
          pointerEvents: 'none',
          marginTop: '10px',
          flex: '0 1 auto',
          top: '0px',
          minHeight: '34px',
          alignSelf: 'center',
          '&>*': { pointerEvents: 'auto' },
        }}
        justify="center"
        align="flex-start"
        gap="sm"
        wrap="nowrap"
        id="toolbar"
      >
        <Group gap="sm">
          {toolBarActive && <ColorPicker />}
          {toolBarActive && stepSelected && <AnnotationChooser />}
        </Group>
      </Group>

      <Stack
        gap="sm"
        align="flex-start"
        style={{
          pointerEvents: 'none',
          marginLeft: '5px',
          marginTop: '10px',
        }}
      >
        {cameraControlsActive && (
          <>
            {/* <TransformModeSwitch /> */}
            <CameraCenterButton />
            <TransformActionSwitch />
            <RotationSnapSettings />
            <PreviewSwitch />
          </>
        )}
        <SidebarTextRendererButton />
      </Stack>
    </>
  );
};
