import { MachineController, UIController } from '@assemblio/frontend/stores';
import { Part } from '@assemblio/type/input';
import { ActionIcon, Box } from '@mantine/core';
import cx from 'clsx';
import ToggableTextInput from './ToggableTextInput';
import classes from './TreeItem.module.scss';
import { TreeItemActions } from './TreeItemActions';
import useLeafSubscription from './useLeafSubscription';
import { Tree } from '../types/hierarchy.types';

export const HierarchyLeaf = ({ data }: Tree) => {
  const part = data as Part;
  const { selected, partInformation, elementId } = useLeafSubscription(part);

  const select = (gltfIndex: number) => {
    MachineController.selectTransition(gltfIndex);
  };

  return (
    <Box
      key={`part-${part.gltfIndex}`}
      className={cx(classes.itemWrapper, {
        [classes.selected]: selected,
      })}
      ml={'10px'}
    >
      <ActionIcon className={classes.tileYellow} radius={0} size={10} variant="filled" />
      <div
        className={classes.mid}
        onMouseDown={(e: React.MouseEvent<HTMLElement>) => {
          if (e.button === 0) {
            select(part.gltfIndex);
          }
        }}
        onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          UIController.setPartContext(part.gltfIndex);
        }}
      >
        <ToggableTextInput
          elementId={elementId}
          isLeaf={true}
          gltfIndex={part.gltfIndex}
          name={partInformation !== undefined ? partInformation.name : ''}
          visible={partInformation !== undefined && partInformation.visible}
          excluded={partInformation !== undefined && partInformation.excluded}
        />
      </div>
      {partInformation && !partInformation.excluded && (
        <div className={classes.rhs}>
          <TreeItemActions
            isLeaf={true}
            assembly={part}
            visible={partInformation !== undefined && partInformation.visible}
            transparent={partInformation !== undefined && partInformation.transparent}
          />
        </div>
      )}
    </Box>
  );
};
